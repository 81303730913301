import { useNavigate, Navigate } from "react-router-dom";

const GuestRoute = ({ children }: any) => {
  const token = sessionStorage.getItem("access_token");
  const navigate = useNavigate();

  if (token) {
    return <Navigate to="/onboarding" />;
  }
  return children;
};

export default GuestRoute;
