import { Dropzone } from "@mantine/dropzone";
import { CircularProgress, Grid } from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useCallback, useState } from "react";
import { FileRejection, FileWithPath } from "react-dropzone";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  deleteDocsAPI,
  fetchDocsAPI,
  saveDocsAPI,
} from "../../../../api/uploads";
import ButtonComponent from "../../../../components/ButtonComponent";
import ContainerComponent from "../../../../components/Container/ContainerComponent";
import SnackBar from "../../../../components/SnackBar";
import ColorButton from "../../../../components/StyledComponents/UploadButton";
import TitleBox from "../../../../components/TitleBox/TitleBox";
import { SVGICONS } from "../../../../constants";
import {
  FILE_SIZE,
  FILE_TYPE_OTHERS,
} from "../../../../constants/fileProperties";
import { ISnackbarDetailsInterface } from "../../../../interfaces";
import { documentFormSubmittedAtom, formDetailsAtom } from "../../../../recoil";
import DisplayMessage from "../DisplayMessage";
import "../styles.css";
import { uploadButton } from "../uploadConstants";
import UploadDetails from "../UploadDetails";

const ProofOfIdentity = () => {
  const [fileNames, setFileNames] = useState<{ pan: string; aadhar: string }>({
    pan: "",
    aadhar: "",
  });
  const [panValue, setPanValue] = useState<FileWithPath | null>(null);
  const [aadharValue, setAadharValue] = useState<FileWithPath | null>(null);

  const [isNext, setIsNext] = useState<boolean>(false);
  const [isDisable, setIsDisable] = useState<{ pan: boolean; aadhar: boolean }>(
    {
      pan: false,
      aadhar: false,
    }
  );

  const [snackBarState, setSnackBarState] = useState<ISnackbarDetailsInterface>(
    {
      message: "",
      open: false,
      severity: undefined,
    }
  );

  // const setDocumentFormSubmitted = useSetRecoilState(documentFormSubmittedAtom);
  const [activeTab, setActiveTab] = useRecoilState(formDetailsAtom);
  const tabs = activeTab.documentUploads.tabsArray.map((tab) => {
    if (tab.id === activeTab.documentUploads.stepCount)
      return {
        ...tab,
        isCompleted: true,
      };
    return tab;
  });

  const handleCloseSnackbar = () => {
    setSnackBarState((prev) => ({
      ...prev,
      open: false,
    }));
  };

  const { isLoading: isLoadingFetch } = useQuery({
    queryKey: ["getIdentityDocs"],
    queryFn: fetchDocsAPI,
    onSuccess: (response) => {
      const fileNameDataObj = {
        pan: "",
        aadhar: "",
      };
      const isDisabledDataObj = {
        pan: false,
        aadhar: false,
      };

      if (
        response.data?.proofOfIdentity?.adhaarCard !== null &&
        response.data?.proofOfIdentity?.adhaarCard !== undefined
      ) {
        // setFileNames((prev) => ({
        //   ...prev,
        //   aadhar: "Aadhar Card",
        // }));
        // setIsDisable((prev) => ({
        //   ...prev,
        //   aadhar: true,
        // }));
        fileNameDataObj["aadhar"] = "Aadhar Card";
        isDisabledDataObj["aadhar"] = false;
        setAadharValue(response.data?.proofOfIdentity?.adhaarCard);
      }
      if (
        response.data?.proofOfIdentity?.panCard !== null &&
        response.data?.proofOfIdentity?.panCard !== undefined
      ) {
        fileNameDataObj["pan"] = "PAN Card";
        isDisabledDataObj["pan"] = true;
        setPanValue(response.data?.proofOfIdentity?.panCard);
      }

      setFileNames((prev) => ({ ...prev, ...fileNameDataObj }));
      setIsDisable((prev) => ({ ...prev, ...isDisabledDataObj }));
    },
  });

  const onDropPan = useCallback(
    (panFile: FileWithPath[]) => {
      setFileNames((prev) => ({
        ...prev,
        pan: panFile[0].name,
      }));
      setPanValue(panFile[0]);
    },
    [fileNames, panValue]
  );

  const onDropAadhar = useCallback(
    (aadharFile: FileWithPath[]) => {
      setFileNames((prev) => ({
        ...prev,
        aadhar: aadharFile[0].name,
      }));
      setAadharValue(aadharFile[0]);
    },
    [fileNames, aadharValue]
  );

  const onRejectIdentity = (files: FileRejection[]) => {
    if (files[0].errors[0].code === "file-too-large") {
      setSnackBarState({
        open: true,
        message: "File size should not be larger than 1mb",
        severity: "warning",
      });
    } else {
      setSnackBarState({
        open: true,
        message: "File type must be only in .pdf format",
        severity: "warning",
      });
    }
  };

  // const onRejectAadhar = (files: FileRejection[]) => {
  //   if (files[0].errors[0].code === "file-too-large") {
  //     setSnackBarState({
  //       open: true,
  //       message: "File size should not be larger than 1mb",
  //       severity: "warning",
  //     });
  //   } else {
  //     setSnackBarState({
  //       open: true,
  //       message: "File type must be only in .jpeg/.png/.pdf format",
  //       severity: "warning",
  //     });
  //   }
  // };

  const handleSubmit = async () => {
    if (
      panValue !== null &&
      aadharValue !== null &&
      fileNames.aadhar !== "" &&
      fileNames.pan !== ""
    ) {
      const formData = new FormData();
      formData.append("panCard", panValue);
      formData.append("adhaarCard", aadharValue);
      mutate(formData);
    } else {
      setSnackBarState({
        open: true,
        message: "Both Files are required!",
        severity: "error",
      });
    }
  };

  const { mutate, isLoading } = useMutation({
    mutationFn: saveDocsAPI,
    onSuccess: () => {
      setSnackBarState({
        open: true,
        message: "successfully submitted!",
        severity: "success",
      });
      // setDocumentFormSubmitted((prev) => ({
      //   ...prev,
      //   form: {
      //     ...prev.form,
      //     identityProofForm: true,
      //   },
      // }));
      setActiveTab((prev) => ({
        ...prev,
        documentUploads: {
          ...prev.documentUploads,
          tabsArray: tabs,
        },
      }));
      setIsNext(true);
    },
    onError: (error) => {
      setSnackBarState({
        open: true,
        message: "Something went wrong",
        severity: "error",
      });
    },
  });

  const clearNamePan = () => {
    const confirm = window.confirm("Are you sure to delete the file?");
    if (confirm) {
      mutateDeletePan("proofOfIdentity.panCard");
    }
  };

  const clearNameAadhar = () => {
    const confirm = window.confirm("Are you sure to delete the file?");
    if (confirm) {
      mutateDeleteAadhar("proofOfIdentity.adhaarCard");
    }
  };

  const { mutate: mutateDeletePan, isLoading: isDeletePanLoading } =
    useMutation({
      mutationFn: deleteDocsAPI,
      onSuccess: () => {
        setFileNames((prev) => ({
          ...prev,
          pan: "",
        }));
        setIsDisable((prev) => ({
          ...prev,
          pan: false,
        }));
        setPanValue(null);
        setSnackBarState({
          open: true,
          message: "File Deleted!",
          severity: "success",
        });
      },
      onError: () => {
        setIsDisable((prev) => ({
          ...prev,
          pan: true,
        }));
      },
    });

  const { mutate: mutateDeleteAadhar, isLoading: isDeleteAadharLoading } =
    useMutation({
      mutationFn: deleteDocsAPI,
      onSuccess: () => {
        setFileNames((prev) => ({
          ...prev,
          aadhar: "",
        }));
        setIsDisable((prev) => ({
          ...prev,
          aadhar: false,
        }));
        setAadharValue(null);
        setSnackBarState({
          open: true,
          message: "File Deleted!",
          severity: "success",
        });
      },
      onError: () => {
        setIsDisable((prev) => ({
          ...prev,
          aadhar: true,
        }));
      },
    });

  return (
    <ContainerComponent>
      <>
        <Grid container direction="row" columnSpacing={5}>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TitleBox label={"PAN Card"}>
              <Dropzone
                disabled={isDisable.pan}
                className="dropzone"
                onDrop={(files) => onDropPan(files)}
                onReject={(files: FileRejection[]) => onRejectIdentity(files)}
                maxSize={FILE_SIZE}
                accept={FILE_TYPE_OTHERS}
              >
                <ColorButton
                  variant="contained"
                  startIcon={<img src={SVGICONS.UploadDocumentIcon} />}
                  sx={uploadButton}
                >
                  Upload File
                </ColorButton>
                <DisplayMessage fileType="PDF" />
              </Dropzone>
              {fileNames.pan !== "" ? (
                <UploadDetails
                  fileName={fileNames.pan}
                  clearName={clearNamePan}
                  isLoading={isDeletePanLoading}
                />
              ) : (
                <></>
              )}
            </TitleBox>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TitleBox label={"Aadhar Card"}>
              <Dropzone
                disabled={isDisable.aadhar}
                onDrop={(files) => onDropAadhar(files)}
                onReject={(files: FileRejection[]) => onRejectIdentity(files)}
                maxSize={FILE_SIZE}
                accept={FILE_TYPE_OTHERS}
                className="dropzone"
              >
                <ColorButton
                  variant="contained"
                  startIcon={<img src={SVGICONS.UploadDocumentIcon} />}
                  sx={uploadButton}
                >
                  Upload File
                </ColorButton>
                <DisplayMessage fileType="PDF" />
              </Dropzone>
              {fileNames.aadhar !== "" ? (
                <UploadDetails
                  fileName={fileNames.aadhar}
                  clearName={clearNameAadhar}
                  isLoading={isDeleteAadharLoading}
                />
              ) : (
                <></>
              )}
            </TitleBox>
          </Grid>
        </Grid>

        <Grid container justifyContent="flex-end">
          <div style={{ marginRight: "1em" }}>
            <ButtonComponent
              handleSubmit={handleSubmit}
              buttonText={"Previous"}
              width="100px"
            />
          </div>
          <ButtonComponent
            handleSubmit={handleSubmit}
            buttonText={isNext ? "Next" : "Save"}
            width="100px"
            isLoading={isNext ? false : isLoading}
          />
        </Grid>
        <SnackBar
          snackbarOpen={snackBarState.open}
          handleCloseSnackbar={handleCloseSnackbar}
          message={snackBarState.message}
          severity={snackBarState.severity}
        />
      </>
    </ContainerComponent>
  );
};

export default ProofOfIdentity;
