import { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  AlertColor,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import {
  buttonContainer,
  descriptionTypo,
  textField,
  textFieldContainer,
  textFieldInputAdornment,
} from "./styles";
import { requestOtp } from "../../api/login";
import SnackBar from "../../components/SnackBar";
import DotHover from "../../components/DotHover";
import ButtonComponent from "../../components/ButtonComponent";
import { ReactComponent as EmailLogo } from "../../assets/images/emailLogo.svg";
import { StyledLoginDiv } from "../../components/StyledComponents/StyledLoginDiv";
import { ReactComponent as TechVariableLogo } from "../../assets/images/techVariableLogo.svg";
import { useNavigate } from "react-router-dom";
import { ISnackbarDetailsInterface } from "../../interfaces";
import { useMutation } from "@tanstack/react-query";

const LoginRequest = () => {
  const navigate = useNavigate();
  const [snackBarState, setSnackBarState] = useState<ISnackbarDetailsInterface>(
    {
      message: "",
      open: false,
      severity: undefined,
    }
  );

  // const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleCloseSnackbar = () => {
    setSnackBarState((prev) => ({
      ...prev,
      open: false,
    }));
  };

  const { handleSubmit, ...formik } = useFormik({
    initialValues: { emailID: "" },
    validationSchema: yup.object().shape({
      emailID: yup
        .string()
        .email("Email should be valid!")
        .max(200)
        .required("Email is required!"),
    }),
    validateOnChange: false,
    onSubmit: (values) => {
      handleReqSubmit(values.emailID);
    },
  });

  const { mutate, isLoading } = useMutation({
    mutationFn: requestOtp,
    onSuccess: () => {
      setSnackBarState({
        open: true,
        message: "OTP sent to your email ID!",
        severity: "success",
      });
      window.sessionStorage.setItem("email_id", formik.values.emailID);
      setTimeout(() => {
        navigate("/verify-otp");
      }, 1000);
    },
    onError: (error: any) => {
      setSnackBarState({
        open: true,
        message: error.response.data.message,
        severity: "error",
      });
    },
  });

  const handleReqSubmit = async (email: string) => {
    mutate(email);
    // try {
    //   setIsLoading(true);
    //   const response = await requestOtp(email);
    //   if (response.status === 201) {
    //     setIsLoading(false);
    //     setSnackBarState({
    //       open: true,
    //       message: "OTP sent to your email ID!",
    //       severity: "success",
    //     });
    //     window.sessionStorage.setItem("email_id", email);
    //     setTimeout(() => {
    //       navigate("/loginOtp");
    //     }, 1000);
    //   }
    // } catch (err: any) {
    //   setIsLoading(false);
    //   setSnackBarState({
    //     open: true,
    //     message: `Oops! ${err.response.data.message}`,
    //     severity: "error",
    //   });
    // }
  };

  useEffect(() => {
    if (formik.errors.emailID) {
      setSnackBarState({
        open: true,
        message: formik.errors.emailID.toString(),
        severity: "warning",
      });
    }
  }, [formik.errors]);

  return (
    <div>
      <DotHover>
        <StyledLoginDiv>
          <SnackBar
            snackbarOpen={snackBarState.open}
            handleCloseSnackbar={handleCloseSnackbar}
            message={snackBarState.message}
            severity={snackBarState.severity}
          />
          <TechVariableLogo
            style={{
              justifyContent: "center",
              width: "144px",
              height: "41px",
              paddingTop: "60px",
            }}
          />
          <Typography sx={descriptionTypo}>Enter your email address</Typography>

          <Grid container justifyContent="center" sx={textFieldContainer}>
            <TextField
              type={"email"}
              name={"emailID"}
              value={formik.values.emailID}
              onChange={(event) => formik.handleChange(event)}
              autoFocus
              size="small"
              variant="standard"
              sx={textField}
              InputProps={{
                disableUnderline: true,
                placeholder: "Email address",
                startAdornment: (
                  <InputAdornment position="start" sx={textFieldInputAdornment}>
                    <EmailLogo />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid sx={buttonContainer}>
            <ButtonComponent
              handleSubmit={handleSubmit}
              buttonText={"REQUEST FOR OTP"}
              isLoading={isLoading}
            />
          </Grid>
        </StyledLoginDiv>
      </DotHover>
    </div>
  );
};

export default LoginRequest;
