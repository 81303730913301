import { Dropzone, FileWithPath } from "@mantine/dropzone";
import { Grid } from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useCallback, useState } from "react";
import { FileRejection } from "react-dropzone";
import { useRecoilState } from "recoil";
import {
  deleteDocsAPI,
  fetchDocsAPI,
  saveDocsAPI,
} from "../../../../api/uploads";
import ButtonComponent from "../../../../components/ButtonComponent";
import ContainerComponent from "../../../../components/Container/ContainerComponent";
import SnackBar from "../../../../components/SnackBar";
import ColorButton from "../../../../components/StyledComponents/UploadButton";
import TitleBox from "../../../../components/TitleBox/TitleBox";
import { SVGICONS } from "../../../../constants";
import {
  FILE_SIZE,
  FILE_TYPE_PHOTOS,
} from "../../../../constants/fileProperties";
import { ISnackbarDetailsInterface } from "../../../../interfaces";
import { documentFormSubmittedAtom, formDetailsAtom } from "../../../../recoil";
import DisplayMessage from "../DisplayMessage";
import "../styles.css";
import { uploadButton } from "../uploadConstants";
import UploadDetails from "../UploadDetails";

const Photos = () => {
  const [fileNames, setFileNames] = useState<{
    passport: string;
    personal: string;
  }>({
    passport: "",
    personal: "",
  });
  const [isNext, setIsNext] = useState<boolean>(false);

  const [snackBarState, setSnackBarState] = useState<ISnackbarDetailsInterface>(
    {
      message: "",
      open: false,
      severity: undefined,
    }
  );

  const [passportValue, setPassportValue] = useState<FileWithPath | null>(null);
  const [personalValue, setPersonalValue] = useState<FileWithPath | null>(null);

  const [isDisable, setIsDisable] = useState<{
    passportPhoto: boolean;
    personalPhoto: boolean;
  }>({
    passportPhoto: false,
    personalPhoto: false,
  });

  const handleCloseSnackbar = () => {
    setSnackBarState((prev) => ({
      ...prev,
      open: false,
    }));
  };

  // const [, setDocumentFormSubmitted] = useRecoilState(
  //   documentFormSubmittedAtom
  // );

  const [activeTab, setActiveTab] = useRecoilState(formDetailsAtom);
  const tabs = activeTab.documentUploads.tabsArray.map((tab) => {
    if (tab.id === activeTab.documentUploads.stepCount)
      return {
        ...tab,
        isCompleted: true,
      };
    return tab;
  });

  const { isLoading: isLoadingFetch } = useQuery({
    queryKey: ["getPhotos"],
    queryFn: fetchDocsAPI,
    onSuccess: (response) => {
      // console.log({ res: response.data.photos });
      const fileNameDataObj = { passport: "", personal: "" };
      const isDisabledDataObj = {
        passportPhoto: false,
        personalPhoto: false,
      };
      if (
        response.data?.photos?.passportPhoto !== null &&
        response.data?.photos?.passportPhoto !== undefined
      ) {
        fileNameDataObj["passport"] = "Passport Photo";
        isDisabledDataObj["passportPhoto"] = true;
        // setFileNames((prev) => ({
        //   ...prev,
        //   passport: "Passport Photo",
        // }));

        // setIsDisable((prev) => ({
        //   ...prev,
        //   passportPhoto: true,
        // }));

        setPassportValue(response.data?.photos?.passportPhoto);
      }

      if (
        response.data?.photos?.personalPhoto !== null &&
        response.data?.photos?.personalPhoto !== undefined
      ) {
        fileNameDataObj["personal"] = "Personal Photo";
        isDisabledDataObj["personalPhoto"] = true;

        setPersonalValue(response.data?.photos?.personalPhoto);
      }

      setFileNames((prev) => ({ ...prev, ...fileNameDataObj }));
      setIsDisable((prev) => ({ ...prev, ...isDisabledDataObj }));
    },
  });

  const onDropPassport = useCallback(
    (filePassport: FileWithPath[]) => {
      setFileNames((prev) => ({
        ...prev,
        passport: filePassport[0].name,
      }));
      setPassportValue(filePassport[0]);
    },
    [fileNames, passportValue]
  );

  const onDropPersonal = useCallback(
    (filePersonal: FileWithPath[]) => {
      setFileNames((prev) => ({
        ...prev,
        personal: filePersonal[0].name,
      }));
      setPersonalValue(filePersonal[0]);
    },
    [fileNames, personalValue]
  );

  const handleReject = (files: FileRejection[]) => {
    if (files[0].errors[0].code === "file-too-large") {
      setSnackBarState({
        open: true,
        message: "File size should not be larger than 1mb",
        severity: "warning",
      });
    } else {
      setSnackBarState({
        open: true,
        message: "File type must be only in .jpg/.jpeg/.png format",
        severity: "warning",
      });
    }
  };

  // const handleRejectPersonal = (files: FileRejection[]) => {
  //   // console.log({ filesss: files });
  //   if (files[0].errors[0].code === "file-too-large") {
  //     setSnackBarState({
  //       open: true,
  //       message: "File size should not be larger than 1mb",
  //       severity: "warning",
  //     });
  //   } else {
  //     setSnackBarState({
  //       open: true,
  //       message: "File type must be only in .jpeg/.png/.pdf format",
  //       severity: "warning",
  //     });
  //   }
  // };

  const handleSubmit = async () => {
    if (
      passportValue !== null &&
      personalValue !== null &&
      fileNames.passport !== "" &&
      fileNames.personal !== ""
    ) {
      const formDataNew = new FormData();
      formDataNew.append("passportPhoto", passportValue);
      formDataNew.append("personalPhoto", personalValue);
      mutate(formDataNew);
    } else {
      setSnackBarState({
        open: true,
        message: "Both Files are required!",
        severity: "error",
      });
    }
  };

  const { mutate, isLoading } = useMutation({
    mutationFn: saveDocsAPI,
    onSuccess: () => {
      setSnackBarState({
        open: true,
        message: "successfully submitted!",
        severity: "success",
      });
      // setDocumentFormSubmitted((prev) => ({
      //   ...prev,
      //   form: {
      //     ...prev.form,
      //     photosForm: true,
      //   },
      // }));
      setActiveTab((prev) => ({
        ...prev,
        documentUploads: {
          ...prev.documentUploads,
          tabsArray: tabs,
        },
      }));
      setIsNext(true);
    },
    onError: (error) => {
      setSnackBarState({
        open: true,
        message: "Something went wrong",
        severity: "error",
      });
    },
  });

  const clearNamePassport = () => {
    const confirm = window.confirm("Are you sure to delete the file?");
    if (confirm) {
      mutateDeletePassport("photos.passportPhoto");
    }
  };

  const clearNamePersonal = () => {
    const confirm = window.confirm("Are you sure to delete the file?");
    if (confirm) {
      mutateDeletePersonal("photos.personalPhoto");
    }
  };

  const { mutate: mutateDeletePassport, isLoading: isDeletePassportLoading } =
    useMutation({
      mutationFn: deleteDocsAPI,
      onSuccess: () => {
        setFileNames((prev) => ({
          ...prev,
          passport: "",
        }));
        setIsDisable((prev) => ({
          ...prev,
          passportPhoto: false,
        }));
        setPassportValue(null);
        setSnackBarState({
          open: true,
          message: "File Deleted!",
          severity: "success",
        });
      },
      onError: () => {
        setIsDisable((prev) => ({
          ...prev,
          passportPhoto: true,
        }));
      },
    });

  const { mutate: mutateDeletePersonal, isLoading: isDeletePersonaltLoading } =
    useMutation({
      mutationFn: deleteDocsAPI,
      onSuccess: () => {
        setFileNames((prev) => ({
          ...prev,
          personal: "",
        }));
        setIsDisable((prev) => ({
          ...prev,
          personalPhoto: false,
        }));
        setPersonalValue(null);
        setSnackBarState({
          open: true,
          message: "File Deleted!",
          severity: "success",
        });
      },
      onError: () => {
        setIsDisable((prev) => ({
          ...prev,
          personalPhoto: true,
        }));
      },
    });

  return (
    <ContainerComponent>
      <Grid container direction="row" columnSpacing={5}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <TitleBox label={"Passport Photo"}>
            <Dropzone
              className="dropzone"
              disabled={isDisable.passportPhoto}
              onDrop={(files) => onDropPassport(files)}
              onReject={(files: FileRejection[]) => handleReject(files)}
              maxSize={FILE_SIZE}
              accept={FILE_TYPE_PHOTOS}
            >
              <ColorButton
                variant="contained"
                startIcon={<img src={SVGICONS.UploadDocumentIcon} />}
                sx={uploadButton}
              >
                Upload File
              </ColorButton>
              <DisplayMessage fileType={"JPG/JPEG/PNG"} />
            </Dropzone>
            {fileNames.passport !== "" ? (
              <UploadDetails
                fileName={fileNames.passport}
                clearName={clearNamePassport}
                isLoading={isDeletePassportLoading}
              />
            ) : (
              <></>
            )}
          </TitleBox>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <TitleBox label={"Personal Photo"}>
            <Dropzone
              disabled={isDisable.personalPhoto}
              onDrop={(files) => onDropPersonal(files)}
              onReject={(files: FileRejection[]) => handleReject(files)}
              maxSize={FILE_SIZE}
              accept={FILE_TYPE_PHOTOS}
              className="dropzone"
            >
              <ColorButton
                variant="contained"
                startIcon={<img src={SVGICONS.UploadDocumentIcon} />}
                sx={uploadButton}
              >
                Upload File
              </ColorButton>
              <DisplayMessage fileType={"JPG/JPEG/PNG"} />
            </Dropzone>
            {fileNames.personal !== "" ? (
              <UploadDetails
                fileName={fileNames.personal}
                clearName={clearNamePersonal}
                isLoading={isDeletePersonaltLoading}
              />
            ) : (
              <></>
            )}
          </TitleBox>
        </Grid>
      </Grid>

      <Grid container justifyContent="flex-end">
        <div style={{ marginRight: "1em" }}>
          <ButtonComponent
            handleSubmit={handleSubmit}
            buttonText={"Previous"}
            width="100px"
          />
        </div>
        <ButtonComponent
          handleSubmit={handleSubmit}
          buttonText={isNext ? "Next" : "Save"}
          width="100px"
          isLoading={isNext ? false : isLoading}
        />
      </Grid>
      <SnackBar
        snackbarOpen={snackBarState.open}
        handleCloseSnackbar={handleCloseSnackbar}
        message={snackBarState.message}
        severity={snackBarState.severity}
      />
    </ContainerComponent>
  );
};

export default Photos;
