export const basicDetailsKeys = [
    "name",
    "emailID",
    "mobileNo",
    "gender",
    "married",
    "bloodGroup",
    "birthDate",
    "age",
    "idProofs", //object
    "address", //object
    "isPermanentAddressDifferent",
    "emergencyContact" //object
]


export const basicDetailsMapper = (data: any) => {

    // console.log({ mapdat: Object.entries(data) });

    // const flattenedData = getEntries(data);
    // const lol = flattenedData.filter((item: any) => {
    //     const meow = item[0];
    //     return basicDetailsKeys.find(key => key.toLowerCase() === meow.toLowerCase())
    // })

    // console.log({ lol });


    // every((value: any) => {

    //     const secondVal = value[1];
    //     console.log({ secondVal });
    //     return secondVal !== "" && secondVal !== null && secondVal !== undefined
    // })
    // console.log({ flattenedData });

    return basicDetailsKeys.map(key => {
        const value = data[key];

        if (key === "idProofs") {
            if (value.aadharNo === "") return false
            if (value.panNo === "") return false
        }

        if (key === "address") {
            if (value.present.city === "") return false;
            if (value.present.state === "") return false;
            if (value.present.pin === "") return false;
            if (value.present.addressField === "") return false;
        }

        if (key === "emergencyContact") {
            if (value.name === "") return false
            if (value.relationship === "") return false
            if (value.contactNo === "") return false
        }

        // console.log({ key, value });

        return value !== "" && value !== null && value !== undefined
    }).every(val => val === true)

}