import {
  Avatar,
  Box,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  IAccordionComponents,
  ITab,
} from "../../interfaces/components.interface";
import {
  documentUploadsComponents,
  recruiteeDetailsComponents,
} from "../../pages/Onboarding/components";
import {
  accordionCompletedAtom,
  documentFormSubmittedAtom,
  documentUploadsAtom,
  formDetailsAtom,
  onboardingTabsAtom,
  recruiteeAtom,
  recruiteeFormSubmittedAtom,
} from "../../recoil";
import { useEffect } from "react";

export interface IFormType {
  form: {
    [key: string]: boolean;
  };
  currentActiveStep: number;
}

const StepperComponent = ({ steps }: { steps: IAccordionComponents }) => {
  // const [recruiteeStepNo, setRecruiteeStepNo] = useRecoilState(recruiteeAtom);
  // const [documentUploadsStepNo, setDocumentUploadsStepNo] =
  //   useRecoilState(documentUploadsAtom);

  // const setExp = useSetRecoilState(onboardingTabsAtom);

  // const [activeTab, setActiveTab] = useRecoilState(onboardingTabsAtom);
  // const [recruiteeSubmitState, setRecruiteeSubmitState] = useRecoilState(
  //   recruiteeFormSubmittedAtom
  // );
  // const [documentSubmittedState, setDocumentSubmittedState] = useRecoilState(
  //   documentFormSubmittedAtom
  // );
  // const currentActiveAccordion = useRecoilValue(onboardingTabsAtom);

  // const handleOnClick = (step: ITab, index: number) => {
  //   const componentsToMap = currentActiveAccordion.recruiteeDetailsTabs
  //     ? recruiteeDetailsComponents
  //     : documentUploadsComponents;

  //   const currentFormSubmitted = currentActiveAccordion.recruiteeDetailsTabs
  //     ? recruiteeSubmitState
  //     : documentSubmittedState;

  //   console.log("sexy", { currentFormSubmitted, componentsToMap });

  //   const previousComp = componentsToMap.comp[index === 0 ? index : index - 1];
  //   console.log({ previousComp });
  //   // @ts-ignore
  //   const activeStepCompleted = currentFormSubmitted.form[
  //     previousComp.key
  //   ] as boolean;

  //   // const activeStepCompleted = currentFormSubmitted.form`${previousComp.key}`;

  //   console.log({ activeStepCompleted });

  //   if (
  //     step.id - 1 > currentFormSubmitted.currentActiveStep &&
  //     !activeStepCompleted
  //   ) {
  //     return;
  //   }

  //   if (currentActiveAccordion.recruiteeDetailsTabs) {
  //     setRecruiteeSubmitState((prev) => ({
  //       ...prev,
  //       currentActiveStep: index,
  //     }));
  //   }

  //   if (currentActiveAccordion.documentUploadsTabs) {
  //     setDocumentSubmittedState((prev) => ({
  //       ...prev,
  //       currentActiveStep: index,
  //     }));
  //   }

  //   activeTab.recruiteeDetailsTabs
  //     ? setRecruiteeStepNo(index + 1)
  //     : setDocumentUploadsStepNo(index + 1);
  // };

  const [activeTab, setActiveTab] = useRecoilState(formDetailsAtom);

  //handleClick func
  const handleClick = (step: ITab, index: number) => {
    //user cannot go to the next step unless and until they complete the 1st step
    //on completion of a step they can go to all previous steps and the next step

    const previousStep =
      activeTab.recruiteeDetails.tabsArray[index - 1 >= 0 ? index - 1 : 0];
    if (!previousStep.isCompleted) {
      return;
    }

    if (activeTab.recruiteeDetails.tabExpanded) {
      setActiveTab((prev) => ({
        ...prev,
        recruiteeDetails: {
          ...prev.recruiteeDetails,
          stepCount: step.id,
        },
      }));
    }

    //document uploads
    const previousDocumentUploadsStep =
      activeTab.documentUploads.tabsArray[index - 1 >= 0 ? index - 1 : 0];
    if (!previousDocumentUploadsStep.isCompleted) return;

    if (activeTab.documentUploads.tabExpanded) {
      setActiveTab((prev) => ({
        ...prev,
        documentUploads: {
          ...prev.documentUploads,
          stepCount: step.id,
        },
      }));
    }
  };
  console.log("activeTab", activeTab);
  return (
    <Box sx={{ maxWidth: "600px" }}>
      <Stepper
        connector={<></>}
        // sx={{ background: "red" }}
        activeStep={
          activeTab.recruiteeDetails.tabExpanded
            ? activeTab.recruiteeDetails.stepCount
            : activeTab.documentUploads.stepCount
        }
        orientation="vertical"
      >
        {steps.comp.map((step, index) => (
          <Step key={step.title}>
            <StepLabel
              sx={{
                mb: "20px",
              }}
              StepIconProps={{
                icon: <></>,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  columnGap: "1rem",
                  alignItems: "center",
                  color: "white",
                  cursor: "pointer",
                }}
                // onClick={() =>
                //   activeTab.recruiteeDetails.tabsArray[index].isCompleted &&
                //   handleClick(step, index)
                // }
                onClick={() => handleClick(step, index)}
              >
                <Typography variant="h6" align="right" sx={{ fontSize: "1em" }}>
                  {step.title}
                </Typography>

                {activeTab.recruiteeDetails.tabExpanded && (
                  <Avatar
                    sx={{
                      bgcolor: activeTab.recruiteeDetails.tabsArray.find(
                        (curElem) => curElem.name === step.key
                      )?.isCompleted
                        ? "#2FB27D"
                        : "#49497D",
                    }}
                  >
                    <img src={step.icon} alt="icon" />
                  </Avatar>
                )}

                {activeTab.documentUploads.tabExpanded && (
                  <Avatar
                    sx={{
                      bgcolor: activeTab.documentUploads.tabsArray.find(
                        (curElem) => curElem.name === step.key
                      )?.isCompleted
                        ? "#2FB27D"
                        : "#49497D",
                    }}
                  >
                    <img src={step.icon} alt="icon" />
                  </Avatar>
                )}
              </Box>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default StepperComponent;

{
  /* {activeTab.recruiteeDetailsTabs ? (
                  <Avatar
                    sx={{
                      bgcolor: recruiteeSubmitState.form[step.key]
                        ? "#2FB27D"
                        : "#49497D",
                    }}
                  >
                    <img src={step.icon} alt="icon" />
                  </Avatar>
                ) : (
                  <Avatar
                    sx={{
                      bgcolor: documentSubmittedState.form[step.key]
                        ? "#2FB27D"
                        : "#49497D",
                    }}
                  >
                    <img src={step.icon} alt="icon" />
                  </Avatar>
                )} */
}
