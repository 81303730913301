import styled from "@emotion/styled";
import { Button, ButtonProps } from "@mui/material";

const ColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: "#140047",
  backgroundColor: "#fff",
  borderRadius: "10px",
  width: "144px",
  "&:hover": {
    backgroundColor: "#f0ebff",
  },
}));

export default ColorButton;
