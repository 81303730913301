// export const rootMobile = {
 
//   // zIndex: -2,
// };

export const ellipseOne = {
  position: "fixed",
  left: "104px",
  top: "-131px",
  width: "281px",
  height: "281px",
  filter: "blur(100px)",
  background: "rgba(47, 178, 178, 0.5)",
  zIndex: -1,
};

export const ellipseTwo = {
  position: "fixed",
  borderRadius: "25%",
  width: "450px",
  height: "450px",
  left: "-250px",
  top: "250px",
  filter: "blur(70px)",
  zIndex: -1,
  background: "rgba(171, 96, 230, 0.5)",
};

export const ellipseThree = {
  position: "fixed",
  width: "272px",
  height: "272px",
  right: "-120px",
  bottom: "-100px",
  filter: "blur(75px)",
  background: "rgba(47, 178, 125, 0.5)",
  zIndex: -1,
};

// MOBILE VIEW:
export const ellipseOneMobile = {
  position: "fixed",
  width: "281px",
  height: "281px",
  left: " 207px",
  top: "-102px",
  filter: "blur(100px)",
  background: "rgba(47, 178, 178, 0.5)",
  zIndex: -1,
};

export const ellipseTwoMobile = {
  position: "fixed",
  width: "450px",
  height: "450px",
  left: "-250px",
  top: " 200px",
  filter: "blur(70px)",
  background: "rgba(171, 96, 230, 0.5)",
  zIndex: -1,
};

export const ellipseThreeMobile = {
  position: "fixed",
  width: "272px",
  height: "272px",
  right: "-120px",
  bottom: "-100px",
  filter: "blur(75px)",
  background: "rgba(47, 178, 125, 0.5)",
  zIndex: -1,
};
