import React, { useEffect, useState } from "react";
import { StyledTextField } from "../../../../components/StyledComponents/StyledTextField/StyledTextField";

interface TextFieldEducationProps {
  handleChangeData: (value: string | number, name: string) => void;
  name: string;
  type: "text" | "number" | "date";
  value: string | number;
}

const TextFieldEducation = ({
  handleChangeData,
  name,
  type,
  value = "",
}: TextFieldEducationProps) => {
  const [text, setText] = useState<string | number>("");

  useEffect(() => {
    if (value !== "") {
      setText(value);
    }
  }, [value]);

  const handleChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setText(e.target.value);
  };

  return (
    <StyledTextField
      autoComplete="off"
      value={text}
      type={type}
      onChange={(e) => handleChange(e)}
      onBlur={() => handleChangeData(text, name)}
      sx={{ width: "100%" }}
    />
  );
};

export default TextFieldEducation;
