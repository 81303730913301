import { Grid, InputLabel, Typography } from "@mui/material";
import { StyledSpanError } from "../../../../components/StyledComponents/StyledSpanError";
import TitleBox from "../../../../components/TitleBox/TitleBox";
import { gridContainer, textColor } from "../styleParent";
import _ from "lodash";
import TextFieldEducation from "./TextFieldEducation";
import { FormikErrors } from "formik";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { StyledTextField } from "../../../../components/StyledComponents/StyledTextField/StyledTextField";

interface EducationFormInterface {
  gridLabel: string;
  label: string[];
  name: any;
  formik: any;
  errors:
    | FormikErrors<{
        institute: string;
        yearOfPassing: string;
        percentage: number;
        subjects: string;
      }>
    | undefined;
  values: {
    institute: string;
    percentage: number;
    subjects: string;
    yearOfPassing: string;
  };
  setFieldValue: any;
}

const EducationForm = ({
  gridLabel,
  label,
  name,
  formik,
  errors,
  values,
  setFieldValue,
}: EducationFormInterface) => {
  const handleChangeValue = (value: string | number, name: string) => {
    setFieldValue(name, value);
  };

  function handleChangeDate(dateData: number | null) {
    // console.log("data", new Date(dateData as number).toISOString());
    if (dateData) {
      setFieldValue(
        `${name}.yearOfPassing`,
        new Date(dateData as number).toISOString()
      );
    }
  }

  return (
    <TitleBox label={gridLabel}>
      <Grid container columnSpacing={10} rowSpacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <InputLabel shrink htmlFor="bootstrap-input" sx={textColor}>
            {label[0]} *
          </InputLabel>
          <TextFieldEducation
            type="text"
            value={values.institute}
            name={`${name}.institute`}
            handleChangeData={handleChangeValue}
          />
          <StyledSpanError>{errors?.institute}</StyledSpanError>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <InputLabel shrink htmlFor="bootstrap-input" sx={textColor}>
            {label[1]} *
          </InputLabel>
          <DesktopDatePicker
            // label="Date desktop"
            disableFuture
            inputFormat="DD/MM/YYYY"
            value={values.yearOfPassing}
            onChange={handleChangeDate}
            renderInput={(params) => (
              <StyledTextField
                autoComplete="off"
                {...params}
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
              />
            )}
          />
          {/* <TextFieldEducation
            value={values.yearOfPassing}
            type="date"
            name={`${name}.yearOfPassing`}
            handleChangeData={handleChangeValue}
          /> */}
          <StyledSpanError>{errors?.yearOfPassing}</StyledSpanError>
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={6}>
          <InputLabel shrink htmlFor="bootstrap-input" sx={textColor}>
            {label[2]} *
          </InputLabel>
          <TextFieldEducation
            value={values.percentage}
            type="number"
            name={`${name}.percentage`}
            handleChangeData={handleChangeValue}
          />
          <StyledSpanError>{errors?.percentage}</StyledSpanError>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <InputLabel shrink htmlFor="bootstrap-input" sx={textColor}>
            {label[3]}
          </InputLabel>
          <TextFieldEducation
            value={values.subjects}
            type="text"
            name={`${name}.subjects`}
            handleChangeData={handleChangeValue}
          />
          <Typography
            variant="body1"
            fontSize=".625rem"
            mt=".375rem"
            fontStyle="italic"
          >
            Subjects to be filled in comma seperated
          </Typography>
        </Grid>
      </Grid>
    </TitleBox>
  );
};

export default EducationForm;
