import { Alert, Snackbar } from "@mui/material";
import { SnackbarInterface } from "../../interfaces";

const SnackBar = ({
  snackbarOpen,
  handleCloseSnackbar,
  message,
	severity
}: SnackbarInterface) => {
  return (
    <Snackbar
      open={snackbarOpen}
      autoHideDuration={6000}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      onClose={handleCloseSnackbar}
			
    >
      <Alert
        onClose={handleCloseSnackbar}
        severity={severity}
        sx={{ width: "100%" }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default SnackBar;
