import React from "react";
import { Grid, InputLabel } from "@mui/material";
import { StyledTextField } from "../../../../../components/StyledComponents/StyledTextField/StyledTextField";
import { StyledSpanError } from "../../../../../components/StyledComponents/StyledSpanError";
import DropdownComponent from "../../../../../components/Dropdown/DropdownComponent";
import moment from "moment";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker/DesktopDatePicker";

const DependentItems = [
  {
    id: 1,
    value: "true", //convert to boolean
    text: "Yes",
  },
  {
    id: 2,
    value: "false",
    text: "No",
  },
];

const FamilyDetailComponent = ({
  parent,
  parentLabel,
  parentValues,
  parentErrors,
  handleBlur,
  handleChange,
  setFieldValue,
}: any) => {
  // console.log("eed", parentValues);
  // console.log("makasaka", parentValues);
  // const formattedDate = moment(parentValues.dateOfBirth).format("YYYY-MM-DD");

  function handleChangeDate(dateData: number | null) {
    console.log("data2", new Date(dateData as number).toISOString());
    setFieldValue(
      `family.${parent}.dateOfBirth`,
      new Date(dateData as number).toISOString()
    );
  }

  return (
    <>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <InputLabel
          shrink
          htmlFor="bootstrap-input"
          sx={{ color: "white", fontSize: "0.75rem" }}
        >
          {parentLabel} *
        </InputLabel>
        <StyledTextField
          autoComplete="off"
          hiddenLabel
          fullWidth
          name={`family.${parent}.name`}
          value={parentValues.name}
          onBlur={handleBlur}
          onChange={handleChange}
        />
        <StyledSpanError>{parentErrors?.name}</StyledSpanError>
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <InputLabel
          shrink
          htmlFor="bootstrap-input"
          sx={{ color: "white", fontSize: "0.75rem" }}
        >
          Dependent *
        </InputLabel>
        <DropdownComponent
          name={`family.${parent}.dependent`}
          value={parentValues.dependent}
          handleChange={handleChange}
          items={DependentItems}
        />
        <StyledSpanError>{parentErrors?.dependent}</StyledSpanError>
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <InputLabel
          shrink
          htmlFor="bootstrap-input"
          sx={{ color: "white", fontSize: "0.75rem" }}
        >
          Occupation *
        </InputLabel>
        <StyledTextField
          autoComplete="off"
          hiddenLabel
          fullWidth
          name={`family.${parent}.occupation`}
          value={parentValues.occupation}
          onBlur={handleBlur}
          onChange={handleChange}
        />
        <StyledSpanError>{parentErrors?.occupation}</StyledSpanError>
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <InputLabel
          shrink
          htmlFor="bootstrap-input"
          sx={{ color: "white", fontSize: "0.75rem" }}
        >
          Date of Birth *
        </InputLabel>
        <DesktopDatePicker
          disableFuture
          inputFormat="DD/MM/YYYY"
          value={parentValues.dateOfBirth}
          // value={}
          onChange={handleChangeDate}
          renderInput={(params) => (
            <StyledTextField
              autoComplete="off"
              {...params}
              onKeyDown={(e) => {
                e.preventDefault();
              }}
            />
          )}
        />
        {/* <StyledTextField
          type="date"
          hiddenLabel
          fullWidth
          name={`family.${parent}.dateOfBirth`}
          // value={parentValues.dateOfBirth}
          value={formattedDate}
          onBlur={handleBlur}
          onChange={handleChange}
        /> */}
        <StyledSpanError>{parentErrors?.dateOfBirth}</StyledSpanError>
      </Grid>
    </>
  );
};

export default FamilyDetailComponent;
