import { TextField } from '@mui/material';
import { styled } from '@mui/system'
import { theme } from '../../../theme';


export const StyledTextField = styled(TextField)({
    width: "100%",
    "& .MuiIconButton-edgeEnd": {
        color: "#c4c4c4",
        size: "2px"
    },
    // marginBottom: "1.93em",
    "& .MuiInputBase-root": {
        fontWeight: 500,
        height: "2.875rem",
        outline: "none",
        border: '1px solid #381A84',
        borderRadius: "10px",
        color: "white",
        fontSize: "0.875rem",
        "& > .Mui-disabled": {
            textFillColor: "white",
        },
        "input:-webkit-autofill": {
            backgroundColor: "transparent !important",
            color: "white !important"
        },
        "input:-webkit-autofill:hover": {
            backgroundColor: "transparent !important",
            color: "white !important"
        },
        "input:-webkit-autofill:focus": {
            backgroundColor: "transparent !important",
            color: "white !important"
        }
    },
    "& .Mui-focused": {
        "& fieldset": {
            border: "none",
            outline: "none"
        },
        fontWeight: 600,
        border: "1px solid #2FB27D", //green
        borderRadius: "10px",
        boxShadow: "0px 0px 8px rgba(47, 178, 125, 0.65)",
        color: "white",
        fontSize: "0.875rem"
    },
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            border: "none",
            borderColor: "transparent",
            // borderWidth: "1px !important",
        },
    }

}) as typeof TextField