export const gridContainer = {
  margin: "2em",
  border: "1px solid #381A84",
  borderRadius: "10px",
};

export const textColor = {
  color: "#fff",
  fontSize: "0.75rem",
};

export const prevButton = { marginRight: "1em" };
