import { FormControlLabel, Checkbox } from "@mui/material";
import { useState, useEffect } from "react";

const CheckboxComponent = ({ name, label, isChecked, checkboxChange }: any) => {
  return (
    <>
      <FormControlLabel
        sx={{
          "& .MuiTypography-root": {
            fontSize: "0.75rem",
            fontWeight: 400,
          },
        }}
        control={
          <Checkbox
            sx={{
              color: "white",
              "& .MuiSvgIcon-root": {
                width: "1rem",
              },
            }}
            disableRipple
            name={name}
            onChange={checkboxChange}
            size="small"
            checked={isChecked}
          />
        }
        label={label}
      />
    </>
  );
};

export default CheckboxComponent;
