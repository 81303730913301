export const uploadButton = {
  background: "#fff",
  color: "#140047",
  marginTop: "1.2rem"
};

export const dropZoneContainer = {
  border: "1px dashed #fff",
  borderRadius: "10px",
  padding: "1em",
  background: "transparent",
  display: "flex",
  flexDirection: "column",
  width: "100%",
  height: "173px",
  justifyContent: "center",
  textAlign: "center",
  "&:hover": {
    backgroundColor: "transparent",
  },
};

export type IMAGE_TYPE = (
  | "image/png"
  | "image/jpg"
  | "image/jpeg"
  | "image/pdf"
  | "image/docx"
  | "image/doc"
)[];
