import { Box, Tab, Tabs } from "@mui/material";
import { useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  documentUploadsComponents,
  formDetailsComponents,
  IFormDetailsComponents,
  onboardingComponents,
  recruiteeDetailsComponents,
} from "../../pages/Onboarding/components";
import {
  accordionCompletedAtom,
  documentUploadsAtom,
  formDetailsAtom,
  onboardingTabsAtom,
  recruiteeAtom,
} from "../../recoil";
import InsideTabs from "./InsideTabs";

const HeaderTabs = () => {
  // const [value, setValue] = useState(1);
  // const [currentActiveTab, setCurrentActiveTab] = useState(1);
  // // const [currentActiveTab, setCurrentActiveTab] =
  // //   useRecoilState(tabsNumberAtom);
  // const [activeTab, setActiveTab] = useRecoilState(onboardingTabsAtom);

  // const accordionCompleted = useRecoilValue(accordionCompletedAtom);

  // const handleChange = (e: any, newvalue: number) => {
  //   setActiveTab((prev) => ({
  //     recruiteeDetailsTabs: !prev.recruiteeDetailsTabs,
  //     documentUploadsTabs: !prev.documentUploadsTabs,
  //   }));
  //   setValue(newvalue);
  //   setCurrentActiveTab(newvalue);
  // };

  // const handleClick = (tab: any) => {
  //   if (tab.id === 2) {
  //     return false;
  //   }
  // };

  // console.log("accord", currentActiveTab);

  const [activeTab, setActiveTab] = useRecoilState(formDetailsAtom);

  const handleClick = (tabKey: "recruiteeDetails" | "documentUploads") => {
    if (!activeTab.recruiteeDetails.tabCompleted) return;
    setActiveTab((prev) => ({
      ...prev,
      [tabKey]: {
        ...prev[tabKey],
        tabExpanded: true,
      },
      [tabKey === "recruiteeDetails" ? "documentUploads" : "recruiteeDetails"]:
        {
          ...prev[
            tabKey === "recruiteeDetails"
              ? "documentUploads"
              : "recruiteeDetails"
          ],
          tabExpanded: false,
        },
    }));
  };

  return (
    <Box
      sx={{
        width: "100%",
        typography: "body1",
      }}
    >
      <Box
        sx={{
          borderRadius: "30px",
          background: "#49497D",
          padding: "3px",
        }}
      >
        <Tabs
          // value={2}
          // onChange={handleChange}
          indicatorColor="secondary"
          textColor="inherit"
          variant="fullWidth"
          aria-label="full width tabs example"
          sx={{ fontWeight: "600" }}
          TabIndicatorProps={{
            style: {
              background: "transparent",
            },
          }}
        >
          {/* {formDetailsComponents.map((curTab: IFormDetailsComponents) => (
            <Tab
              // disabled={
              //   curTab.id === 2 && !accordionCompleted.recruiteeCompleted
              // }
              label={curTab.title}
              // value={curTab.id}
              value={activeTab.recruiteeDetails.tabExpanded}
              disableRipple
              sx={{
                opacity: 1,
                background: curTab.id === 1 ? "#2FB27D" : "none",
                borderRadius: curTab.id === 1 ? "30px" : "none",
                fontWeight: "600",
                textTransform: "capitalize",
                fontSize: "0.75em",
                height: "2.125em",
              }}
              onClick={() => handleClick(curTab)}
            />
          ))} */}

          <Tab
            label="Recruitee Details"
            value={activeTab.recruiteeDetails.tabExpanded}
            disableRipple
            sx={{
              opacity: 1,
              background: activeTab.recruiteeDetails.tabExpanded
                ? "#2FB27D"
                : "none",
              borderRadius: activeTab.recruiteeDetails.tabExpanded
                ? "30px"
                : "none",
              fontWeight: "600",
              textTransform: "capitalize",
              fontSize: "0.75em",
              height: "2.125em",
            }}
            onClick={() => handleClick("recruiteeDetails")}
          />
          <Tab
            label="Document Uploads"
            value={activeTab.documentUploads.tabExpanded}
            disableRipple
            sx={{
              opacity: 1,
              background: activeTab.documentUploads.tabExpanded
                ? "#2FB27D"
                : "none",
              borderRadius: activeTab.documentUploads.tabExpanded
                ? "30px"
                : "none",
              fontWeight: "600",
              textTransform: "capitalize",
              fontSize: "0.75em",
              height: "2.125em",
            }}
            onClick={() => handleClick("documentUploads")}
          />
        </Tabs>
      </Box>
      <Box>
        <InsideTabs
          activeHeaderTab={
            activeTab.recruiteeDetails.tabExpanded
              ? recruiteeDetailsComponents
              : documentUploadsComponents
          }
        />
      </Box>
    </Box>
  );
};

export default HeaderTabs;
