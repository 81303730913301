import { CSSObject } from "@mui/material/styles";

export const switchRoot: CSSObject = {
  marginLeft: "7%",
  height: "80px",
  width: "70vw",
};

export const gridLabel: CSSObject = {
  marginTop: "-25px",
  textAlign: "center",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "12px",
  lineHeight: "18px",
  color: "#FFFFFF",
};

export const checkboxContainer: CSSObject = {
  padding: "1em",
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  justifyContent: "flex-start",
};

export const checkboxText: CSSObject = {
  padding: "1em",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "12px",
  textAlign: "center",
};

export const formContainer: CSSObject = { marginBottom: "1em" };
