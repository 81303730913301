import { useState } from "react";
import { Box, useTheme, useMediaQuery } from "@mui/material";

const ContainerComponent = ({ children }: any) => {
  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.down("md"));

  const mobileStyle = {
    my: "20px",
    px: "1em",
    py: "1.25em",
    minHeight: "60vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    // border: "1px solid yellow",
  };

  const desktopStyle = {
    px: "2.5em",
    py: "1.25em",
    minHeight: "80vh",
    // border: "1px solid red",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  };

  return <Box sx={isMedium ? mobileStyle : desktopStyle}>{children}</Box>;
};

export default ContainerComponent;
