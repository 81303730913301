export const educationSchema = {
  class10: {
    institute: "",
    yearOfPassing: "",
    percentage: 0,
    subjects: "",
  },
  class12: {
    institute: "",
    yearOfPassing: "",
    percentage: 0,
    subjects: "",
  },
  isDiploma: false,
  diploma: {
    institute: "",
    yearOfPassing: "",
    percentage: 0,
    subjects: "",
  },
  isGraduate: false,
  graduation: {
    institute: "",
    yearOfPassing: "",
    percentage: 0,
    subjects: "",
  },
  isPostGraduate: false,
  postGraduation: {
    institute: "",
    yearOfPassing: "",
    percentage: 0,
    subjects: "",
  },
};
