import { Grid, Typography } from "@mui/material";
import CircularLoader from "../../../../components/CircularLoader";
import { SVGICONS } from "../../../../constants";
import { UploadDetailsInterface } from "../../../../interfaces";

const UploadDetails = ({
  fileName,
  clearName,
  isLoading,
}: UploadDetailsInterface) => {
  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      sx={{
        margin: "1em",
        padding: "10px",
        border: "1px solid #fff",
        borderRadius: "10px",
        flexWrap: "nowrap",
      }}
    >
      <Grid item>
        <img src={SVGICONS.FileIcon} />
        <span
          style={{
            paddingLeft: "10px",
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "14px",
          }}
        >
          {fileName}
        </span>
      </Grid>
      <Grid item sx={{ cursor: "pointer" }}>
        {isLoading ? (
          <CircularLoader />
        ) : (
          <img src={SVGICONS.CloseIcon} onClick={clearName} />
        )}
      </Grid>
    </Grid>
  );
};

export default UploadDetails;
