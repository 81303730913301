import { CircularProgress } from "@mui/material";

const CircularLoader = () => {
  return (
    <div style={{ textAlign: "center" }}>
      <CircularProgress size={20} color="inherit" />
    </div>
  );
};

export default CircularLoader;
