import { CSSObject } from "@mui/material/styles";

export const descriptionTypo: CSSObject = {
  paddingTop: "30px",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: "700",
  fontSize: "15px",
  color: "#14213D",
};

export const textFieldContainer: CSSObject = {
  textAlign: "center",
  paddingTop: "16px",
};

export const textField: CSSObject = {
  border: "0.5px solid rgba(0, 0, 0, 0.25)",
  borderRadius: "30px",
  paddingTop: "0.5em",
  paddingBottom: "0.5em",
  width: "250px",
  height: "27px",
  paddingRight: "1.2em"
};

export const textFieldInputAdornment: CSSObject = {
  paddingLeft: "0.5em",
};

export const buttonContainer: CSSObject = {
  paddingTop: "25px",
  paddingBottom: "10px",
};

export const resendTypography: CSSObject = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 800,
  fontSize: "10px",
  color: "#14213D",
  cursor: "pointer",
};
