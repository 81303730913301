import "./styles.scss";
import { DotHoverInterface } from "../../interfaces";
import { useEffect, useRef, useState } from "react";
import { useMediaQuery, useTheme } from "@mui/material";

const DotHover = ({ children }: DotHoverInterface) => {
  const followRef = useRef<any>();
  const [mousePos, setMousePos] = useState<any>({});

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    const handleMouseMove = (event: any) => {
      setMousePos({ x: event.clientX, y: event.clientY });
    };
    window.addEventListener("mousemove", handleMouseMove);
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  useEffect(() => {
    followRef.current.style.top = `${mousePos.y}px`;
    followRef.current.style.left = `${mousePos.x}px`;
  }, [mousePos]);

  return (
    <div className="card">
      <div className="follow" ref={followRef}></div>
      <div className="card__img">
        {children}
        <div
          className={isSmall ? "card__grid-effect-phone" : "card__grid-effect"}
        >
          {[...Array(2000)].map((x, i) => (
            <span
              className={
                isSmall
                  ? "card__grid-effect-phone-tile"
                  : "card__grid-effect-tile"
              }
              key={i}
            ></span>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DotHover;
