import { IBankDetails } from "../../../interfaces/forms/bankDetails.interface";
import { IBasicDetails } from "../../../interfaces/forms/basicDetails.interface";
import { EducationDetails } from "../../../interfaces/forms/educationDetail.interface";
import { IEmployeeInformation } from "../../../interfaces/forms/employeeInformation.interface";
import { IFamilyLanguage } from "../../../interfaces/forms/familyLanguage.interface";
import {
  IExperienceDetails,
  IWorkExperience,
} from "../../../interfaces/forms/workExperience.interface";

export const setBasicDetailsDefaultValue = (
  initialData: IBasicDetails
): any => {
  // console.log("inidata", initialData);

  return {
    name: initialData?.name,
    age: initialData?.age ?? "",
    gender: initialData?.gender ?? "",
    married: initialData?.married ?? "",
    bloodGroup: initialData?.bloodGroup ?? "",
    birthDate: initialData?.birthDate ?? "",
    emailID: initialData?.emailID,
    idProofs: {
      aadharNo: initialData?.idProofs?.aadharNo ?? "",
      panNo: initialData?.idProofs?.panNo ?? "",
      voterIdNo: initialData?.idProofs?.voterIdNo ?? "",
      passportNo: initialData?.idProofs?.passportNo ?? "",
    },
    mobileNo: initialData?.mobileNo,
    address: {
      present: {
        city: initialData?.address?.present?.city ?? "",
        state: initialData?.address?.present?.state ?? "",
        pin: initialData?.address?.present?.pin ?? "",
        addressField: initialData?.address?.present?.addressField ?? "",
      },
      permanent: {
        city: initialData?.address?.permanent?.city ?? "",
        state: initialData?.address?.permanent?.state ?? "",
        pin: initialData?.address?.permanent?.pin ?? "",
        addressField: initialData?.address?.permanent?.addressField ?? "",
      },
    },
    isPermanentAddressDifferent:
      initialData?.isPermanentAddressDifferent ?? false,
    emergencyContact: {
      name: initialData?.emergencyContact?.name ?? "",
      relationship: initialData?.emergencyContact?.relationship ?? "",
      contactNo: initialData?.emergencyContact?.contactNo ?? "",
    },
  };
};

export const setBankDetailsDefaultValue = (initialData: IBankDetails) => {
  return {
    bankName: initialData.bankName ?? "",
    accountNo: initialData.accountNo ?? "",
    IFSC: initialData.IFSC ?? "",
  };
};

export const setEmployeeInformationDefaultValue = (
  initialData: IEmployeeInformation
) => {
  return {
    employeeNo: initialData.employeeNo ?? 0,
    department: initialData.department ?? "",
    status: initialData.status ?? true,
    designation: initialData.designation ?? "",
  };
};

export const setFamilyLanguageDefaultValue = (
  initialData: IFamilyLanguage
): IFamilyLanguage => {

  return {
    family: {
      father: {
        name: initialData?.family?.father.name ?? "",
        dependent: initialData?.family?.father.dependent ? true : false,
        occupation: initialData?.family?.father.occupation ?? "",
        dateOfBirth: initialData?.family?.father.dateOfBirth ?? "",
      },
      mother: {
        name: initialData?.family?.mother.name ?? "",
        dependent: initialData?.family?.mother.dependent ? true : false,
        occupation: initialData?.family?.mother.occupation ?? "",
        dateOfBirth: initialData?.family?.mother.dateOfBirth ?? "",
      },
      spouse: {
        name: initialData?.family?.spouse?.name ?? "",
        dependent: initialData?.family?.spouse?.dependent ? true : false,
        occupation: initialData?.family?.spouse?.occupation ?? "",
        dateOfBirth: initialData?.family?.spouse?.dateOfBirth ?? "",
      },
    },
    languages: [
      {
        languageName: "English",
        read: initialData.languages[0].read,
        write: initialData.languages[0].write,
        speak: initialData.languages[0].speak,
      },
      {
        languageName: "Hindi",
        read: initialData.languages[1].read,
        write: initialData.languages[1].write,
        speak: initialData.languages[1].speak,
      },
      {
        languageName: "Assamese",
        read: initialData.languages[2].read,
        write: initialData.languages[2].write,
        speak: initialData.languages[2].speak,
      },
    ],
  };
};

export const setEducationDefaultValue = (initialData: EducationDetails) => {
  // console.log({ eduData: initialData });
  return {
    class10: {
      institute: initialData?.class10?.institute ?? "",
      yearOfPassing: initialData?.class10?.yearOfPassing ?? "",
      percentage: initialData?.class10?.percentage ?? 0,
      subjects: initialData?.class10?.subjects?.join(",") ?? "",
    },
    class12: {
      institute: initialData?.class12?.institute ?? "",
      yearOfPassing: initialData?.class12?.yearOfPassing ?? "",
      percentage: initialData?.class12?.percentage ?? 0,
      subjects: initialData?.class12?.subjects?.join(",") ?? "",
    },
    isDiploma: initialData?.diploma?.institute !== "" ? true : false,
    diploma: {
      institute: initialData?.diploma?.institute ?? "",
      yearOfPassing: initialData?.diploma?.yearOfPassing ?? "",
      percentage: initialData?.diploma?.percentage ?? 0,
      subjects: initialData?.diploma?.subjects?.join(",") ?? "",
    },
    isGraduate: initialData?.graduation?.institute !== "" ? true : false,
    graduation: {
      institute: initialData?.graduation?.institute ?? "",
      yearOfPassing: initialData?.graduation?.yearOfPassing ?? "",
      percentage: initialData?.graduation?.percentage ?? 0,
      subjects: initialData?.graduation?.subjects?.join(",") ?? "",
    },
    isPostGraduate: initialData?.postGraduation?.institute !== "" ? true : false,
    postGraduation: {
      institute: initialData?.postGraduation?.institute ?? "",
      yearOfPassing: initialData?.postGraduation?.yearOfPassing ?? "",
      percentage: initialData?.postGraduation?.percentage ?? 0,
      subjects: initialData?.postGraduation?.subjects?.join(",") ?? "",
    },
  };
};

export const setWorkExperienceDefaultValue = (
  initialData: IExperienceDetails[]
) => {
  let expArray: any = [];
  if (initialData.length > 0) {
    initialData.forEach((value: any, index: number) => {
      let workExps = [...initialData];
      let exps: any = { ...workExps[index] };
      exps.organization = value.organization;
      exps.tctc = value.tctc;
      exps.designation = value.designation;
      exps.startDate = value.startDate;
      exps.endDate = value.endDate;
      exps.reasonForLeaving = value.reasonForLeaving;

      expArray.push(exps);
    });
    const modifiedData = {
      workExperience: expArray,
    };
    return modifiedData;
  } else {
    return [];
  }
};
