import { Grid, Box } from "@mui/material";
import AccordionComponent from "../Accordion/AccordionComponent";
import LineComponent from "../LineComponent/LineComponent";

const AccordionWrapper = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <AccordionComponent />
      {/* <LineComponent /> */}
    </div>
  );
};

export default AccordionWrapper;
