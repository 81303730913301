import * as yup from 'yup';

const parentSchema = yup.object().shape({
    name: yup.string().required('Required!'),
    dependent: yup.string().required('Required!'),
    occupation: yup.string().required('Required!'),
    dateOfBirth: yup.string().required('Required!')
})

const familySchema = yup.object().shape({
    mother: parentSchema,
    father: parentSchema
})

const familySpouseSchema = yup.object().shape({
    spouse: parentSchema,
    mother: parentSchema,
    father: parentSchema
})

const langaugeSchema = yup.array().of(
    yup.object().shape({
        languageName: yup.string(),
        speak: yup.boolean(),
        read: yup.boolean(),
        write: yup.boolean()
    })
)

export const familyLanguageVSchema = yup.object().shape({
    family: familySchema,
    languages: langaugeSchema
})

export const familyLanguageSpouseVSchema = yup.object().shape({
    family: familySpouseSchema,
    languages: langaugeSchema
})