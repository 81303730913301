import { Box, Typography } from "@mui/material";
import React from "react";

const PageNotFound = () => {
  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography
        variant="h5"
        sx={{
          color: "white",
          display: "flex",
          alignItems: "center",
          fontWeight: 500,
        }}
      >
        404 |
        <Typography variant="h6" sx={{ color: "#ccc", ml: "0.5rem" }}>
          Page Not Found
        </Typography>
      </Typography>
    </Box>
  );
};

export default PageNotFound;
