import { styled } from "@mui/system";

export const StyledLoginDiv = styled("div")({
  margin: "24px",
  boxShadow: "0px 0px 100px rgba(255, 255, 255, 0.25)",
  borderRadius: "30px",
  minHeight: "350px",
  width: "500px",
  minWidth: "5px !important",
  padding: "1em",
  backgroundColor: "#fff",
  textAlign: "center",
  zIndex: 2000,
});
