import React from "react";
import { Grid, Box, Typography, useTheme, useMediaQuery } from "@mui/material";

const TitleBox = ({ label, children }: any) => {
  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.down("md"));

  const paddingStyle = isMedium ? "1.5em" : "2em";

  return (
    <Box
      sx={{
        position: "relative",
        border: "1px solid #381A84",
        borderRadius: "10px",
        p: `${paddingStyle}`,
        mb: "2rem",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography
        sx={{
          position: "absolute",
          top: "-14px",
          px: "10px",
          py: "5px",
          backgroundColor: "#140047",
          fontSize: "0.75rem",
          fontWeight: 400,
          //   border: "1px solid red"
        }}
      >
        {label}
      </Typography>
      {children}
    </Box>
  );
};

export default TitleBox;
