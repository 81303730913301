import TVLogo from "../../assets/images/TV-logo-white.svg";
import {
  Box,
  Typography,
  Divider,
  useTheme,
  useMediaQuery,
  Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ICONS } from "../../constants";
import ButtonComponent from "../../components/ButtonComponent";
import { axiosInstance } from "../../api/axios.global";
import axios from "axios";

const HeaderComponent = () => {
  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.down("md"));

  const navigate = useNavigate();

  const headerstyle = isMedium ? "column-reverse" : "row";
  const headerstyle2 = isMedium ? "normal" : "center";
  const boxStyle = isMedium ? "30px" : "0";
  const wrapperMargin = isMedium ? "1.5em" : "4.375em";

  const logoutstyle = isMedium ? "row-reverse" : "row";

  const logoutHandler = () => {
    sessionStorage.clear();
    navigate("/");
  };

  // const deleteHandler = async () => {

  //   console.log("deletetete");
  //   await axiosInstance.delete(
  //     `/on-boarding/delete/temp-upload-docs/academicCertificates.class10th`
  //   );
  // };
  return (
    <>
      {/* <button onClick={deleteHandler}>DELETE</button> */}

      <Box
        display="flex"
        flexDirection={headerstyle}
        alignItems={headerstyle2}
        justifyContent="space-between"
        marginTop="40px"
        marginLeft={wrapperMargin}
        marginRight={wrapperMargin}
      >
        <Box>
          <Typography variant="h5" fontWeight={600}>
            Onboarding Details
          </Typography>
          <Typography
            fontWeight={400}
            sx={{ opacity: 0.5, fontSize: "0.75em" }}
          >
            Follow these simple steps to complete your onboarding
          </Typography>
        </Box>
        <Box
          sx={{
            pb: boxStyle,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          flexDirection={logoutstyle}
          columnGap="1rem"
        >
          <Box sx={{ mt: "-1rem" }}>
            <ButtonComponent
              handleSubmit={logoutHandler}
              buttonText={"Logout"}
              width="100px"
            />
          </Box>
          <img src={TVLogo} alt="logo" />
        </Box>
      </Box>

      <Divider
        sx={{
          background: "#381A84",
          // background:"white",
          my: "20px",
        }}
        variant="middle"
      />
    </>
  );
};

export default HeaderComponent;
