import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    primary: {
      main: "#381A84", //border-purple
      light: "#49497D" //inactive-purple
    },
    secondary: {
      main: "#2FB27D", //green
    },
  },
  typography: {
    // h1: {
    //   fontFamily: ""
    // }
    fontFamily: `'Poppins','sans-serif'`,
    fontWeightBold: 600,
    fontWeightMedium: 500,
    fontWeightRegular: 400
  }
});


/* 
  ** bodybackground --> #140047
  ** green highlight --> #2FB27D
  ** purple borders --> #381A84
  ** purple inactive --> #49497D
*/