import { Grid, InputLabel, useMediaQuery, useTheme } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import {
  getUserInfoAPI,
  saveBasicDetailsAPI,
  updateSpouseAPI,
} from "../../../../api/recruitee";
import ButtonComponent from "../../../../components/ButtonComponent";
import CheckboxComponent from "../../../../components/Checkbox/CheckboxComponent";
import CircularLoader from "../../../../components/CircularLoader";
import ContainerComponent from "../../../../components/Container/ContainerComponent";
import DropdownComponent from "../../../../components/Dropdown/DropdownComponent";
import SnackBar from "../../../../components/SnackBar";
import { StyledSpanError } from "../../../../components/StyledComponents/StyledSpanError";
import { StyledTextField } from "../../../../components/StyledComponents/StyledTextField/StyledTextField";
import { ISnackbarDetailsInterface } from "../../../../interfaces";
import { IBasicDetails } from "../../../../interfaces/forms/basicDetails.interface";
import { formDetailsAtom } from "../../../../recoil";
import { basicDetailsSchema } from "../../../../schemas/basicDetailsSchema";
import { basicDetailsVSchema } from "../../../../yup-schemas/basicDetailsVSchema";
import { basicDetailsMapper } from "../../utils/object-mapping/basic-details";
import { eduQualMapper } from "../../utils/object-mapping/edu-qual";
import { setBasicDetailsDefaultValue } from "../../utils/setDefaultValues";

const MaritalStatusItems = [
  {
    id: 1,
    value: "Yes",
    text: "Married",
  },
  {
    id: 2,
    value: "No",
    text: "Unmarried",
  },
];

const GenderItems = [
  {
    id: 1,
    value: "male",
    text: "Male",
  },
  {
    id: 2,
    value: "female",
    text: "Female",
  },
  {
    id: 3,
    value: "other",
    text: "Others",
  },
];

const BloodGroupItems = [
  {
    id: 1,
    value: "A+",
    text: "A+",
  },
  {
    id: 2,
    value: "A-",
    text: "A-",
  },
  {
    id: 3,
    value: "B+",
    text: "B+",
  },
  {
    id: 4,
    value: "B-",
    text: "B-",
  },
  {
    id: 5,
    value: "O+",
    text: "O+",
  },
  {
    id: 6,
    value: "O-",
    text: "O-",
  },
  {
    id: 7,
    value: "AB+",
    text: "AB+",
  },
  {
    id: 8,
    value: "AB-",
    text: "AB-",
  },
];

const BasicDetails: React.FC = () => {
  const [details, setDetails] = useState<IBasicDetails>(basicDetailsSchema);
  const [isNext, setIsNext] = useState<boolean>(false);

  // const setRecruiteeStepNo = useSetRecoilState(recruiteeAtom);
  // const handleStepChange = () => {
  //   setRecruiteeStepNo((prevActiveStep) => prevActiveStep + 1);
  // };

  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.down("md"));

  const [snackbarDetails, setSnackbarDetails] =
    useState<ISnackbarDetailsInterface>({
      open: false,
      message: "",
      severity: undefined,
    });

  // const [recru, setRecruiteeFormSubmitted] = useRecoilState(
  //   recruiteeFormSubmittedAtom
  // );

  // const [, setDocumentFormSubmitted] = useRecoilState(
  //   documentFormSubmittedAtom
  // );

  // const patchFormData = async (data: IBasicDetails) => {
  //   console.log("date type : ", data);
  //   try {
  //     const res = await saveBasicDetailsAPI(data);
  //     if (res.status === 200) {
  //       //snackbar open
  //       setSnackbarDetails({
  //         open: true,
  //         message: "successfully submitted!",
  //         severity: "success",
  //       });

  //       console.log("backend data....", res);
  //       setIsMarried(data.married);
  //       setIsNext(true);
  //     }
  //   } catch (error) {
  //     setSnackbarDetails({
  //       open: true,
  //       message: "Something went wrong",
  //       severity: "error",
  //     });
  //     console.log("s", error);
  //     //snackbar error
  //   }
  // };

  const [activeTab, setActiveTab] = useRecoilState(formDetailsAtom);
  const tabs = activeTab.recruiteeDetails.tabsArray.map((tab) => {
    if (tab.id === activeTab.recruiteeDetails.stepCount)
      return {
        ...tab,
        isCompleted: true,
      };
    return tab;
  });

  function updateFormDetails(
    headerTab: "recruiteeDetails" | "documentUploads",
    formName: string,
    status: boolean
  ) {
    setActiveTab((prevFormDetails) => {
      const tabsArray = prevFormDetails[headerTab].tabsArray.map((tab) => {
        if (tab.name === formName) {
          return { ...tab, isCompleted: status };
        }
        return tab;
      });

      let updatedFormDetails;
      if (headerTab === "recruiteeDetails") {
        const allFormsCompleted = tabsArray.every((tab) => tab.isCompleted);
        updatedFormDetails = {
          ...prevFormDetails,
          [headerTab]: {
            ...prevFormDetails[headerTab],
            tabsArray,
            tabCompleted: allFormsCompleted,
          },
        };
      } else {
        updatedFormDetails = {
          ...prevFormDetails,
          [headerTab]: {
            ...prevFormDetails[headerTab],
            tabsArray,
          },
        };
      }

      return updatedFormDetails;
    });
  }

  //PATCH request
  const { mutate, isLoading: isLoadingPATCH } = useMutation({
    mutationFn: saveBasicDetailsAPI,
    onSuccess: (data, variable) => {
      setSnackbarDetails({
        open: true,
        message: "Successfully submitted!",
        severity: "success",
      });

      // setRecruiteeFormSubmitted((prev) => ({
      //   ...prev,
      //   form: {
      //     ...prev.form,
      //     basicDetailsForm: true,
      //   },
      // }));

      // setActiveTab(activeTabNew);
      // setActiveTab(prev => ({
      //   ...prev, recruiteeDetails: {
      //     ...prev.recruiteeDetails,
      //     tabsArray: [...prev.recruiteeDetails.tabsArray,  ]
      //   }
      // }))

      setActiveTab((prev) => ({
        ...prev,
        recruiteeDetails: {
          ...prev.recruiteeDetails,
          tabsArray: tabs,
        },
      }));

      setIsNext(true);
    },
    onError: (error) => {
      // console.log("basicError", error);
      setSnackbarDetails({
        open: true,
        message: "Something went wrong",
        severity: "error",
      });
    },
  });

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleSubmit,
    handleChange,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues: details,
    validationSchema: basicDetailsVSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => {
      let newValues = { ...values };
      if (values.isPermanentAddressDifferent === false) {
        newValues.address.permanent = newValues.address.present;
      }

      if (values.married === "No") {
        updateSpouseAPI();
      }
      // patchFormData(newValues);
      mutate(newValues);
    },
  });

  //GET request
  const { isLoading: isLoadingGET, data: getBasicData } = useQuery({
    queryKey: ["getBasicDetails"],
    queryFn: getUserInfoAPI,
    onSuccess: (getData) => {
      setDetails(getData.data.data);
      setValues(setBasicDetailsDefaultValue(getData.data.data));

      // console.log("allDetails", getData.data.data);

      //basic-details
      const hasFilledBasicDetailsForm = basicDetailsMapper(getData.data.data);
      // console.log({ hasFilledBasicDetailsForm });
      if (hasFilledBasicDetailsForm) {
        // setRecruiteeFormSubmitted((prev) => ({
        //   ...prev,
        //   form: {
        //     ...prev.form,
        //     basicDetailsForm: true,
        //   },
        // }));

        // setActiveTab((prevformDetails) => ({
        //   ...prevformDetails,
        //   recruiteeDetails: {
        //     ...prevformDetails.recruiteeDetails,
        //     tabsArray: prevformDetails.recruiteeDetails.tabsArray.map((tab) => {
        //       if (tab.name === "basicDetailsForm") {
        //         return { ...tab, isCompleted: true };
        //       }
        //       return tab;
        //     }),
        //   },
        // }));

        updateFormDetails("recruiteeDetails", "basicDetailsForm", true);
      }

      //edu-qual
      const hasFilledEduQualForm = eduQualMapper(getData.data.data);
      if (hasFilledEduQualForm) {
        // setRecruiteeFormSubmitted((prev) => ({
        //   ...prev,
        //   form: {
        //     ...prev.form,
        //     eduQualForm: true,
        //   },
        // }));
        updateFormDetails("recruiteeDetails", "eduQualForm", true);
      }

      //fam-lang
      if (getData.data.data.family.father.name !== "") {
        // setRecruiteeFormSubmitted((prev) => ({
        //   ...prev,
        //   form: {
        //     ...prev.form,
        //     familyLangForm: true,
        //     workExpForm: true,
        //   },
        // }));

        updateFormDetails("recruiteeDetails", "workExpForm", true);
        updateFormDetails("recruiteeDetails", "familyLangForm", true);
      }

      //bank-details
      if (getData.data.data.bankAccDetails.IFSC !== "") {
        // setRecruiteeFormSubmitted((prev) => ({
        //   ...prev,
        //   form: {
        //     ...prev.form,
        //     bankDetailsForm: true,
        //   },
        // }));
        updateFormDetails("recruiteeDetails", "bankDetailsForm", true);
      }

      // //employee-info
      // if (
      //   getData.data.data.employeeInformation.department !== "" &&
      //   getData.data.data.employeeInformation.department !== undefined
      // ) {
      // setRecruiteeFormSubmitted((prev) => ({
      //   ...prev,
      //   form: {
      //     ...prev.form,
      //     empInfoForm: true,
      //   },
      // }));
      // }

      //photos
      if (getData.data.data.uploadedDocuments.photos.personalPhoto !== "") {
        // setDocumentFormSubmitted((prev) => ({
        //   ...prev,
        //   form: {
        //     ...prev.form,
        //     photosForm: true,
        //   },
        // }));
        updateFormDetails("documentUploads", "photosForm", true);
      }

      //tv docs
      // if (getData.data.data.uploadedDocuments.acceptedOfferLetter) {
      //   updateFormDetails("documentUploads", "tvDocsForm", true);
      // }

      //identity -proof
      if (
        getData.data.data.uploadedDocuments.proofOfIdentity.adhaarCard !== ""
      ) {
        // setDocumentFormSubmitted((prev) => ({
        //   ...prev,
        //   form: {
        //     ...prev.form,
        //     identityProofForm: true,
        //   },
        // }));
        updateFormDetails("documentUploads", "identityProofForm", true);
      }

      //acad-certificates
      if (
        getData.data.data.uploadedDocuments.academicCertificates.class10th !==
        ""
      ) {
        // setDocumentFormSubmitted((prev) => ({
        //   ...prev,
        //   form: {
        //     ...prev.form,
        //     acadCertificates: true,
        //   },
        // }));
        updateFormDetails("documentUploads", "acadCertificatesForm", true);
      }
    },
    onError: (error) => {
      setSnackbarDetails({
        open: true,
        message: "Something went wrong",
        severity: "error",
      });
    },
  });

  const handleCloseSnackbar = () => {
    setSnackbarDetails((prev) => ({
      ...prev,
      open: false,
    }));
  };

  function handleChangeDate(dateData: number | null) {
    // console.log("data", new Date(dateData as number).toISOString());
    if (dateData) {
      setFieldValue("birthDate", new Date(dateData as number).toISOString());
    }
  }

  useEffect(() => {
    const calculateAge = () => {
      const today = new Date();

      let age = 0;
      if (values.birthDate) {
        age = today.getFullYear() - new Date(values.birthDate).getFullYear();
      }

      if (age === 0) {
        setFieldValue("age", "0");
      } else {
        setFieldValue("age", age.toString());
      }
    };
    calculateAge();
  }, [values.birthDate]);

  return (
    <ContainerComponent>
      {isLoadingGET ? (
        <CircularLoader />
      ) : (
        <Grid container sx={{ width: "100%" }}>
          <SnackBar
            snackbarOpen={snackbarDetails.open}
            message={snackbarDetails.message}
            severity={snackbarDetails.severity}
            handleCloseSnackbar={handleCloseSnackbar}
          />
          <Grid container columnSpacing={4} rowSpacing={3}>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <InputLabel
                shrink
                htmlFor="bootstrap-input"
                sx={{ color: "white", fontSize: "0.75rem" }}
              >
                Full Name *
              </InputLabel>
              <StyledTextField
                autoComplete="off"
                hiddenLabel
                fullWidth
                name="name"
                value={values.name}
                onBlur={handleBlur}
                onChange={handleChange}
                // to={touched?.name}
              />
              <StyledSpanError>{errors.name}</StyledSpanError>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <InputLabel
                shrink
                htmlFor="bootstrap-input"
                sx={{ color: "white", fontSize: "0.75rem" }}
              >
                Email ID *
              </InputLabel>
              <StyledTextField
                autoComplete="off"
                hiddenLabel
                fullWidth
                name="emailID"
                value={values.emailID}
                onBlur={handleBlur}
                onChange={handleChange}
                // to={touched?.name}
              />
              <StyledSpanError>{errors.emailID}</StyledSpanError>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <InputLabel
                shrink
                htmlFor="bootstrap-input"
                sx={{ color: "white", fontSize: "0.75rem" }}
              >
                Phone Number *
              </InputLabel>
              <StyledTextField
                autoComplete="off"
                inputProps={{
                  maxLength: 10,
                }}
                hiddenLabel
                fullWidth
                name="mobileNo"
                value={values.mobileNo}
                onBlur={handleBlur}
                onChange={handleChange}
                // to={touched?.name}
              />
              <StyledSpanError>{errors.mobileNo}</StyledSpanError>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <InputLabel
                shrink
                htmlFor="bootstrap-input"
                sx={{ color: "white", fontSize: "0.75rem" }}
              >
                Gender *
              </InputLabel>
              <DropdownComponent
                name="gender"
                value={values.gender}
                handleChange={handleChange}
                items={GenderItems}
              />
              <StyledSpanError>{errors.gender}</StyledSpanError>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <InputLabel
                shrink
                htmlFor="bootstrap-input"
                sx={{ color: "white", fontSize: "0.75rem" }}
              >
                Date of Birth *
              </InputLabel>
              <DesktopDatePicker
                disableFuture
                inputFormat="DD/MM/YYYY"
                value={values.birthDate}
                onChange={handleChangeDate}
                renderInput={(params) => (
                  <StyledTextField
                    autoComplete="off"
                    {...params}
                    onKeyDown={(e) => {
                      e.preventDefault();
                    }}
                  />
                )}
              />
              {/* <StyledTextField
              hiddenLabel
              fullWidth
              type="date"
              name="birthDate"
              value={values.birthDate}
              onChange={handleChange}
            /> */}
              <StyledSpanError>{errors.birthDate}</StyledSpanError>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <InputLabel
                shrink
                htmlFor="bootstrap-input"
                sx={{ color: "white", fontSize: "0.75rem" }}
              >
                Age
              </InputLabel>
              <StyledTextField
                autoComplete="off"
                disabled={true}
                // isDisabled={true}
                hiddenLabel
                fullWidth
                name="age"
                value={values.age ?? 0}
                onBlur={handleBlur}
                onChange={handleChange}
                // to={touched?.name}
              />
              <StyledSpanError>{errors.age}</StyledSpanError>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <InputLabel
                shrink
                htmlFor="bootstrap-input"
                sx={{ color: "white", fontSize: "0.75rem" }}
              >
                Marital Status *
              </InputLabel>
              <DropdownComponent
                name="married"
                // value={isMarried}
                // handleChange={marriedStattus}
                value={values?.married}
                handleChange={handleChange}
                items={MaritalStatusItems}
              />
              <StyledSpanError>{errors.married}</StyledSpanError>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <InputLabel
                shrink
                htmlFor="bootstrap-input"
                sx={{ color: "white", fontSize: "0.75rem" }}
              >
                Blood Group *
              </InputLabel>
              <DropdownComponent
                name="bloodGroup"
                value={values?.bloodGroup}
                handleChange={handleChange}
                items={BloodGroupItems}
              />
              {/* <StyledTextField
                hiddenLabel
                fullWidth
                name="bloodGroup"
                value={values.bloodGroup}
                onBlur={handleBlur}
                onChange={handleChange}
                // to={touched?.name}
              /> */}
              <StyledSpanError>{errors.bloodGroup}</StyledSpanError>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <InputLabel
                shrink
                htmlFor="bootstrap-input"
                sx={{ color: "white", fontSize: "0.75rem" }}
              >
                Voter ID
              </InputLabel>
              <StyledTextField
                autoComplete="off"
                hiddenLabel
                fullWidth
                name="idProofs.voterIdNo"
                value={values.idProofs.voterIdNo}
                onBlur={handleBlur}
                onChange={(e) =>
                  setFieldValue(
                    "idProofs.voterIdNo",
                    e.target.value.toUpperCase()
                  )
                }
              />
              <StyledSpanError>{errors.idProofs?.voterIdNo}</StyledSpanError>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <InputLabel
                shrink
                htmlFor="bootstrap-input"
                sx={{ color: "white", fontSize: "0.75rem" }}
              >
                Aadhar Number *
              </InputLabel>
              <StyledTextField
                autoComplete="off"
                inputProps={{
                  maxLength: 12,
                }}
                hiddenLabel
                fullWidth
                name="idProofs.aadharNo"
                value={values.idProofs.aadharNo}
                onBlur={handleBlur}
                onChange={handleChange}
                // to={touched?.name}
              />
              <StyledSpanError>{errors.idProofs?.aadharNo}</StyledSpanError>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <InputLabel
                shrink
                htmlFor="bootstrap-input"
                sx={{
                  color: "white",
                  fontSize: "0.75rem",
                }}
              >
                PAN No. *
              </InputLabel>
              <StyledTextField
                autoComplete="off"
                hiddenLabel
                fullWidth
                name="idProofs.panNo"
                value={values.idProofs.panNo}
                onBlur={handleBlur}
                onChange={(e) =>
                  setFieldValue("idProofs.panNo", e.target.value.toUpperCase())
                }
                // error={errors.idProofs?.panNo ? true : false}
                // to={touched?.name}
              />
              <StyledSpanError>{errors.idProofs?.panNo}</StyledSpanError>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <InputLabel
                shrink
                htmlFor="bootstrap-input"
                sx={{ color: "white", fontSize: "0.75rem" }}
              >
                Passport No.
              </InputLabel>
              <StyledTextField
                autoComplete="off"
                hiddenLabel
                fullWidth
                name="idProofs.passportNo"
                value={values.idProofs.passportNo}
                onBlur={handleBlur}
                onChange={(e) =>
                  setFieldValue(
                    "idProofs.passportNo",
                    e.target.value.toUpperCase()
                  )
                }
              />
              <StyledSpanError>{errors.idProofs?.passportNo}</StyledSpanError>
            </Grid>

            {/* <Box sx={{border: "1px solid red"}}></Box> */}

            <Grid item xs={12} sm={12} md={4} lg={4}>
              <InputLabel
                shrink
                htmlFor="bootstrap-input"
                sx={{ color: "white", fontSize: "0.75rem" }}
              >
                Present Address *
              </InputLabel>
              <StyledTextField
                autoComplete="off"
                // sx={{
                //   "& .MuiInputBase-input": {
                //     textTransform: "capitalize",
                //   },
                // }}
                hiddenLabel
                fullWidth
                name="address.present.addressField"
                value={values.address.present.addressField}
                onBlur={handleBlur}
                onChange={handleChange}
                // to={touched?.name}
              />
              <StyledSpanError>
                {errors.address?.present?.addressField}
              </StyledSpanError>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <InputLabel
                shrink
                htmlFor="bootstrap-input"
                sx={{ color: "white", fontSize: "0.75rem" }}
              >
                City *
              </InputLabel>
              <StyledTextField
                autoComplete="off"
                // sx={{
                //   "& .MuiInputBase-input": {
                //     textTransform: "capitalize",
                //   },
                // }}
                hiddenLabel
                fullWidth
                name="address.present.city"
                value={values.address.present.city}
                onBlur={handleBlur}
                onChange={handleChange}
                // to={touched?.name}
              />
              <StyledSpanError>{errors.address?.present?.city}</StyledSpanError>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <InputLabel
                shrink
                htmlFor="bootstrap-input"
                sx={{ color: "white", fontSize: "0.75rem" }}
              >
                State *
              </InputLabel>
              <StyledTextField
                autoComplete="off"
                // sx={{
                //   "& .MuiInputBase-input": {
                //     textTransform: "capitalize",
                //   },
                // }}
                hiddenLabel
                fullWidth
                name="address.present.state"
                value={values.address.present.state}
                onBlur={handleBlur}
                onChange={handleChange}
                // to={touched?.name}
              />
              <StyledSpanError>
                {errors.address?.present?.state}
              </StyledSpanError>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <InputLabel
                shrink
                htmlFor="bootstrap-input"
                sx={{ color: "white", fontSize: "0.75rem" }}
              >
                PINCODE *
              </InputLabel>
              <StyledTextField
                autoComplete="off"
                inputProps={{
                  maxLength: 6,
                }}
                hiddenLabel
                fullWidth
                name="address.present.pin"
                value={values.address.present.pin}
                onBlur={handleBlur}
                onChange={handleChange}
                // to={touched?.name}
              />
              <StyledSpanError>{errors.address?.present?.pin}</StyledSpanError>
            </Grid>

            {/*  */}

            <Grid item xs={12} sm={12} md={12} lg={12}>
              <CheckboxComponent
                name="isPermanentAddressDifferent"
                label="Is permanent address different ?"
                isChecked={values.isPermanentAddressDifferent}
                checkboxChange={handleChange}
              />
            </Grid>

            {values.isPermanentAddressDifferent ? (
              <>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <InputLabel
                    shrink
                    htmlFor="bootstrap-input"
                    sx={{ color: "white", fontSize: "0.75rem" }}
                  >
                    Permanent Address
                  </InputLabel>
                  <StyledTextField
                    autoComplete="off"
                    // sx={{
                    //   "& .MuiInputBase-input": {
                    //     textTransform: "capitalize",
                    //   },
                    // }}
                    hiddenLabel
                    fullWidth
                    name="address.permanent.addressField"
                    value={values.address.permanent.addressField}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    // to={touched?.name}
                  />
                  <StyledSpanError>
                    {errors.address?.permanent?.addressField}
                  </StyledSpanError>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <InputLabel
                    shrink
                    htmlFor="bootstrap-input"
                    sx={{ color: "white", fontSize: "0.75rem" }}
                  >
                    City
                  </InputLabel>
                  <StyledTextField
                    autoComplete="off"
                    // sx={{
                    //   "& .MuiInputBase-input": {
                    //     textTransform: "capitalize",
                    //   },
                    // }}
                    hiddenLabel
                    fullWidth
                    name="address.permanent.city"
                    value={values.address.permanent.city}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    // to={touched?.name}
                  />
                  <StyledSpanError>
                    {errors.address?.permanent?.city}
                  </StyledSpanError>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <InputLabel
                    shrink
                    htmlFor="bootstrap-input"
                    sx={{ color: "white", fontSize: "0.75rem" }}
                  >
                    State
                  </InputLabel>
                  <StyledTextField
                    autoComplete="off"
                    // sx={{
                    //   "& .MuiInputBase-input": {
                    //     textTransform: "capitalize",
                    //   },
                    // }}
                    hiddenLabel
                    fullWidth
                    name="address.permanent.state"
                    value={values.address.permanent.state}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    // to={touched?.name}
                  />
                  <StyledSpanError>
                    {errors.address?.permanent?.state}
                  </StyledSpanError>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <InputLabel
                    shrink
                    htmlFor="bootstrap-input"
                    sx={{ color: "white", fontSize: "0.75rem" }}
                  >
                    PINCODE
                  </InputLabel>
                  <StyledTextField
                    autoComplete="off"
                    hiddenLabel
                    fullWidth
                    name="address.permanent.pin"
                    value={values.address.permanent.pin}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    // to={touched?.name}
                  />
                  <StyledSpanError>
                    {errors.address?.permanent?.pin}
                  </StyledSpanError>
                </Grid>
              </>
            ) : (
              <></>
            )}

            {isMedium ? (
              <></>
            ) : values.isPermanentAddressDifferent ? (
              <Grid item xs={12} sm={12} md={8} lg={8}></Grid>
            ) : (
              <></>
            )}

            <Grid item xs={12} sm={12} md={4} lg={4}>
              <InputLabel
                shrink
                htmlFor="bootstrap-input"
                sx={{ color: "white", fontSize: "0.75rem" }}
              >
                Emergency Contact No. *
              </InputLabel>
              <StyledTextField
                autoComplete="off"
                // sx={{
                //   "& .MuiInputBase-input": {
                //     textTransform: "capitalize",
                //   },
                // }}
                inputProps={{
                  maxLength: 10,
                }}
                hiddenLabel
                fullWidth
                name="emergencyContact.contactNo"
                value={values.emergencyContact.contactNo}
                onBlur={handleBlur}
                onChange={handleChange}
                // to={touched?.name}
              />
              <StyledSpanError>
                {errors.emergencyContact?.contactNo}
              </StyledSpanError>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <InputLabel
                shrink
                htmlFor="bootstrap-input"
                sx={{ color: "white", fontSize: "0.75rem" }}
              >
                Emergency Contact Name *
              </InputLabel>
              <StyledTextField
                autoComplete="off"
                // sx={{
                //   "& .MuiInputBase-input": {
                //     textTransform: "capitalize",
                //   },
                // }}
                hiddenLabel
                fullWidth
                name="emergencyContact.name"
                value={values.emergencyContact.name}
                onBlur={handleBlur}
                onChange={handleChange}
                // to={touched?.name}
              />
              <StyledSpanError>{errors.emergencyContact?.name}</StyledSpanError>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <InputLabel
                shrink
                htmlFor="bootstrap-input"
                sx={{ color: "white", fontSize: "0.75rem" }}
              >
                Emergency Contact Relationship *
              </InputLabel>
              <StyledTextField
                autoComplete="off"
                // sx={{
                //   "& .MuiInputBase-input": {
                //     textTransform: "capitalize",
                //   },
                // }}
                hiddenLabel
                fullWidth
                name="emergencyContact.relationship"
                value={values.emergencyContact.relationship}
                onBlur={handleBlur}
                onChange={handleChange}
                // to={touched?.name}
              />
              <StyledSpanError>
                {errors.emergencyContact?.relationship}
              </StyledSpanError>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="flex-end"
            justifyContent="flex-end"
            // sm={12}
          >
            <ButtonComponent
              handleSubmit={handleSubmit}
              buttonText={isNext ? "Next" : "Save"}
              width="100px"
              isLoading={isNext ? false : isLoadingPATCH}
            />
          </Grid>
          {/* </form> */}
        </Grid>
      )}
    </ContainerComponent>
  );
};

export default BasicDetails;

{
  /* 
  <DatePickerComponent
  name="birthDate"
  handleChange={handleChange}
  value={values.birthDate}
  /> */
}
