import { useMutation, useQuery } from "@tanstack/react-query";
import { Box, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { useFormik } from "formik";
import CheckIcon from "./CheckIcon";
import { prevButton } from "../styleParent";
import EducationForm from "./EducationForm";
import SnackBar from "../../../../components/SnackBar";
import CircularLoader from "../../../../components/CircularLoader";
import { ISnackbarDetailsInterface } from "../../../../interfaces";
import ButtonComponent from "../../../../components/ButtonComponent";
import { setEducationDefaultValue } from "../../utils/setDefaultValues";
import { checkboxContainer, checkboxText, formContainer } from "./styles";
import {
  checkState,
  educationQualificationAtom,
  formDetailsAtom,
  recruiteeFormSubmittedAtom,
} from "../../../../recoil";
import { getUserInfoAPI, saveEducationAPI } from "../../../../api/recruitee";
import ContainerComponent from "../../../../components/Container/ContainerComponent";
import { educationValidationSchema } from "../../../../yup-schemas/educationValidationSchema";
import { checkBoxContent, labels } from "./educationConstants";

const EducationalQualification = () => {
  const [EducationalQualificationData, setEducationalQualificationData] =
    useRecoilState(educationQualificationAtom);

  const [isNext, setIsNext] = useState<boolean>(false);
  const [forcedRender, setForcedRender] = useState<boolean>(false);

  const [snackBarState, setSnackBarState] = useState<ISnackbarDetailsInterface>(
    {
      message: "",
      open: false,
      severity: undefined,
    }
  );

  const [checked, setChecked] = useRecoilState<any>(checkState);
  // const [, setRecruiteeFormSubmitted] = useRecoilState(
  //   recruiteeFormSubmittedAtom
  // );

  const [activeTab, setActiveTab] = useRecoilState(formDetailsAtom);
  const tabs = activeTab.recruiteeDetails.tabsArray.map((tab) => {
    if (tab.id === activeTab.recruiteeDetails.stepCount)
      return { ...tab, isCompleted: true };
    return tab;
  });

  const { setValues, setFieldValue, handleSubmit, ...formik } = useFormik({
    initialValues: EducationalQualificationData,
    enableReinitialize: true,
    validationSchema: educationValidationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => {
      // console.log(values);
      let payload;
      payload = {
        class10: {
          institute: values?.class10?.institute,
          yearOfPassing: values?.class10?.yearOfPassing,
          percentage: values?.class10?.percentage,
          subjects: values?.class10?.subjects.split(","),
        },
        class12: {
          institute: values?.class12?.institute,
          yearOfPassing: values?.class12?.yearOfPassing,
          percentage: values?.class12?.percentage,
          subjects: values?.class12?.subjects.split(","),
        },
        diploma: {
          institute: values?.isDiploma ? values?.diploma?.institute : "",
          yearOfPassing: values?.isDiploma
            ? values?.diploma?.yearOfPassing
            : "",
          percentage: values?.isDiploma ? values?.diploma?.percentage : "",
          subjects: values?.isDiploma
            ? values?.diploma?.subjects.split(",")
            : "",
        },
        graduation: {
          institute: values?.isGraduate ? values?.graduation?.institute : "",
          yearOfPassing: values?.isGraduate
            ? values?.graduation?.yearOfPassing
            : "",
          percentage: values?.isGraduate ? values?.graduation?.percentage : "",
          subjects: values?.isGraduate
            ? values?.graduation?.subjects.split(",")
            : "",
        },
        postGraduation: {
          institute:
            values?.isPostGraduate && values.isGraduate
              ? values?.postGraduation?.institute
              : "",
          yearOfPassing:
            values?.isPostGraduate && values.isGraduate
              ? values?.postGraduation?.yearOfPassing
              : "",
          percentage:
            values?.isPostGraduate && values.isGraduate
              ? values?.postGraduation?.percentage
              : "",
          subjects:
            values?.isPostGraduate && values.isGraduate
              ? values?.postGraduation?.subjects.split(",")
              : "",
        },
      };

      saveEducation(payload);
    },
  });

  // functions
  function toggleForcedRender() {
    setForcedRender(true);
  }

  const saveEducation = async (payload: any) => {
    // console.log({ eduPayload: payload });
    mutate(payload);
  };

  const { mutate, isLoading } = useMutation({
    mutationFn: saveEducationAPI,
    onSuccess: (data, variable) => {
      setSnackBarState({
        open: true,
        message: "successfully submitted!",
        severity: "success",
      });

      // setRecruiteeFormSubmitted((prev) => ({
      //   ...prev,
      //   form: {
      //     ...prev.form,
      //     eduQualForm: true,
      //   },
      // }));

      setActiveTab((prev) => ({
        ...prev,
        recruiteeDetails: {
          ...prev.recruiteeDetails,
          tabsArray: tabs,
        },
      }));
      setIsNext(true);
    },
    onError: (error) => {
      setSnackBarState({
        open: true,
        message: "Something went wrong",
        severity: "error",
      });
    },
  });

  const handleCloseSnackbar = () => {
    setSnackBarState((prev) => ({
      ...prev,
      open: false,
    }));
  };

  const handleCheckBox = (content: {
    name: string;
    text: string;
    isDisable: boolean;
  }) => {
    if (content.name === "diploma") {
      setChecked((prev: any) => ({
        ...prev,
        diploma: !checked.diploma,
      }));
    }

    if (content.name === "graduation") {
      setChecked((prev: any) => ({
        ...prev,
        graduation: !checked.graduation,
      }));
    }

    if (content.name === "postGraduation") {
      if (checked.graduation && formik.values?.graduation) {
        setChecked((prev: any) => ({
          ...prev,
          postGraduation: !checked.postGraduation,
        }));
      } else {
        setChecked((prev: any) => ({
          ...prev,
          postGraduation: false,
        }));
      }
    }
  };

  useEffect(() => {
    setFieldValue("isDiploma", checked?.diploma);
    setFieldValue("isGraduate", checked?.graduation);
    setFieldValue("isPostGraduate", checked?.postGraduation);
  }, [checked]);

  const {
    isLoading: isLoadingFetch,
    isSuccess,
    refetch,
  } = useQuery({
    queryKey: ["getEducation"],
    queryFn: getUserInfoAPI,
    onSuccess: (data) => {
      if (data?.data?.data?.education !== undefined) {
        setEducationalQualificationData(data?.data?.data?.education);
        setValues(setEducationDefaultValue(data?.data?.data?.education));
        toggleForcedRender();
      }
    },
    onError: (error) => {
      setSnackBarState({
        open: true,
        message: "Something went wrong",
        severity: "error",
      });
    },
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    setChecks();
  }, [formik.values]);

  const setChecks = () => {
    setChecked((prev: any) => ({
      ...prev,
      diploma: formik.values?.isDiploma,
      graduation: formik.values?.isGraduate,
      postGraduation: formik.values?.isPostGraduate,
    }));
  };

  useEffect(() => {
    if (!forcedRender) return;
    refetch();
  }, [forcedRender]);

  return (
    <ContainerComponent>
      {isLoadingFetch ? (
        <CircularLoader />
      ) : (
        <Grid container direction="row">
          {/* check boxes */}
          <Box sx={checkboxContainer}>
            {checkBoxContent.map((content, index) => (
              <Box
                sx={{
                  margin: "14px",
                  paddingLeft: "0.5em",
                  paddingRight: "0.5em",
                  borderRadius: "10px",
                  textAlign: "center",
                  cursor: "pointer",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  border: content.isDisable
                    ? "1px solid #2FB27D"
                    : checked[content.name]
                    ? "1px solid #2FB27D"
                    : "1px solid #381A84",
                  background: content.isDisable
                    ? "#2FB27D"
                    : checked[content.name]
                    ? "#2FB27D"
                    : "transparent",
                }}
                onClick={() => handleCheckBox(content)}
                key={index}
              >
                <CheckIcon isChecked={checked[content.name]} />
                <Typography sx={checkboxText}>{content.text}</Typography>
              </Box>
            ))}
          </Box>

          {/* forms */}
          <div>
            <EducationForm
              setFieldValue={setFieldValue}
              gridLabel="Class 10"
              label={labels}
              name={"class10"}
              formik={formik}
              errors={formik?.errors?.class10}
              values={formik?.values?.class10}
            />
            <EducationForm
              setFieldValue={setFieldValue}
              gridLabel="Class 12"
              label={labels}
              name={"class12"}
              formik={formik}
              errors={formik.errors?.class12}
              values={formik.values?.class12}
            />

            {formik.values?.isDiploma && checked?.diploma && (
              <EducationForm
                setFieldValue={setFieldValue}
                gridLabel="Diploma"
                label={labels}
                name={"diploma"}
                formik={formik}
                errors={formik.errors?.diploma}
                values={formik.values?.diploma}
              />
            )}

            {formik.values?.isGraduate && checked?.graduation && (
              <EducationForm
                setFieldValue={setFieldValue}
                gridLabel="Graduate"
                label={labels}
                name={"graduation"}
                formik={formik}
                errors={formik.errors?.graduation}
                values={formik.values?.graduation}
              />
            )}

            {formik.values?.isGraduate &&
              checked?.graduation &&
              formik.values?.isPostGraduate &&
              checked?.postGraduation && (
                <EducationForm
                  setFieldValue={setFieldValue}
                  gridLabel="Post Graduate"
                  label={labels}
                  name={"postGraduation"}
                  formik={formik}
                  errors={formik.errors?.postGraduation}
                  values={formik.values?.postGraduation}
                />
              )}
          </div>

          <Grid container justifyContent="flex-end" sm={12} sx={formContainer}>
            <div style={prevButton}>
              <ButtonComponent
                handleSubmit={handleSubmit}
                buttonText={"Previous"}
                width="100px"
              />
            </div>
            <ButtonComponent
              handleSubmit={handleSubmit}
              buttonText={isNext ? "Next" : "Save"}
              width="100px"
              isLoading={isNext ? false : isLoading}
            />
          </Grid>
          <SnackBar
            snackbarOpen={snackBarState.open}
            handleCloseSnackbar={handleCloseSnackbar}
            message={snackBarState.message}
            severity={snackBarState.severity}
          />
        </Grid>
      )}
    </ContainerComponent>
  );
};

export default EducationalQualification;
