import axios from "axios";
import { BaseURL } from "../constants/baseurl";
import { axiosInstance } from './axios.global'

export const requestOtp = (email: string) => {
  const response = axios.post(`${BaseURL}/on-boarding/request-otp`, {
    emailID: email,
  });
  return response;
};

export const verifyOtp = (payload: { emailID: string; otp: string }) => {
  const response = axios.post(`${BaseURL}/on-boarding/verify-otp`, payload);
  return response;
};


export const setUserDone = async (status: boolean) => {
  return await axiosInstance.put(`${BaseURL}/on-boarding/set-user-done?isDone=${status}`)
}
