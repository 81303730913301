import ButtonComponent from "../../components/ButtonComponent";
import { useEffect, useState } from "react";
import DotHover from "../../components/DotHover";
import SnackBar from "../../components/SnackBar";
import {
  buttonContainer,
  descriptionTypo,
  resendTypography,
  textField,
  textFieldContainer,
  textFieldInputAdornment,
} from "./styles";
import {
  AlertColor,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import * as yup from "yup";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { requestOtp, verifyOtp } from "../../api/login";
import { ReactComponent as OtpLogo } from "../../assets/images/otpLogo.svg";
import { StyledLoginDiv } from "../../components/StyledComponents/StyledLoginDiv";
import { ReactComponent as TechVariableLogo } from "../../assets/images/techVariableLogo.svg";
import { ISnackbarDetailsInterface } from "../../interfaces";
import { useMutation } from "@tanstack/react-query";

const LoginOtp = () => {
  const navigate = useNavigate();

  const [snackBarState, setSnackBarState] = useState<ISnackbarDetailsInterface>(
    {
      message: "",
      open: false,
      severity: undefined,
    }
  );

  const sessionEmail = window.sessionStorage.getItem("email_id") as string;

  const handleCloseSnackbar = () => {
    setSnackBarState((prev) => ({
      ...prev,
      open: false,
    }));
  };

  const { handleSubmit, ...formik } = useFormik({
    initialValues: { emailID: sessionEmail, otp: "" },
    validationSchema: yup.object().shape({
      otp: yup.string().min(6).max(6).required("OTP is required!"),
    }),
    validateOnChange: false,
    onSubmit: (values) => {
      handleSubmitOtp({
        emailID: values.emailID,
        otp: values.otp,
      });
    },
  });

  const handleSubmitOtp = async (payload: { emailID: any; otp: string }) => {
    mutate(payload);
  };

  const { mutate, isLoading } = useMutation({
    mutationFn: verifyOtp,
    onSuccess: (data) => {
      setSnackBarState({
        open: true,
        message: "Login Successful!",
        severity: "success",
      });
      window.sessionStorage.setItem("access_token", data.data.access_token);
      setTimeout(() => {
        navigate("/onboarding");
      }, 1000);
    },
    onError: (error: any) => {
      setSnackBarState({
        open: true,
        message: error.response.data.message,
        severity: "error",
      });
    },
  });

  const { mutate: mutateResent } = useMutation({
    mutationFn: requestOtp,
    onSuccess: () => {
      setSnackBarState({
        open: true,
        message: `OTP sent to your email ID!`,
        severity: "success",
      });
    },
    onError: (error: unknown) => {
      setSnackBarState({
        open: true,
        message: `err.response.data.message`,
        severity: "error",
      });
    },
  });

  const resendOtp = async () => {
    if (sessionEmail === null) {
      setSnackBarState({
        open: true,
        message: `Couldn't retrieve Email ID!`,
        severity: "error",
      });
    } else {
      mutateResent(sessionEmail);
    }
  };

  useEffect(() => {
    if (formik.errors.otp) {
      setSnackBarState({
        open: true,
        message: formik.errors.otp.toString(),
        severity: "warning",
      });
    }
  }, [formik.errors]);

  return (
    <div>
      <DotHover>
        <StyledLoginDiv>
          <TechVariableLogo
            style={{
              justifyContent: "center",
              width: "144px",
              height: "41px",
              paddingTop: "60px",
            }}
          />
          <Typography sx={descriptionTypo}>
            Enter OTP sent to your email address
          </Typography>

          <Grid container justifyContent="center" sx={textFieldContainer}>
            <TextField
              name={"otp"}
              value={formik.values.otp}
              onChange={(event) => formik.handleChange(event)}
              autoFocus
              size="small"
              variant="standard"
              sx={textField}
              InputProps={{
                disableUnderline: true,
                placeholder: "OTP",
                startAdornment: (
                  <InputAdornment position="start" sx={textFieldInputAdornment}>
                    <OtpLogo />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid sx={buttonContainer}>
            <ButtonComponent
              handleSubmit={handleSubmit}
              buttonText={"LOGIN"}
              isLoading={isLoading}
            />
          </Grid>
          <Typography sx={resendTypography} onClick={resendOtp}>
            <u>Resend OTP</u>
          </Typography>
        </StyledLoginDiv>
        <SnackBar
          snackbarOpen={snackBarState.open}
          handleCloseSnackbar={handleCloseSnackbar}
          message={snackBarState.message}
          severity={snackBarState.severity}
        />
      </DotHover>
    </div>
  );
};

export default LoginOtp;
