import { Grid } from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import {
  getUserInfoAPI,
  saveFamilyLanguageAPI,
} from "../../../../api/recruitee";
import ButtonComponent from "../../../../components/ButtonComponent";
import CircularLoader from "../../../../components/CircularLoader";
import ContainerComponent from "../../../../components/Container/ContainerComponent";
import SnackBar from "../../../../components/SnackBar";
import TitleBox from "../../../../components/TitleBox/TitleBox";
import { ISnackbarDetailsInterface } from "../../../../interfaces";
import { IFamilyLanguage } from "../../../../interfaces/forms/familyLanguage.interface";
import {
  formDetailsAtom,
  recruiteeFormSubmittedAtom,
} from "../../../../recoil";
import { familyLangaugeSchema } from "../../../../schemas/familyLanguageSchema";
import {
  familyLanguageSpouseVSchema,
  familyLanguageVSchema,
} from "../../../../yup-schemas/familyLanguageVSchema";
import { setFamilyLanguageDefaultValue } from "../../utils/setDefaultValues";
import FamilyDetailComponent from "./components/familyComponent";
import LanguageComponent from "./components/languageComponent";

const DependentItems = [
  {
    id: 1,
    value: true, //convert to boolean
    text: "Yes",
  },
  {
    id: 2,
    value: false,
    text: "No",
  },
];

const FamilyLanguage = () => {
  const [famLangDetails, setFamLangDetails] =
    useState<IFamilyLanguage>(familyLangaugeSchema);
  const [isNext, setIsNext] = useState<boolean>(false);

  const [isMarried, setIsMarried] = useState("");

  const [snackbarDetails, setSnackbarDetails] =
    useState<ISnackbarDetailsInterface>({
      open: false,
      message: "",
      severity: undefined,
    });

  // const [, setRecruiteeFormSubmitted] = useRecoilState(
  //   recruiteeFormSubmittedAtom
  // );

  const [activeTab, setActiveTab] = useRecoilState(formDetailsAtom);
  const tabs = activeTab.recruiteeDetails.tabsArray.map((tab) => {
    if (tab.id === activeTab.recruiteeDetails.stepCount)
      return {
        ...tab,
        isCompleted: true,
      };
    return tab;
  });

  // const patchFormData = async (data: IFamilyLanguage) => {
  //   console.log("nilo", data);
  //   try {
  //     const res = await saveFamilyLanguageAPI(data);
  //     console.log("famLangres", res);
  //     if (res.status === 200) {
  //       //snackbar open
  //       setSnackbarDetails({
  //         open: true,
  //         message: "Successfully submitted!",
  //         severity: "success",
  //       });
  //       setIsNext(true);
  //     }
  //   } catch (error) {
  //     console.log("famlang error", error);
  //     setSnackbarDetails({
  //       open: true,
  //       message: "Something went wrong",
  //       severity: "error",
  //     });
  //   }
  // };

  //PATCH request
  const { mutate, isLoading: isLoadingPATCH } = useMutation({
    mutationFn: saveFamilyLanguageAPI,
    onSuccess: (data, variable) => {
      //snackbar open
      setSnackbarDetails({
        open: true,
        message: "Successfully submitted!",
        severity: "success",
      });
      // setRecruiteeFormSubmitted((prev) => ({
      //   ...prev,
      //   form: {
      //     ...prev.form,
      //     familyLangForm: true,
      //   },
      // }));

      setActiveTab((prev) => ({
        ...prev,
        recruiteeDetails: {
          ...prev.recruiteeDetails,
          tabsArray: tabs,
        },
      }));
      setIsNext(true);
    },
    onError: () => {
      setSnackbarDetails({
        open: true,
        message: "Something went wrong",
        severity: "error",
      });
    },
  });

  const familySchema = (isMarried: string) => {
    if (isMarried === "Yes") {
      return familyLanguageSpouseVSchema;
    }
    return familyLanguageVSchema;
  };

  const {
    values,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues: famLangDetails,
    validationSchema: familySchema(isMarried),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => {
      // patchFormData(values);
      mutate(values);
    },
  });

  // console.log("famlang values: ", values);

  //GET request
  const { isLoading: isLoadingGET } = useQuery({
    queryKey: ["getFamilyLanguage"],
    queryFn: getUserInfoAPI,
    onSuccess: (getData) => {
      // console.log({ getfamilydata: getData.data.data });
      setIsMarried(getData?.data?.data?.married);

      setFamLangDetails(getData?.data?.data);
      setValues(setFamilyLanguageDefaultValue(getData.data.data));
    },
    onError: (error) => {
      setSnackbarDetails({
        open: true,
        message: "Something went wrong",
        severity: "error",
      });
    },
  });

  // useEffect(() => {
  //   console.log("Yo", { values });
  // }, [values]);

  // useEffect(() => {
  //   const getFormData = async () => {
  //     try {
  //       // const res = await axiosInstance.get(`${MODULE_NAME}/get-user-info`);
  //       const res = await getUserInfoAPI();
  //       console.log("object", res.data.data);
  //       setFamLangDetails(res.data.data);
  //       setValues(setFamilyLanguageDefaultValue(res.data.data));
  //     } catch (error) {
  //       //snackbar error
  //     }
  //   };
  //   getFormData();
  // }, []);

  const handleCloseSnackbar = () => {
    setSnackbarDetails((prev) => ({
      ...prev,
      open: false,
    }));
  };

  return (
    <ContainerComponent>
      {isLoadingGET ? (
        <CircularLoader />
      ) : (
        <>
          <Grid container sx={{ width: "100%" }}>
            <SnackBar
              snackbarOpen={snackbarDetails.open}
              message={snackbarDetails.message}
              severity={snackbarDetails.severity}
              handleCloseSnackbar={handleCloseSnackbar}
            />
            {/* family */}
            <Grid item xs={12}>
              <TitleBox label="Family">
                <Grid container columnSpacing={4} rowSpacing={4}>
                  {/* <Grid item xs={12} sm={12} md={6} lg={6}>
            <InputLabel
              shrink
              htmlFor="bootstrap-input"
              sx={{ color: "white" }}
              >
              Father's Name
              </InputLabel>
              <StyledTextField
              hiddenLabel
              fullWidth
              name="family.father.name"
              value={values.family.father.name}
              onBlur={handleBlur}
              onChange={handleChange}
              />
              <StyledSpanError>{errors.family?.father?.name}</StyledSpanError>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
            <InputLabel
            shrink
            htmlFor="bootstrap-input"
            sx={{ color: "white" }}
            >
              Dependent
              </InputLabel>
              <DropdownComponent
              name="family.father.dependent"
              value={values.family.father.dependent}
              handleChange={handleChange}
              items={DependentItems}
              />
              <StyledSpanError>
              {errors.family?.father?.dependent}
              </StyledSpanError>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
            <InputLabel
              shrink
              htmlFor="bootstrap-input"
              sx={{ color: "white" }}
              >
              Occupation
              </InputLabel>
              <StyledTextField
              hiddenLabel
              fullWidth
              name="family.father.occupation"
              value={values.family.father.occupation}
              onBlur={handleBlur}
              onChange={handleChange}
              />
              <StyledSpanError>
              {errors.family?.father?.occupation}
              </StyledSpanError>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
              <InputLabel
              shrink
              htmlFor="bootstrap-input"
              sx={{ color: "white" }}
              >
              Occupation
              </InputLabel>
              <StyledTextField
              type="date"
              hiddenLabel
              fullWidth
              name="family.father.dob"
              value={values.family.father.dob}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            <StyledSpanError>{errors.family?.father?.dob}</StyledSpanError>
          </Grid> */}

                  <FamilyDetailComponent
                    parent="father"
                    parentLabel="Father's Name"
                    parentValues={values.family?.father}
                    parentErrors={errors.family?.father}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                  />

                  <FamilyDetailComponent
                    parent="mother"
                    parentLabel="Mother's Name"
                    parentValues={values.family.mother}
                    parentErrors={errors.family?.mother}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                  />
                  {/* <Grid item xs={12}>
                <CheckboxComponent
                  name="isMarried"
                  label="Are you married?"
                  isChecked={isMarry}
                  checkboxChange={handleIsMarry}
                />
              </Grid> */}
                  {isMarried === "Yes" ? (
                    <FamilyDetailComponent
                      parent="spouse"
                      parentLabel="Spouse's Name"
                      parentValues={values.family.spouse}
                      parentErrors={errors.family?.spouse}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      setFieldValue={setFieldValue}
                    />
                  ) : (
                    <></>
                  )}
                </Grid>
              </TitleBox>
            </Grid>

            {/* lang */}
            <Grid item xs={12}>
              <TitleBox label="Languages">
                <Grid container columnSpacing={4} rowSpacing={4}>
                  {values.languages.map((curElem, index) => (
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={4}
                      lg={4}
                      sx={{ pb: "25px" }}
                      key={index}
                    >
                      <LanguageComponent
                        langDetails={curElem}
                        handleChange={handleChange}
                        names={[
                          `languages[${index}].speak`,
                          `languages[${index}].read`,
                          `languages[${index}].write`,
                        ]}
                      />
                    </Grid>
                  ))}
                </Grid>
              </TitleBox>
            </Grid>
          </Grid>

          {/*  button*/}
          <Grid
            container
            justifyContent="flex-end"
            sm={12}
            sx={{ marginBottom: "1em" }}
          >
            <div style={{ marginRight: "1em" }}>
              <ButtonComponent
                handleSubmit={handleSubmit}
                buttonText={"Previous"}
                width="100px"
              />
            </div>
            <ButtonComponent
              handleSubmit={handleSubmit}
              buttonText={isNext ? "Next" : "Save"}
              width="100px"
              isLoading={isNext ? false : isLoadingPATCH}
            />
          </Grid>
        </>
      )}
    </ContainerComponent>
  );
};

export default FamilyLanguage;
