import * as yup from "yup";
export const educationValidationSchema = yup.object().shape({
  class10: yup.object().shape({
    institute: yup.string().required("Required!"),
    yearOfPassing: yup.string().required("Required!"),
    percentage: yup
      .number()
      .required("Required!")
      .typeError("A number is required")
      .min(1, "Percentage should be more than 0")
      .max(100, "Percentage should be less than or equal to 100"),
  }),
  class12: yup.object().shape({
    institute: yup.string().required("Required!"),
    yearOfPassing: yup.string().required("Required!"),
    percentage: yup
      .number()
      .required("Required!")
      .typeError("A number is required")
      .min(1, "Percentage should be more than 0")
      .max(100, "Percentage should be less than or equal to 100"),
  }),
  isDiploma: yup.boolean(),
  diploma: yup.object().when("isDiploma", {
    is: (value: boolean) => value === true,
    then: yup.object().shape({
      institute: yup.string().required("Required!"),
      yearOfPassing: yup.string().required("Required!"),
      percentage: yup
        .number()
        .required("Required!")
        .typeError("A number is required")
        .min(1, "Percentage should be more than 0")
        .max(100, "Percentage should be less than or equal to 100"),
    }),
  }),
  isGraduate: yup.boolean(),
  graduation: yup.object().when("isGraduate", {
    is: (value: boolean) => value === true,
    then: yup.object().shape({
      institute: yup.string().required("Required!"),
      yearOfPassing: yup.string().required("Required!"),
      percentage: yup
        .number()
        .required("Required!")
        .typeError("A number is required")
        .min(1, "Percentage should be more than 0")
        .max(100, "Percentage should be less than or equal to 100"),
    }),
  }),
  isPostGraduate: yup.boolean(),
  postGraduation: yup.object().when("isPostGraduate", {
    is: (value: boolean) => value === true,
    then: yup.object().shape({
      institute: yup.string().required("Required!"),
      yearOfPassing: yup.string().required("Required!"),
      percentage: yup
        .number()
        .required("Required!")
        .typeError("A number is required")
        .min(1, "Percentage should be more than 0")
        .max(100, "Percentage should be less than or equal to 100"),
    }),
  }),
});
