import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import { useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { IOnboarding } from "../../interfaces/components.interface";
import {
  formDetailsComponents,
  IFormDetailsComponents,
  onboardingComponents,
} from "../../pages/Onboarding/components";
import {
  documentFormSubmittedAtom,
  formDetailsAtom,
  onboardingTabsAtom,
  recruiteeFormSubmittedAtom,
} from "../../recoil";
import { theme } from "../../theme";
import LineComponent from "../LineComponent/LineComponent";
import StepperComponent from "../Stepper/StepperComponent";

const AccordionComponent = () => {
  // const [expanded, setExpanded] = useRecoilState(onboardingTabsAtom);
  // const currentActiveAccordion = expanded.recruiteeDetailsTabs
  //   ? onboardingComponents[0]
  //   : onboardingComponents[1];

  // const recruiteeFormSubmitted = useRecoilValue(recruiteeFormSubmittedAtom);
  // const documentFormSubmitted = useRecoilValue(documentFormSubmittedAtom);

  // const handleChange = (curElem: IOnboarding) => {
  //   const isRecruiteeFormFullySubmitted = Object.values(
  //     recruiteeFormSubmitted.form
  //   );

  //   if (!isRecruiteeFormFullySubmitted.every((val: boolean) => !!val)) return;

  //   setExpanded((prev) => ({
  //     documentUploadsTabs: curElem.id === onboardingComponents[1].id,
  //     recruiteeDetailsTabs: curElem.id === onboardingComponents[0].id,
  //   }));
  // };

  const [activeTab, setActiveTab] = useRecoilState(formDetailsAtom);
  const handleChange = (curElem: IFormDetailsComponents) => {
    //if recruiteeDetails not completed --> documentUploads expanded --> false

    if (curElem.id === 1) {
      setActiveTab((prev) => ({
        ...prev,
        recruiteeDetails: {
          ...prev.recruiteeDetails,
          tabExpanded: true,
        },
        documentUploads: {
          ...prev.documentUploads,
          tabExpanded: false,
        },
      }));
      return;
    }
    if (!activeTab.recruiteeDetails.tabCompleted) return;

    if (curElem.id === 2) {
      setActiveTab((prev) => ({
        ...prev,
        recruiteeDetails: {
          ...prev.recruiteeDetails,
          tabExpanded: false,
        },
        documentUploads: {
          ...prev.documentUploads,
          tabExpanded: true,
        },
      }));
    }
    return;
  };

  return (
    <>
      <Box>
        {/* {onboardingComponents.map((curElem) => (
          <>
            <Accordion
              elevation={0}
              key={curElem.id}
              sx={{
                background: "transparent",
                color: "white",
              }}
              expanded={currentActiveAccordion.id === curElem.id}
              onChange={() => handleChange(curElem)}
            >
              <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
                sx={{
                  // background: "red",
                  background: "#49497D",
                  borderRadius: "10px",
                  // height: "48px",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: theme.typography.fontWeightBold,
                    fontSize: "14px",
                    margin: "auto",
                  }}
                >
                  {curElem.title}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <StepperComponent steps={curElem.tab} />
              </AccordionDetails>
            </Accordion>
          </>
        ))} */}

        {formDetailsComponents.map((curElem) => (
          <>
            <Accordion
              elevation={0}
              key={curElem.id}
              sx={{
                background: "transparent",
                color: "white",
              }}
              expanded={
                activeTab.recruiteeDetails.tabExpanded
                  ? curElem.id === 1
                  : curElem.id === 2
              }
              onChange={() => handleChange(curElem)}
            >
              <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
                sx={{
                  background: "#49497D",
                  borderRadius: "10px",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: theme.typography.fontWeightBold,
                    fontSize: "14px",
                    margin: "auto",
                  }}
                >
                  {curElem.title}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <StepperComponent steps={curElem.tab} />
              </AccordionDetails>
            </Accordion>
          </>
        ))}
      </Box>
    </>
  );
};

export default AccordionComponent;

{
  /* <Accordion
          sx={{ background: "transparent" }}
          expanded={exp.recruiteeDetailsTabs}
          onChange={handleChange}
        >
          <AccordionSummary
            aria-controls="panel1d-content"
            id="panel1d-header"
            sx={{
              background: "#49497D",
              borderRadius: "10px",
              // height: "2em",
            }}
          >
            <Typography>Recruitee Details</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <StepperComponent steps={recruiteeDetailsComponents} />
          </AccordionDetails>
        </Accordion>
        <Accordion
          sx={{ background: "transparent" }}
          expanded={exp.documentUploadsTabs}
          onChange={handleChange}
        >
          <AccordionSummary
            aria-controls="panel2d-content"
            id="panel2d-header"
            sx={{
              background: "#49497D",
              borderRadius: "10px",
              height: "2em",
            }}
          >
            <Typography>Document Uploads</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <StepperComponent steps={documentUploadsComponents} />
          </AccordionDetails>
        </Accordion> */
}
