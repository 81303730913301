import {
  Button,
  Grid,
  InputLabel,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { useEffect, useState } from "react";
import { StyledSpanError } from "../../../../components/StyledComponents/StyledSpanError";
import { StyledTextField } from "../../../../components/StyledComponents/StyledTextField/StyledTextField";
import TitleBox from "../../../../components/TitleBox/TitleBox";
import { SVGICONS } from "../../../../constants/svgicons";
import { IExperienceDetails } from "../../../../interfaces/forms/workExperience.interface";
import { textColor } from "../styleParent";

interface IWorkExperienceReferenceError {
  name: string;
  relationship: string;
  contact: string;
  designation: string;
}

interface IWorkExperienceError {
  organization: string;
  tctc: string;
  startDate: string;
  endDate: string;
  designation: string;
  reasonForLeaving: string;
  reference: IWorkExperienceReferenceError;
}

interface WorkExperienceFormInterface {
  index: number;
  name: string[];
  errors: any;
  value: IExperienceDetails;
  removeExperience: (index: number) => void;
  handleChange: {
    (e: React.ChangeEvent<any>): void;
    <T_1 = string | React.ChangeEvent<any>>(
      field: T_1
    ): T_1 extends React.ChangeEvent<any>
      ? void
      : (e: string | React.ChangeEvent<any>) => void;
  };
  setFieldValue: any;
}

const WorkExperienceForm = ({
  index,
  name,
  errors,
  value,
  removeExperience,
  handleChange,
  setFieldValue,
}: WorkExperienceFormInterface) => {
  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.down("md"));

  const mediumScreen = {
    color: "#fff",
    borderRadius: "20px",
    border: "1px solid #fff",
    textTransform: "none",
  };

  const smallScreen = {
    color: "#fff",
    borderRadius: "20px",
    border: "1px solid #fff",
    textTransform: "none",
    fontSize: "10px",
  };

  function handleChangeStartDate(dateData: number | null) {
    setFieldValue(name[2], new Date(dateData as number).toISOString());
  }

  function handleChangeEndDate(dateData: number | null) {
    setFieldValue(name[3], new Date(dateData as number).toISOString());
  }

  const [errorMessage, setErrorMessage] = useState<IWorkExperienceError | null>(
    {
      organization: "",
      tctc: "",
      startDate: "",
      endDate: "",
      designation: "",
      reasonForLeaving: "",
      reference: {
        name: "",
        relationship: "",
        contact: "",
        designation: "",
      },
    }
  );

  useEffect(() => {
    setErrorMessages();
  }, [errors]);

  const setErrorMessages = () => {
    const isValidErrors = Array.isArray(errors);

    if (errors.length < 1) {
      setErrorMessage(null);
    }

    if (isValidErrors) {
      errors.forEach((error) => {
        if (index.toString() === error.index) {
          Object.keys(error).forEach((key) => {
            const reference = key.split(".")[0];
            const referenceKey = key.split(".")[1];

            if (key !== "index") {
              if (reference === "reference") {
                setErrorMessage((prev: any) => ({
                  ...prev,
                  reference: {
                    ...prev?.reference,
                    [referenceKey]: error[key],
                  },
                }));
              } else if (key === "tctc") {
                setErrorMessage((prev: any) => ({
                  ...prev,
                  tctc: "TCTC must be greater than or equal to 1",
                }));
              } else {
                setErrorMessage((prev: any) => ({
                  ...prev,
                  [key]: error[key],
                }));
              }
            }
          });
        }
      });
    }
  };

  return (
    <Grid container spacing={2} direction="row">
      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
        <InputLabel shrink htmlFor="bootstrap-input" sx={textColor}>
          Organization Name *
        </InputLabel>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <StyledTextField
            autoComplete="off"
            name={name[0]}
            value={value.organization}
            onChange={handleChange}
          />
          <StyledSpanError>{errorMessage?.organization}</StyledSpanError>
        </div>
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <InputLabel shrink htmlFor="bootstrap-input" sx={textColor}>
            TCTC (PA) *
          </InputLabel>
          <StyledTextField
            autoComplete="off"
            type="number"
            name={name[1]}
            value={value.tctc}
            onChange={handleChange}
          />
          <StyledSpanError>{errorMessage?.tctc}</StyledSpanError>
        </div>
      </Grid>

      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <InputLabel shrink htmlFor="bootstrap-input" sx={textColor}>
            Start Date *
          </InputLabel>
          <DesktopDatePicker
            disableFuture
            inputFormat="DD/MM/YYYY"
            value={value.startDate}
            onChange={handleChangeStartDate}
            renderInput={(params) => (
              <StyledTextField
                autoComplete="off"
                {...params}
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
              />
            )}
          />
          <StyledSpanError>{errorMessage?.startDate}</StyledSpanError>
        </div>
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <InputLabel shrink htmlFor="bootstrap-input" sx={textColor}>
            End Date *
          </InputLabel>
          <DesktopDatePicker
            disableFuture
            inputFormat="DD/MM/YYYY"
            value={value.endDate}
            onChange={handleChangeEndDate}
            renderInput={(params) => (
              <StyledTextField
                autoComplete="off"
                {...params}
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
              />
            )}
          />
          <StyledSpanError>{errorMessage?.endDate}</StyledSpanError>
        </div>
      </Grid>

      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <InputLabel shrink htmlFor="bootstrap-input" sx={textColor}>
            Designation
          </InputLabel>
          <StyledTextField
            autoComplete="off"
            type="text"
            name={name[4]}
            value={value.designation}
            onChange={handleChange}
          />
          <StyledSpanError>{errorMessage?.designation}</StyledSpanError>
        </div>
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <InputLabel shrink htmlFor="bootstrap-input" sx={textColor}>
            Reason for leaving
          </InputLabel>
          <StyledTextField
            autoComplete="off"
            type="text"
            name={`${name[5]}`}
            value={value.reasonForLeaving}
            onChange={handleChange}
          />
          <StyledSpanError>{errorMessage?.reasonForLeaving}</StyledSpanError>
        </div>
      </Grid>

      {/* reference details */}
      <Grid item xs={12} spacing={2} sx={{ mt: "1rem" }}>
        <TitleBox label="Reference">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <InputLabel shrink htmlFor="bootstrap-input" sx={textColor}>
                Name
              </InputLabel>
              <StyledTextField
                autoComplete="off"
                type="text"
                name={`${name[6]}.name`}
                value={value?.reference?.name}
                onChange={handleChange}
              />
              <StyledSpanError>{errorMessage?.reference?.name}</StyledSpanError>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <InputLabel shrink htmlFor="bootstrap-input" sx={textColor}>
                Relationship
              </InputLabel>
              <StyledTextField
                autoComplete="off"
                type="text"
                name={`${name[6]}.relationship`}
                value={value?.reference?.relationship}
                onChange={handleChange}
              />
              <StyledSpanError>
                {errorMessage?.reference?.relationship}
              </StyledSpanError>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <InputLabel shrink htmlFor="bootstrap-input" sx={textColor}>
                Contact
              </InputLabel>
              <StyledTextField
                autoComplete="off"
                name={`${name[6]}.contact`}
                value={value?.reference?.contact}
                onChange={handleChange}
              />
              <StyledSpanError>
                {errorMessage?.reference?.contact}
              </StyledSpanError>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <InputLabel shrink htmlFor="bootstrap-input" sx={textColor}>
                Designation
              </InputLabel>
              <StyledTextField
                autoComplete="off"
                type="text"
                name={`${name[6]}.designation`}
                value={value?.reference?.designation}
                onChange={handleChange}
              />
              <StyledSpanError>
                {errorMessage?.reference?.designation}
              </StyledSpanError>
            </Grid>
          </Grid>
        </TitleBox>
      </Grid>

      <Grid container justifyContent="flex-end">
        <Button
          variant="outlined"
          startIcon={<img src={SVGICONS.DeleteIcon} />}
          onClick={() => removeExperience(index)}
          sx={isMedium ? smallScreen : mediumScreen}
        >
          Remove Experience
        </Button>
      </Grid>
    </Grid>
  );
};

export default WorkExperienceForm;
