import WrapperComponent from "../../components/Wrapper/WrapperComponent";
import HeaderComponent from "./HeaderComponent";
import BodyComponent from "./BodyComponent";
import { useEffect, useState } from "react";

const Onboarding = () => {
  return (
    <WrapperComponent>
      <HeaderComponent />
      <BodyComponent />
    </WrapperComponent>
  );
};

export default Onboarding;
