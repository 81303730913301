import { ButtonComponentInterface } from "../../interfaces";
import CustomButton from "./CustomButton";

const ButtonComponent = ({
  handleSubmit,
  buttonText,
  width,
  isNext,
  isLoading,
  formComplete,
}: ButtonComponentInterface) => {
  return (
    <CustomButton
      handleSubmit={handleSubmit}
      buttonText={buttonText}
      width={`${width}`}
      isNext={isNext}
      isLoading={isLoading}
      formComplete={formComplete}
    />
  );
};

export default ButtonComponent;
