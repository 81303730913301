import { SVGICONS } from "../../constants";
import { IAccordionComponents, IOnboarding } from "../../interfaces/components.interface";
import AcademicCertificates from "./DocumentUploads/AcademicCertificates";
import Photos from "./DocumentUploads/Photos";
import ProofOfIdentity from "./DocumentUploads/ProofOfIdentity";
import TVDocuments from "./DocumentUploads/TVDocuments";
import WorkExpDocuments from "./DocumentUploads/WorkExpDocuments";
import BankDetails from "./RecruiteeDetails/BankDetails";
import BasicDetails from "./RecruiteeDetails/BasicDetails";
import EducationalQualification from "./RecruiteeDetails/EducationalQualification";
import EmployeeInformation from "./RecruiteeDetails/EmployeeInformation";
import FamilyLanguage from "./RecruiteeDetails/FamilyLanguage";
import WorkExperience from "./RecruiteeDetails/WorkExperience";

export let recruiteeDetailsComponents: IAccordionComponents =
{
    key: "recruiteeDetailsComponents",
    comp: [
        {
            id: 1,
            icon: `${SVGICONS.BasicDetailsIcon}`,
            title: "Basic Details",
            component: BasicDetails,
            key: 'basicDetailsForm',
        },
        {
            id: 2,
            icon: `${SVGICONS.EduQualIcon}`,
            title: "Educational Qualification",
            component: EducationalQualification,
            key: 'eduQualForm',

        },
        {
            id: 3,
            icon: `${SVGICONS.WorkExpIcon}`,
            title: "Work Experience",
            component: WorkExperience,
            key: 'workExpForm',

        },
        {
            id: 4,
            icon: `${SVGICONS.FamilyIcon}`,
            title: "Family & Language",
            component: FamilyLanguage,
            key: 'familyLangForm',

        },
        {
            id: 5,
            icon: `${SVGICONS.BankDetailsIcon}`,
            title: "Bank Details",
            component: BankDetails,
            key: 'bankDetailsForm',

        },
    ],
};

export let documentUploadsComponents: IAccordionComponents =
{
    key: "documentUploadsComponents",
    comp: [
        {
            id: 1,
            icon: `${SVGICONS.PhotosIcon}`,
            title: "Photos",
            component: Photos,
            key: 'photosForm',

        },
        // {
        //     id: 2,
        //     icon: `${SVGICONS.TVDocumentsIcon}`,
        //     title: "Techvariable Documents",
        //     component: TVDocuments,
        //     key: 'tvDocsForm',

        // },
        {
            id: 2,
            icon: `${SVGICONS.ProofIdentityIcon}`,
            title: "Proof of Identity",
            component: ProofOfIdentity,
            key: 'identityProofForm',

        },
        {
            id: 3,
            icon: `${SVGICONS.AcadCertificatesIcon}`,
            title: "Academic Certificates",
            component: AcademicCertificates,
            key: 'acadCertificatesForm',

        },
        {
            id: 4,
            icon: `${SVGICONS.WorkExpDocumentsIcon}`,
            title: "Work Experience Documents",
            component: WorkExpDocuments,
            key: 'workExpDocumentForm',

        },
    ]
}


export let onboardingComponents: IOnboarding[] = [
    {
        id: 1,
        title: "Recruitee Details",
        tab: recruiteeDetailsComponents,
        isExpanded: true,
    },
    {
        id: 2,
        title: "Document Uploads",
        tab: documentUploadsComponents,
        isExpanded: false,
    },
];


//new recoil setup
export interface IFormDetailsComponents {
    id: number;
    title: string;
    tab: IAccordionComponents
}

export const formDetailsComponents: IFormDetailsComponents[] = [
    {
        id: 1,
        title: "Recruitee Details",
        tab: recruiteeDetailsComponents,
    },
    {
        id: 2,
        title: "Document Uploads",
        tab: documentUploadsComponents,
    },
]
