import { useState } from "react";
import { WrapperInterface } from "../../interfaces";
import { StyledDiv } from "../StyledComponents/StyledDiv";
import {
  ellipseOne,
  ellipseOneMobile,
  ellipseThree,
  ellipseThreeMobile,
  ellipseTwo,
  ellipseTwoMobile,
} from "./wrapperStyles";

const WrapperComponent = ({ children }: WrapperInterface) => {
  const [width, setWidth] = useState<number>(window.innerWidth);
  const breakpoint: number = 600;

  window.addEventListener("resize", () => {
    setWidth(window.innerWidth);
  });

  return (
    <StyledDiv>
      {children}
      <StyledDiv
        sx={width < breakpoint ? ellipseOne : ellipseOneMobile}
      ></StyledDiv>
      <StyledDiv
        sx={width < breakpoint ? ellipseTwo : ellipseTwoMobile}
      ></StyledDiv>
      <StyledDiv
        sx={width < breakpoint ? ellipseThree : ellipseThreeMobile}
      ></StyledDiv>
    </StyledDiv>
  );
};

export default WrapperComponent;
