import { Dropzone } from "@mantine/dropzone";
import { Grid } from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useCallback, useState } from "react";
import { FileRejection, FileWithPath } from "react-dropzone";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  deleteDocsAPI,
  fetchDocsAPI,
  saveDocsAPI,
} from "../../../../api/uploads";
import ButtonComponent from "../../../../components/ButtonComponent";
import CircularLoader from "../../../../components/CircularLoader";
import ContainerComponent from "../../../../components/Container/ContainerComponent";
import SnackBar from "../../../../components/SnackBar";
import ColorButton from "../../../../components/StyledComponents/UploadButton";
import TitleBox from "../../../../components/TitleBox/TitleBox";
import { SVGICONS } from "../../../../constants";
import {
  FILE_SIZE,
  FILE_TYPE_OTHERS,
} from "../../../../constants/fileProperties";
import { ISnackbarDetailsInterface } from "../../../../interfaces";
import { documentFormSubmittedAtom, formDetailsAtom } from "../../../../recoil";
import DisplayMessage from "../DisplayMessage";
import "../styles.css";
import { uploadButton } from "../uploadConstants";
import UploadDetails from "../UploadDetails";

const AcademicCertificates = () => {
  const formData = new FormData();
  const [fileNames, setFileNames] = useState<{
    class10: string;
    class12: string;
    diploma: string;
    grad: string;
    postgrad: string;
  }>({
    class10: "",
    class12: "",
    diploma: "",
    grad: "",
    postgrad: "",
  });

  const [class10Value, setClass10Value] = useState<FileWithPath | null>(null);
  const [class12Value, setClass12Value] = useState<FileWithPath | null>(null);
  const [diplomaValue, setDiplomaValue] = useState<FileWithPath | null>(null);
  const [graduateValue, setGraduateValue] = useState<FileWithPath | null>(null);
  const [PostGraduateValue, setPostGraduateValue] =
    useState<FileWithPath | null>(null);

  const [isDisable, setIsDisable] = useState<{
    class10: boolean;
    class12: boolean;
    diploma: boolean;
    graduate: boolean;
    postGraduate: boolean;
  }>({
    class10: false,
    class12: false,
    diploma: false,
    graduate: false,
    postGraduate: false,
  });

  const [isNext, setIsNext] = useState<boolean>(false);

  const [snackBarState, setSnackBarState] = useState<ISnackbarDetailsInterface>(
    {
      message: "",
      open: false,
      severity: undefined,
    }
  );

  // const setDocumentFormSubmitted = useSetRecoilState(documentFormSubmittedAtom);
  const [activeTab, setActiveTab] = useRecoilState(formDetailsAtom);
  const tabs = activeTab.documentUploads.tabsArray.map((tab) => {
    if (tab.id === activeTab.documentUploads.stepCount)
      return {
        ...tab,
        isCompleted: true,
      };
    return tab;
  });

  const handleCloseSnackbar = () => {
    setSnackBarState((prev) => ({
      ...prev,
      open: false,
    }));
  };

  const { isLoading: isLoadingFetch } = useQuery({
    queryKey: ["getAcademicDocs"],
    queryFn: fetchDocsAPI,
    onSuccess: (response) => {
      if (response.data?.academicCertificates?.class10th) {
        setFileNames((prev) => ({
          ...prev,
          class10: "HSLC Certificate",
        }));
        setIsDisable((prev) => ({
          ...prev,
          class10: true,
        }));
        setClass10Value(response.data?.academicCertificates?.class10th);
      }
      if (response.data?.academicCertificates?.class12th) {
        setFileNames((prev) => ({
          ...prev,
          class12: "HS Certificate",
        }));
        setIsDisable((prev) => ({
          ...prev,
          class12: true,
        }));
        setClass12Value(response.data?.academicCertificates?.class12th);
      }
      if (response.data?.academicCertificates?.diploma) {
        setFileNames((prev) => ({
          ...prev,
          diploma: "Diploma Certificate",
        }));
        setIsDisable((prev) => ({
          ...prev,
          diploma: true,
        }));
        setDiplomaValue(response.data?.academicCertificates?.diploma);
      }
      if (response.data?.academicCertificates?.graduation) {
        setFileNames((prev) => ({
          ...prev,
          grad: "Graduation Certificate",
        }));
        setIsDisable((prev) => ({
          ...prev,
          graduate: true,
        }));
        setGraduateValue(response.data?.academicCertificates?.graduation);
      }
      if (response.data?.academicCertificates?.postGraduation) {
        setFileNames((prev) => ({
          ...prev,
          postgrad: "Post Graduation Certificate",
        }));
        setIsDisable((prev) => ({
          ...prev,
          postGraduate: true,
        }));
        setPostGraduateValue(
          response.data?.academicCertificates?.postGraduation
        );
      }
    },
  });

  const onDropClass10 = useCallback(
    (file10: FileWithPath[]) => {
      setFileNames((prev) => ({
        ...prev,
        class10: file10[0].name,
      }));
      formData.append("class10th", file10[0]);
      setClass10Value(file10[0]);
    },
    [fileNames, class10Value]
  );

  const onDropClass12 = useCallback(
    (file12: FileWithPath[]) => {
      setFileNames((prev) => ({
        ...prev,
        class12: file12[0].name,
      }));
      formData.append("class12th", file12[0]);
      setClass12Value(file12[0]);
    },
    [fileNames, class12Value]
  );

  const onDropDiploma = useCallback(
    (fileDiploma: FileWithPath[]) => {
      setFileNames((prev) => ({
        ...prev,
        diploma: fileDiploma[0].name,
      }));
      formData.append("diploma", fileDiploma[0]);
      setDiplomaValue(fileDiploma[0]);
    },
    [fileNames, diplomaValue]
  );

  const onDropGrad = useCallback(
    (fileGrad: FileWithPath[]) => {
      setFileNames((prev) => ({
        ...prev,
        grad: fileGrad[0].name,
      }));
      formData.append("graduation", fileGrad[0]);
      setGraduateValue(fileGrad[0]);
    },
    [fileNames, graduateValue]
  );

  const onDropPostgrad = useCallback(
    (filePostGrad: FileWithPath[]) => {
      setFileNames((prev) => ({
        ...prev,
        postgrad: filePostGrad[0].name,
      }));
      formData.append("postGraduation", filePostGrad[0]);
      setPostGraduateValue(filePostGrad[0]);
    },
    [fileNames, PostGraduateValue]
  );

  const onRejectClass10 = (files: FileRejection[]) => {
    if (files[0].errors[0].code == "file-too-large") {
      setSnackBarState({
        open: true,
        message: "File size should not be larger than 1mb",
        severity: "warning",
      });
    } else {
      setSnackBarState({
        open: true,
        message: "File type must be only in .pdf format",
        severity: "warning",
      });
    }
  };

  const onRejectClass12 = (files: FileRejection[]) => {
    if (files[0].errors[0].code == "file-too-large") {
      setSnackBarState({
        open: true,
        message: "File size should not be larger than 1mb",
        severity: "warning",
      });
    } else {
      setSnackBarState({
        open: true,
        message: "File type must be only in .pdf format",
        severity: "warning",
      });
    }
  };

  const onRejectDiploma = (files: FileRejection[]) => {
    if (files[0].errors[0].code == "file-too-large") {
      setSnackBarState({
        open: true,
        message: "File size should not be larger than 1mb",
        severity: "warning",
      });
    } else {
      setSnackBarState({
        open: true,
        message: "File type must be only in .pdf format",
        severity: "warning",
      });
    }
  };

  const onRejectGraduation = (files: FileRejection[]) => {
    if (files[0].errors[0].code == "file-too-large") {
      setSnackBarState({
        open: true,
        message: "File size should not be larger than 1mb",
        severity: "warning",
      });
    } else {
      setSnackBarState({
        open: true,
        message: "File type must be only in .pdf format",
        severity: "warning",
      });
    }
  };

  const onRejectPostGraduation = (files: FileRejection[]) => {
    if (files[0].errors[0].code == "file-too-large") {
      setSnackBarState({
        open: true,
        message: "File size should not be larger than 1mb",
        severity: "warning",
      });
    } else {
      setSnackBarState({
        open: true,
        message: "File type must be only in .pdf format",
        severity: "warning",
      });
    }
  };

  const handleSubmit = async () => {
    if (class10Value === null || class12Value === null) {
      setSnackBarState({
        open: true,
        message: "Class 10 and 12 Files are Required!",
        severity: "error",
      });
    } else {
      const formDataNew = new FormData();
      formDataNew.append("class10th", class10Value);
      formDataNew.append("class12th", class12Value);
      if (diplomaValue !== null) {
        formDataNew.append("diploma", diplomaValue);
      }
      if (graduateValue !== null) {
        formDataNew.append("graduation", graduateValue);
      }
      if (PostGraduateValue !== null) {
        formDataNew.append("postGraduation", PostGraduateValue);
      }
      mutate(formDataNew);
    }
  };

  const { mutate, isLoading } = useMutation({
    mutationFn: saveDocsAPI,
    onSuccess: () => {
      setSnackBarState({
        open: true,
        message: "successfully submitted!",
        severity: "success",
      });
      // setDocumentFormSubmitted((prev) => ({
      //   ...prev,
      //   form: {
      //     ...prev.form,
      //     acadCertificates: true,
      //   },
      // }));
      setActiveTab((prev) => ({
        ...prev,
        documentUploads: {
          ...prev.documentUploads,
          tabsArray: tabs,
        },
      }));
      setIsNext(true);
    },
    onError: (error) => {
      setSnackBarState({
        open: true,
        message: "Something went wrong",
        severity: "error",
      });
    },
  });

  const clearNameClass10 = () => {
    const confirm = window.confirm("Are you sure to delete the file?");
    if (confirm) {
      mutateDeleteClass10("academicCertificates.class10th");
    }
  };

  const clearNameClass12 = () => {
    const confirm = window.confirm("Are you sure to delete the file?");
    if (confirm) {
      mutateDeleteClass12("academicCertificates.class12th");
    }
  };

  const clearNameDiploma = () => {
    const confirm = window.confirm("Are you sure to delete the file?");
    if (confirm) {
      mutateDeleteDiploma("academicCertificates.diploma");
    }
  };

  const clearNameGrad = () => {
    const confirm = window.confirm("Are you sure to delete the file?");
    if (confirm) {
      mutateDeleteGraduate("academicCertificates.graduation");
    }
  };

  const clearNamePostgrad = () => {
    const confirm = window.confirm("Are you sure to delete the file?");
    if (confirm) {
      mutateDeletePostGraduate("academicCertificates.postGraduation");
    }
  };

  const { mutate: mutateDeleteClass10, isLoading: isDeleteClass10Loading } =
    useMutation({
      mutationFn: deleteDocsAPI,
      onSuccess: () => {
        setFileNames((prev) => ({
          ...prev,
          class10: "",
        }));
        setClass10Value(null);
        setIsDisable((prev) => ({
          ...prev,
          class10: false,
        }));
        setSnackBarState({
          open: true,
          message: "File Deleted!",
          severity: "success",
        });
      },
      onError: () => {
        setIsDisable((prev) => ({
          ...prev,
          class10: true,
        }));
      },
    });

  const { mutate: mutateDeleteClass12, isLoading: isDeleteClass12Loading } =
    useMutation({
      mutationFn: deleteDocsAPI,
      onSuccess: () => {
        setFileNames((prev) => ({
          ...prev,
          class12: "",
        }));
        setClass12Value(null);
        setIsDisable((prev) => ({
          ...prev,
          class12: false,
        }));
        setSnackBarState({
          open: true,
          message: "File Deleted!",
          severity: "success",
        });
      },
      onError: () => {
        setIsDisable((prev) => ({
          ...prev,
          class12: true,
        }));
      },
    });

  const { mutate: mutateDeleteDiploma, isLoading: isDeleteDiplomaLoading } =
    useMutation({
      mutationFn: deleteDocsAPI,
      onSuccess: () => {
        setFileNames((prev) => ({
          ...prev,
          diploma: "",
        }));
        setDiplomaValue(null);
        setIsDisable((prev) => ({
          ...prev,
          diploma: false,
        }));
        setSnackBarState({
          open: true,
          message: "File Deleted!",
          severity: "success",
        });
      },
      onError: () => {
        setIsDisable((prev) => ({
          ...prev,
          diploma: true,
        }));
      },
    });

  const { mutate: mutateDeleteGraduate, isLoading: isDeleteGraduateLoading } =
    useMutation({
      mutationFn: deleteDocsAPI,
      onSuccess: () => {
        setFileNames((prev) => ({
          ...prev,
          grad: "",
        }));
        setGraduateValue(null);
        setIsDisable((prev) => ({
          ...prev,
          graduate: false,
        }));
        setSnackBarState({
          open: true,
          message: "File Deleted!",
          severity: "success",
        });
      },
      onError: () => {
        setIsDisable((prev) => ({
          ...prev,
          graduate: true,
        }));
      },
    });

  const {
    mutate: mutateDeletePostGraduate,
    isLoading: isDeletePostGraduateLoading,
  } = useMutation({
    mutationFn: deleteDocsAPI,
    onSuccess: () => {
      setFileNames((prev) => ({
        ...prev,
        postgrad: "",
      }));
      setPostGraduateValue(null);
      setIsDisable((prev) => ({
        ...prev,
        postGraduate: false,
      }));
      setSnackBarState({
        open: true,
        message: "File Deleted!",
        severity: "success",
      });
    },
    onError: () => {
      setIsDisable((prev) => ({
        ...prev,
        postGraduate: true,
      }));
    },
  });

  return (
    <ContainerComponent>
      <>
        <Grid container direction="row" columnSpacing={5}>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TitleBox label={"Class 10 Marksheet"}>
              <Dropzone
                className="dropzone"
                disabled={isDisable.class10}
                onDrop={(files) => onDropClass10(files)}
                onReject={(files: FileRejection[]) => onRejectClass10(files)}
                maxSize={FILE_SIZE}
                accept={FILE_TYPE_OTHERS}
              >
                <ColorButton
                  variant="contained"
                  startIcon={<img src={SVGICONS.UploadDocumentIcon} />}
                  sx={uploadButton}
                >
                  Upload File
                </ColorButton>
                <DisplayMessage fileType="PDF" />
              </Dropzone>
              {fileNames.class10 !== "" ? (
                <UploadDetails
                  fileName={fileNames.class10}
                  clearName={clearNameClass10}
                  isLoading={isDeleteClass10Loading}
                />
              ) : (
                <></>
              )}
            </TitleBox>
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TitleBox label={"Class 12 Marksheet"}>
              <Dropzone
                disabled={isDisable.class12}
                onDrop={(files) => onDropClass12(files)}
                onReject={(files: FileRejection[]) => onRejectClass12(files)}
                maxSize={FILE_SIZE}
                accept={FILE_TYPE_OTHERS}
                className="dropzone"
              >
                <ColorButton
                  variant="contained"
                  startIcon={<img src={SVGICONS.UploadDocumentIcon} />}
                  sx={uploadButton}
                >
                  Upload File
                </ColorButton>
                <DisplayMessage fileType="PDF" />
              </Dropzone>
              {fileNames.class12 !== "" ? (
                <UploadDetails
                  fileName={fileNames.class12}
                  clearName={clearNameClass12}
                  isLoading={isDeleteClass12Loading}
                />
              ) : (
                <></>
              )}
            </TitleBox>
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TitleBox label={"Diploma Marksheet"}>
              <Dropzone
                disabled={isDisable.diploma}
                onDrop={(files) => onDropDiploma(files)}
                onReject={(files: FileRejection[]) => onRejectDiploma(files)}
                maxSize={FILE_SIZE}
                accept={FILE_TYPE_OTHERS}
                className="dropzone"
              >
                <ColorButton
                  variant="contained"
                  startIcon={<img src={SVGICONS.UploadDocumentIcon} />}
                  sx={uploadButton}
                >
                  Upload File
                </ColorButton>
                <DisplayMessage fileType="PDF" />
              </Dropzone>
              {fileNames.diploma !== "" ? (
                <UploadDetails
                  fileName={fileNames.diploma}
                  clearName={clearNameDiploma}
                  isLoading={isDeleteDiplomaLoading}
                />
              ) : (
                <></>
              )}
            </TitleBox>
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TitleBox label={"Graduation Marksheet (Last Semester)"}>
              <Dropzone
                disabled={isDisable.graduate}
                onDrop={(files) => onDropGrad(files)}
                onReject={(files: FileRejection[]) => onRejectGraduation(files)}
                maxSize={FILE_SIZE}
                accept={FILE_TYPE_OTHERS}
                className="dropzone"
              >
                <ColorButton
                  variant="contained"
                  startIcon={<img src={SVGICONS.UploadDocumentIcon} />}
                  sx={uploadButton}
                >
                  Upload File
                </ColorButton>
                <DisplayMessage fileType="PDF" />
              </Dropzone>
              {fileNames.grad !== "" ? (
                <UploadDetails
                  fileName={fileNames.grad}
                  clearName={clearNameGrad}
                  isLoading={isDeleteGraduateLoading}
                />
              ) : (
                <></>
              )}
            </TitleBox>
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TitleBox label={"Post Graduation Marksheet (Last Semester)"}>
              <Dropzone
                disabled={isDisable.postGraduate}
                onDrop={(files) => onDropPostgrad(files)}
                onReject={(files: FileRejection[]) =>
                  onRejectPostGraduation(files)
                }
                maxSize={FILE_SIZE}
                accept={FILE_TYPE_OTHERS}
                className="dropzone"
              >
                <ColorButton
                  variant="contained"
                  startIcon={<img src={SVGICONS.UploadDocumentIcon} />}
                  sx={uploadButton}
                >
                  Upload File
                </ColorButton>
                <DisplayMessage fileType="PDF" />
              </Dropzone>
              {fileNames.postgrad !== "" ? (
                <UploadDetails
                  fileName={fileNames.postgrad}
                  clearName={clearNamePostgrad}
                  isLoading={isDeletePostGraduateLoading}
                />
              ) : (
                <></>
              )}
            </TitleBox>
          </Grid>
        </Grid>

        <Grid container justifyContent="flex-end">
          <div style={{ marginRight: "1em" }}>
            <ButtonComponent
              handleSubmit={handleSubmit}
              buttonText={"Previous"}
              width="100px"
            />
          </div>
          <ButtonComponent
            handleSubmit={handleSubmit}
            buttonText={isNext ? "Next" : "Save"}
            width="100px"
            isLoading={isNext ? false : isLoading}
          />
        </Grid>
        <SnackBar
          snackbarOpen={snackBarState.open}
          handleCloseSnackbar={handleCloseSnackbar}
          message={snackBarState.message}
          severity={snackBarState.severity}
        />
      </>
    </ContainerComponent>
  );
};

export default AcademicCertificates;
