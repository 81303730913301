import { atom } from "recoil";
import { educationSchema } from "../schemas/EducationalQualificationsSchema";
import { workExperienceAtomSchema } from "../schemas/WorkExperienceSchema";
import { IFormType } from '../components/Stepper/StepperComponent'

export const checkState = atom({
  key: "checkedState",
  default: {
    class10: true,
    class12: true,
    diploma: false,
    graduation: false,
    postGraduation: false,
  },
});

export const educationQualificationAtom = atom({
  key: "educationQualificationAtom",
  default: educationSchema,
});

const initialWorkExp: workExperienceAtomSchema = {
  experience: []
}

export const workExperienceAtom = atom({
  key: "workExperienceAtom",
  default: initialWorkExp,
});


//tabs
export const recruiteeAtom = atom({
  key: "recruiteeAtom",
  default: 1,
});

export const documentUploadsAtom = atom({
  key: "documentUploadsAtom",
  default: 1,
});

export const onboardingTabsAtom = atom({
  key: "onboardingTabsAtom",
  default: {
    recruiteeDetailsTabs: true,
    documentUploadsTabs: false,
    // currentAccordionIndex: 1
  },
});


const recruiteeFormSubmittedSchema: IFormType = {
  form: {
    basicDetailsForm: false,
    eduQualForm: false,
    workExpForm: false,
    familyLangForm: false,
    bankDetailsForm: false,
    // empInfoForm: false,
  },
  currentActiveStep: 0//starting at 0 because we can index access inside 
}

const documentFormSubmittedSchema: IFormType = {
  form: {
    photosForm: false,
    tvForm: false,
    identityProofForm: false,
    acadCertificates: false,
    workExpDocumentForm: false,
  },
  currentActiveStep: 0//starting at 0 because we can index access inside array
}

export const recruiteeFormSubmittedAtom = atom({
  key: "recruiteeFormSubmittedAtom",
  default: recruiteeFormSubmittedSchema
})

export const documentFormSubmittedAtom = atom({
  key: "documentFormSubmittedAtom",
  default: documentFormSubmittedSchema
})

export const accordionCompletedAtom = atom({
  key: "accordionCompletedAtom",
  default: {
    "recruiteeCompleted": false,
    "documentCompleted": false
  }
})


//----------------------new -recoil--------------------
export interface IForm {
  id: number
  name: string
  isCompleted: boolean
}

export interface IHeaderTab {
  tabsArray: IForm[]
  stepCount: number
  tabExpanded: boolean
  tabCompleted: boolean;
}
export interface IFormDetails {
  recruiteeDetails: IHeaderTab
  documentUploads: IHeaderTab
}

const formDetails: IFormDetails = {
  recruiteeDetails: {
    tabsArray: [
      {
        id: 1,
        name: "basicDetailsForm",
        isCompleted: false
      },
      {
        id: 2,
        name: 'eduQualForm',
        isCompleted: false
      },
      {
        id: 3,
        name: 'workExpForm',
        isCompleted: false
      },
      {
        id: 4,
        name: 'familyLangForm',
        isCompleted: false
      },
      {
        id: 5,
        name: 'bankDetailsForm',
        isCompleted: false
      },
    ],
    stepCount: 1,
    tabExpanded: true,
    tabCompleted: false
  },
  documentUploads: {
    tabsArray: [
      {
        id: 1,
        name: "photosForm",
        isCompleted: false
      },
      // {
      //   id: 2,
      //   name: 'tvDocsForm',
      //   isCompleted: false
      // },
      {
        id: 2,
        name: 'identityProofForm',
        isCompleted: false
      },
      {
        id: 3,
        name: 'acadCertificatesForm',
        isCompleted: false
      },
      {
        id: 4,
        name: 'workExpDocumentForm',
        isCompleted: false

      },
    ],
    stepCount: 1,
    tabExpanded: false,
    tabCompleted: false
  }
}

export const formDetailsAtom = atom({
  key: "formDetailsAtom",
  default: formDetails
})



// export const currentActiveStepAtom = atom({
//   key: "currentActiveStepAtom",
//   default: {
//     tabName: TabName.RECRUITEE_DETAILS,
//     activeStep: {
//       id: 1,
//       name: "basicDetailsForm",
//       isCompleted: false
//     },
//   }
// })