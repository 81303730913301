import React, { useEffect } from "react";
import CheckboxComponent from "../../../../../components/Checkbox/CheckboxComponent";
import { Typography, FormControlLabel, Checkbox } from "@mui/material";
import { ILanguage } from "../../../../../interfaces/forms/familyLanguage.interface";

export interface ILanguageComponentProps {
  langDetails: ILanguage;
  handleChange: (e: React.ChangeEvent<Element>) => void;
  names: string[];
}

const LanguageComponent = ({
  langDetails,
  handleChange,
  names,
}: ILanguageComponentProps) => {
  return (
    <>
      <Typography sx={{ pb: "25px" }} fontSize="0.875rem" fontWeight={600}>
        {langDetails?.languageName}
      </Typography>
      <CheckboxComponent
        name={names[0]}
        label="Speak"
        isChecked={langDetails?.speak}
        checkboxChange={handleChange}
      />
      <CheckboxComponent
        name={names[1]}
        label="Read"
        isChecked={langDetails?.read}
        checkboxChange={handleChange}
      />
      <CheckboxComponent
        name={names[2]}
        label="Write"
        isChecked={langDetails?.write}
        checkboxChange={handleChange}
      />
    </>
  );
};

export default LanguageComponent;
