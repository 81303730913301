import { Button, styled, SxProps, Theme, Typography } from "@mui/material";
import React, { Children, ReactNode, useState } from "react";
import { useRecoilState } from "recoil";
import { ICONS } from "../../constants";
import {
  documentUploadsComponents,
  recruiteeDetailsComponents,
} from "../../pages/Onboarding/components";
import {
  documentUploadsAtom,
  formDetailsAtom,
  onboardingTabsAtom,
  recruiteeAtom,
} from "../../recoil";
import CircularLoader from "../CircularLoader";
import "./styles.scss";

const gradientColor1 = () => {
  return `radial-gradient(circle closest-side,  rgba(200, 148, 255, 0.65), transparent)`;
};
const gradientColor2 = () => {
  return `radial-gradient(circle closest-side,  rgba(120, 0, 245, 0.5), transparent)`;
};

export const StyledButton = styled(Button)<{ left: number; top: number }>`
  margin: 0.1em;
  position: relative;
  background: #01002c;
  // padding: 0.2rem 1rem;
  font-size: 1.2rem;
  border: none;
  color: white;
  width: 242px;
  height: 2em;
  cursor: pointer;
  outline: none;
  overflow: hidden;

  &:hover {
    background: #01002c;
  }

  &:before {
    content: "";
    left: ${({ left }) => left}px;
    top: ${({ top }) => top}px;
    position: absolute;
    width: 0;
    height: 0;
    background: ${gradientColor1};
    transform: translate(-50%, -50%);
    // transition: width 0.2s ease, height 0.2s ease, background 2s ease-in-out;
    // animation: changeBackground 5s ease-in-out infinite;
  }

  &:hover:before {
    color: red;
    width: 200px;
    height: 200px;
    -webkit-transform-origin: 50% 50%;
    -moz-transform-origin: 50% 50%;
    -o-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }

  @keyframes changeBackground {
    0% {
      background: ${gradientColor1};
    }
    25% {
      background: ${gradientColor2};
    }
    50% {
      background: ${gradientColor1};
    }
    75% {
      background: ${gradientColor2};
    }
    100% {
      background: ${gradientColor1};
    }
  }
`;

const CustomButton = (props: {
  children?: ReactNode;
  sx?: SxProps<Theme>;
  formComplete?: string;
  handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
  buttonText: string;
  width: string;
  isNext?: boolean;
  isLoading?: boolean;
}) => {
  // console.log("child", props.formComplete);
  // const [recruiteeStepNo, setRecruiteeStepNo] = useRecoilState(recruiteeAtom);
  // const [activeAccordion, setActiveAccordion] =
  //   useRecoilState(onboardingTabsAtom);
  // const [uploadsStepNo, setUploadStepNo] = useRecoilState(documentUploadsAtom);
  // // const [activeTab, setActivetab] = useRecoilState(tabsNumberAtom);

  const [activeTab, setActiveTab] = useRecoilState(formDetailsAtom);

  const [hoverStyle, setHoverStyle] = useState({
    left: 0,
    top: 0,
  });

  const lastStepRecruiteeDetails = recruiteeDetailsComponents.comp.length;
  const lastStepDocumentUploads = documentUploadsComponents.comp.length;

  const handleNext = () => {
    //   if (activeAccordion.recruiteeDetailsTabs) {
    //     const lastStep =
    //       recruiteeDetailsComponents.comp[
    //         recruiteeDetailsComponents.comp.length - 1
    //       ].id;

    //     if (recruiteeStepNo === lastStep) {
    //       setActiveAccordion({
    //         documentUploadsTabs: true,
    //         recruiteeDetailsTabs: false,
    //       });

    //       // console.log("sstepinside", recruiteeStepNo, uploadsStepNo);
    //       // setActivetab(2);
    //       // setUploadStepNo(1);
    //     }

    //     console.log({ lastStep, recruiteeStepNo });

    //     setRecruiteeStepNo((prevStep) => {
    //       if (prevStep === lastStep) return prevStep;
    //       return prevStep + 1;
    //     });
    //   } else {
    //     const lastStep =
    //       documentUploadsComponents.comp[
    //         documentUploadsComponents.comp.length - 1
    //       ].id;

    //     setUploadStepNo((prevStep) => {
    //       if (prevStep === lastStep) return prevStep;
    //       return prevStep + 1;
    //     });
    //   }
    // };

    // const handlePrev = () => {
    //   if (activeAccordion.recruiteeDetailsTabs) {
    //     setRecruiteeStepNo((prevStep) => prevStep - 1);
    //   } else {
    //     console.log("upzz", uploadsStepNo);
    //     if (uploadsStepNo === 1) {
    //       setActiveAccordion({
    //         documentUploadsTabs: false,
    //         recruiteeDetailsTabs: true,
    //       });
    //       return;
    //     }
    //     setUploadStepNo((prevStep) => prevStep - 1);
    //   }

    if (activeTab.recruiteeDetails.tabExpanded) {
      if (activeTab.recruiteeDetails.stepCount === lastStepRecruiteeDetails) {
        setActiveTab((prev) => ({
          ...prev,
          recruiteeDetails: {
            ...prev.recruiteeDetails,
            tabExpanded: false,
          },
          documentUploads: {
            ...prev.documentUploads,
            tabExpanded: true,
            stepCount: 1,
          },
        }));
        return;
      }

      setActiveTab((prev) => ({
        ...prev,
        recruiteeDetails: {
          ...prev.recruiteeDetails,
          stepCount: prev.recruiteeDetails.stepCount + 1,
        },
      }));
    }

    setActiveTab((prev) => ({
      ...prev,
      documentUploads: {
        ...prev.documentUploads,
        stepCount: prev.documentUploads.stepCount + 1,
      },
    }));
  };

  const handlePrev = () => {
    if (activeTab.recruiteeDetails.tabExpanded) {
      setActiveTab((prev) => ({
        ...prev,
        recruiteeDetails: {
          ...prev.recruiteeDetails,
          stepCount: prev.recruiteeDetails.stepCount - 1,
        },
      }));
      return;
    }

    if (activeTab.documentUploads.stepCount === 1) {
      setActiveTab((prev) => ({
        ...prev,
        recruiteeDetails: {
          ...prev.recruiteeDetails,
          tabExpanded: true,
          stepCount: 5,
        },
        documentUploads: {
          ...prev.documentUploads,
          tabExpanded: false,
        },
      }));
      return;
    }

    setActiveTab((prev) => ({
      ...prev,
      documentUploads: {
        ...prev.documentUploads,
        stepCount: prev.documentUploads.stepCount - 1,
      },
    }));
  };

  const handleMouseMove: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    const rect = e.currentTarget.getBoundingClientRect();
    const left = e.clientX - rect.left;
    const top = e.clientY - rect.top;

    setHoverStyle({ left, top });
  };

  const handleClick = () => {
    if (props.buttonText.toLowerCase() === "next") {
      handleNext();
    } else if (props.buttonText.toLowerCase() === "previous") {
      handlePrev();
    } else {
      props.handleSubmit();
    }
  };

  return (
    <div
      style={{ justifyContent: "center", display: "flex", marginTop: "1rem" }}
    >
      <div className="btngradient">
        <StyledButton
          left={hoverStyle.left}
          top={hoverStyle.top}
          type="submit"
          variant="contained"
          sx={{ borderRadius: "21px", width: props.width }}
          onMouseMove={handleMouseMove}
          onClick={handleClick}
        >
          <Typography
            sx={{
              zIndex: 99,
              fontFamily: "Poppins",
              fontStyle: "normal",
              fontWeight: 600,
              fontSize: "12px",
              lineHeight: "18px",
              textTransform: "none",
              display: "flex",
              flexDirection: "row",
              gap: ".8em",
              alignItems: "center",
            }}
          >
            {props.isLoading ? (
              <CircularLoader />
            ) : (
              <>
                {props.buttonText.toLowerCase() === "logout" && (
                  <ICONS.ArrowBackIcon fontSize="small" />
                )}

                {props.buttonText}

                {props.buttonText.toLowerCase() === "next" && (
                  <ICONS.ArrowForwardIcon fontSize="small" />
                )}
              </>
            )}
          </Typography>
        </StyledButton>
      </div>
    </div>
  );
};

export default CustomButton;
