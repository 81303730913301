import { Box, Typography } from "@mui/material";

const DisplayMessage = ({ fileType }: { fileType: string }) => {
  return (
    <Box sx={{ mt: "1rem" }}>
      <Typography
        variant="body1"
        mb=".3rem"
        fontSize=".625rem"
        fontStyle="italic"
      >
        File should be in {fileType} format
      </Typography>
      <Typography variant="body1" fontSize=".625rem" fontStyle="italic">
        Size should be less than 1mb
      </Typography>
    </Box>
  );
};

export default DisplayMessage;
