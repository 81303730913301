import { Box, Typography } from "@mui/material";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const ThankYouForm = () => {
  const token = sessionStorage.getItem("access_token");
  const location = useLocation();
  const navigate = useNavigate();

  function checkIsFormCompleted() {
    if (!token) navigate("/");
    else if (token && location.state === null) navigate("/onboarding");
    else {
      setTimeout(() => {
        sessionStorage.clear();
        navigate("/");
      }, 5000);
    }
  }

  useEffect(() => {
    checkIsFormCompleted();
  }, []);

  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography
        variant="h3"
        sx={{
          fontWeight: 500,
          color: "white",
          //   background: "-webkit-linear-gradient(#ffe259, #ffa751)",
          //   WebkitBackgroundClip: "text",
          //   WebkitTextFillColor: "transparent",
        }}
      >
        Thank You
      </Typography>
    </Box>
  );
};

export default ThankYouForm;
