import BasicDetailsIcon from "../assets/icons/basic-details.svg";
import EduQualIcon from "../assets/icons/edu-qual.svg";
import WorkExpIcon from "../assets/icons/work-exp.svg";
import FamilyIcon from "../assets/icons/family.svg";
import BankDetailsIcon from "../assets/icons/bank-details.svg";
import EmpInfoIcon from "../assets/icons/emp-info.svg";

import PhotosIcon from "../assets/icons/photos.svg";
import AcadCertificatesIcon from "../assets/icons/academic-certificates.svg";
import WorkExpDocumentsIcon from "../assets/icons/workexp-documents.svg";
import TVDocumentsIcon from "../assets/icons/tv-documents.svg";
import ProofIdentityIcon from "../assets/icons/proof-identity.svg";
import DeleteIcon from "../assets/icons/delete.svg";
import UploadDocumentIcon from "../assets/icons/uploadDocumentIcon.svg";
import FileIcon from "../assets/icons/fileIcon.svg";
import CloseIcon from "../assets/icons/closeIcon.svg";

export const SVGICONS = {
  BankDetailsIcon,
  BasicDetailsIcon,
  EduQualIcon,
  WorkExpIcon,
  FamilyIcon,
  EmpInfoIcon,
  PhotosIcon,
  AcadCertificatesIcon,
  WorkExpDocumentsIcon,
  TVDocumentsIcon,
  ProofIdentityIcon,
  DeleteIcon,
  UploadDocumentIcon,
  FileIcon,
  CloseIcon,
};
