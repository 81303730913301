import { BaseURL, MODULE_NAME } from "../constants/baseurl";
import { axiosInstance, axiosUploadInstance } from "./axios.global";

export const saveDocsAPI = async (payload: FormData) => {
  const response = await axiosUploadInstance.patch(
    `${BaseURL}/${MODULE_NAME}/temp-upload-docs`,
    payload
  );
  return response;
};

export const fetchDocsAPI = async () => {
  const response = await axiosInstance.get(
    `${MODULE_NAME}/temp-get-uploaded-documents`
  );
  return response;
};

export const deleteDocsAPI = async (documentKey: string) => {
  const response = await axiosInstance.delete(
    `${MODULE_NAME}/delete/temp-upload-docs/${documentKey}`
  );
  return response;
};
