import * as yup from "yup";
import { FormValuesInterface } from ".";
import { phoneRegex } from "../../../../yup-schemas/basicDetailsVSchema";

export const validationSchema = yup.array().of(
  yup.object().shape({
    organization: yup.string().required("Required"),
    tctc: yup
      .number()
      .required("Required")
      .min(1)
      .typeError("A number is required"),
    designation: yup.string().required("Required"),
    startDate: yup.string().required("Required"),
    endDate: yup.string().required("Required"),
    reasonForLeaving: yup.string().required("Required"),
    reference: yup.object().shape({
      name: yup.string().required("Required"),
      relationship: yup.string().required("Required"),
      contact: yup.string().required("Required").matches(phoneRegex, "Please provide a valid contact number"),
      designation: yup.string().required("Required"),
    }),
  })
);

export const validate = async (values: FormValuesInterface) => {
  const response = await validationSchema
    .validate(values.workExperience, { abortEarly: false })
    .catch((error: any) => error.inner);

  let validationErrors: any = [];
  if (Array.isArray(response)) {
    response.forEach((resp, index) => {
      if (resp.path !== undefined) {
        let key = resp?.path?.split(".")[1];
        let errorIndex = resp?.path?.split(".")[0];

        let items: any = [...validationErrors];

        let item = { ...items[index] };
        if (key === "reference") {
          let refKey = `${key}.${resp?.path?.split(".")[2]}`;
          item[refKey] = resp.message;
        } else {
          item[key] = resp.message;
        }
        item.index = errorIndex?.split("[")[1]?.split("]")[0];

        validationErrors[index] = item;
      } else {
        validationErrors = [];
      }
    });
  } else {
    validationErrors = [];
  }

  return validationErrors;
};
