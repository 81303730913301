const AddressObject = {
    city: "",
    state: "",
    pin: "",
    addressField: "",
};

export const basicDetailsSchema = {
    name: "",
    age: "",
    gender: "",
    married: "",
    bloodGroup: "",
    birthDate: '',
    emailID: "",
    idProofs: {
        aadharNo: "",
        panNo: "",
        voterIdNo: "",
        passportNo: "",
    },
    mobileNo: "",
    address: {
        present: AddressObject,
        permanent: AddressObject,
    },
    isPermanentAddressDifferent: false,
    emergencyContact: {
        name: "",
        relationship: "",
        contactNo: "",
    },
}