import { MODULE_NAME } from "../constants/baseurl";
import { IBankDetails } from "../interfaces/forms/bankDetails.interface";
import { IBasicDetails } from "../interfaces/forms/basicDetails.interface";
import { IEmployeeInformation } from "../interfaces/forms/employeeInformation.interface";
import { IFamilyLanguage } from "../interfaces/forms/familyLanguage.interface";
import { axiosInstance } from "./axios.global";
import {
  ISaveBankDetailsAPI,
  ISaveBasicDetailsAPI,
  ISaveEmployeeInformationAPI,
  ISaveFamilyLanguageAPI,
} from "./interface";

export const saveEducationAPI = async (payload: any) => {
  const res = await axiosInstance.patch(
    `${MODULE_NAME}/temp-education-details`,
    payload
  );
  return res;
};

export const saveWorkExperinceAPI = async (payload: any) => {
  const payld = { workExperience: payload };
  const res = await axiosInstance.patch(
    `${MODULE_NAME}/temp-work-experience`,
    payld
  );
  return res;
};

export const getUserInfoAPI = async () => {
  return await axiosInstance.get(`${MODULE_NAME}/get-user-info`);
}

export const getEmployeeInfoAPI = async () => {
  const res = await axiosInstance.get(`${MODULE_NAME}/temp-get-employee-information`);
  return res;
};

export const saveBasicDetailsAPI = async (payload: IBasicDetails) => {
  const res = await axiosInstance.patch(`${MODULE_NAME}/temp-basic-details`, payload);
  return res;
};

export const saveFamilyLanguageAPI = async (payload: IFamilyLanguage) => {
  const res = await axiosInstance.patch(`${MODULE_NAME}/temp-family-and-language-details`, payload);
  return res;
};

export const updateSpouseAPI = async () => {
  return await axiosInstance.patch(`${MODULE_NAME}/temp-spouse-details-update`);
}

export const saveBankDetailsAPI = async (payload: IBankDetails) => {
  const res = await axiosInstance.patch(`${MODULE_NAME}/temp-bank-details`, payload);
  return res;
};

export const saveEmployeeInformationAPI = async (payload: IEmployeeInformation) => {
  const res = await axiosInstance.patch(`${MODULE_NAME}/temp-employeeInformation`, payload);
  return res;
}
