import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";

interface CheckIconInterface {
  isChecked: boolean;
}

const CheckIcon = ({ isChecked }: CheckIconInterface) => {
  const whiteText = { color: "#fff" };

  return (
    <div>
      {isChecked ? (
        <CheckCircleIcon fontSize="small" sx={whiteText} />
      ) : (
        <RadioButtonUncheckedIcon fontSize="small" sx={whiteText} />
      )}
    </div>
  );
};

export default CheckIcon;
