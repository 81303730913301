import * as yup from 'yup';

export const PINregex = /^(\d{6})$/;
export const voterIDregex = /^([a-zA-Z]){3}([0-9]){7}?$/;
export const aadharRegex = /^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/;
export const panRegex = /^([A-Z]){5}([0-9]){4}([A-Z]){1}?$/;
export const passportRegex = /^[A-Za-z][0-9]{7,8}$/
// --> /^(?!^0+$)[a-zA-Z0-9]{6,9}$/
// --> /^[A-Z][0-9]{8}$/
export const phoneRegex = /^[6-9]\d{9}$/;


const IdProofsSchema = yup.object().shape({
    aadharNo: yup
        .string()
        .required("Aadhar Number is required!")
        .matches(aadharRegex, "Aadhar Number should be valid!"),
    panNo: yup
        .string()
        .required("PAN is required!")
        .matches(panRegex, "PAN should be valid!"),
    voterIdNo: yup
        .string()
        .matches(voterIDregex, "Voter ID should be valid!"),
    passportNo: yup
        .string()
        .matches(passportRegex, "Passport number should be valid!"),
})

const AddressDetailsSchema = yup.object().shape({
    city: yup.string().required("City is required!"),
    state: yup.string().required("State is required!"),
    pin: yup.string().required("PIN is required!").matches(PINregex, "Please provide a valid PIN"),
    addressField: yup.string().required("Address is required!")
})

const AddressSchema = yup.object().when("isPermanentAddressDifferent", {
    is: (value: boolean) => value === true,
    then: yup.object().shape({
        present: AddressDetailsSchema,
        permanent: AddressDetailsSchema
    }),
    otherwise: yup.object().shape({
        present: AddressDetailsSchema,
    })
})


const EmergencyContactSchema = yup.object().shape({
    name: yup.string().required("Emergency Contact Name is required!"),
    relationship: yup.string().required("Emergency Contact Relationship is required!"),
    contactNo: yup.string().required("Emergency Contact Number is required!").matches(phoneRegex, "Please provide a valid contact number"),
})

export const basicDetailsVSchema = yup.object().shape({
    name: yup.string().required("Full Name is required!"),
    age: yup.string().required("Age is required!"),
    gender: yup.string().required("Gender is required!"),
    married: yup.string().required("Marital Status is required!"),
    bloodGroup: yup.string().required("Blood Group is required!"),
    birthDate: yup.string().required("DOB is required!"),
    emailID: yup.string().email("Please provide a valid email").required("Email is required!"),
    idProofs: IdProofsSchema,
    mobileNo: yup.string().required("Phone Number is required!").matches(phoneRegex, "Phone number should be valid!"),
    address: AddressSchema,
    isPermanentAddressDifferent: yup.boolean(),
    emergencyContact: EmergencyContactSchema
})