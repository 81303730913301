import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import Onboarding from "./pages/Onboarding";
import LoginRequest from "./pages/LoginRequest/LoginRequest";
import LoginOtp from "./pages/LoginRequest/LoginOtp";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StyledTextField } from "./components/StyledComponents/StyledTextField/StyledTextField";
import ThankYouForm from "./pages/ThankYou";
import { useEffect } from "react";
import ProtectedRoutes from "./routes/ProtectedRoutes";
import GuestRoute from "./routes/GuestRoute";
import PageNotFound from "./pages/404Page";

function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <GuestRoute>
                <LoginRequest />
              </GuestRoute>
            }
          />
          <Route
            path="/verify-otp"
            element={
              <GuestRoute>
                <LoginOtp />
              </GuestRoute>
            }
          />
          <Route
            path="/onboarding"
            element={
              <ProtectedRoutes>
                <Onboarding />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/thankyou"
            element={
              <ProtectedRoutes>
                <ThankYouForm />
              </ProtectedRoutes>
            }
          />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Router>
    </LocalizationProvider>
  );
}

export default App;
