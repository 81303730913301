import { Dropzone } from "@mantine/dropzone";
import { Grid } from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useCallback, useState } from "react";
import { FileRejection, FileWithPath } from "react-dropzone";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { setUserDone } from "../../../../api/login";
import {
  deleteDocsAPI,
  fetchDocsAPI,
  saveDocsAPI,
} from "../../../../api/uploads";
import ButtonComponent from "../../../../components/ButtonComponent";
import ContainerComponent from "../../../../components/Container/ContainerComponent";
import SnackBar from "../../../../components/SnackBar";
import ColorButton from "../../../../components/StyledComponents/UploadButton";
import TitleBox from "../../../../components/TitleBox/TitleBox";
import { SVGICONS } from "../../../../constants";
import {
  FILE_SIZE,
  FILE_TYPE_OTHERS,
} from "../../../../constants/fileProperties";
import { ISnackbarDetailsInterface } from "../../../../interfaces";
import { formDetailsAtom } from "../../../../recoil";
import DisplayMessage from "../DisplayMessage";
import "../styles.css";
import { uploadButton } from "../uploadConstants";
import UploadDetails from "../UploadDetails";

const WorkExpDocuments = () => {
  const [fileNames, setFileNames] = useState<{
    reliving: string;
    lastSal: string;
    secondLastSal: string;
    thirdLastSal: string;
  }>({
    reliving: "",
    lastSal: "",
    secondLastSal: "",
    thirdLastSal: "",
  });

  const [isDisable, setIsDisable] = useState<{
    reliving: boolean;
    lastSal: boolean;
    secondLastSal: boolean;
    thirdLastSal: boolean;
  }>({
    reliving: false,
    lastSal: false,
    secondLastSal: false,
    thirdLastSal: false,
  });

  // console.log({ isDisable });

  const [isNext, setIsNext] = useState<boolean>(false);
  const [snackBarState, setSnackBarState] = useState<ISnackbarDetailsInterface>(
    {
      message: "",
      open: false,
      severity: undefined,
    }
  );

  const [relivingValue, setRelivingValue] = useState<FileWithPath | null>(null);
  const [lastSlipValue, setLastSlipValue] = useState<FileWithPath | null>(null);
  const [secondLastSlipValue, setSecondLastSlipValue] =
    useState<FileWithPath | null>(null);
  const [thirdLastSlipValue, setThirdLastSlipValue] =
    useState<FileWithPath | null>(null);

  // const setDocumentFormSubmitted = useSetRecoilState(documentFormSubmittedAtom);
  // const setAccordionCompleted = useSetRecoilState(accordionCompletedAtom);

  const [activeTab, setActiveTab] = useRecoilState(formDetailsAtom);
  const tabs = activeTab.documentUploads.tabsArray.map((tab) => {
    if (tab.id === activeTab.documentUploads.stepCount)
      return {
        ...tab,
        isCompleted: true,
      };
    return tab;
  });

  const navigate = useNavigate();

  const handleCloseSnackbar = () => {
    setSnackBarState((prev) => ({
      ...prev,
      open: false,
    }));
  };

  const { isLoading: isLoadingFetch, data: uploadsData } = useQuery({
    queryKey: ["getWorkExperienceDocs"],
    queryFn: fetchDocsAPI,
    onSuccess: (response) => {
      // console.log("workexpdocs get", response.data.workExperience);

      if (response.data?.workExperience?.relivingLetter) {
        setFileNames((prev) => ({
          ...prev,
          reliving: "Reliving Letter",
        }));
        setIsDisable((prev) => ({
          ...prev,
          reliving: true,
        }));
        setRelivingValue(response.data?.workExperience?.relivingLetter);
      }

      if (response.data?.workExperience?.salarySlip1st) {
        setFileNames((prev) => ({
          ...prev,
          lastSal: "Last Salary Slip",
        }));
        setIsDisable((prev) => ({
          ...prev,
          lastSal: true,
        }));
        setLastSlipValue(response.data?.workExperience?.salarySlip1st);
      }

      if (response.data?.workExperience?.salarySlip2nd) {
        setFileNames((prev) => ({
          ...prev,
          secondLastSal: "Second Last Salary Slip",
        }));
        setIsDisable((prev) => ({
          ...prev,
          secondLastSal: true,
        }));
        setSecondLastSlipValue(response.data?.workExperience.salarySlip2nd);
      }
      if (response.data?.workExperience?.salarySlip3rd) {
        setFileNames((prev) => ({
          ...prev,
          thirdLastSal: "Third Last Salary Slip",
        }));
        setIsDisable((prev) => ({
          ...prev,
          thirdLastSal: true,
        }));
        setThirdLastSlipValue(response.data?.workExperience.salarySlip3rd);
      }
    },
  });

  const onDropReliving = useCallback(
    (fileReliving: FileWithPath[]) => {
      setFileNames((prev) => ({
        ...prev,
        reliving: fileReliving[0].name,
      }));
      setRelivingValue(fileReliving[0]);
    },
    [relivingValue]
  );

  const onDropLast = useCallback(
    (fileLast: FileWithPath[]) => {
      setFileNames((prev) => ({
        ...prev,
        lastSal: fileLast[0].name,
      }));
      setLastSlipValue(fileLast[0]);
    },
    [lastSlipValue]
  );

  const onDropSecondLast = useCallback(
    (file2ndLast: FileWithPath[]) => {
      setFileNames((prev) => ({
        ...prev,
        secondLastSal: file2ndLast[0].name,
      }));
      setSecondLastSlipValue(file2ndLast[0]);
    },
    [secondLastSlipValue]
  );

  const onDropThirdLast = useCallback(
    (file3rdLast: FileWithPath[]) => {
      setFileNames((prev) => ({
        ...prev,
        thirdLastSal: file3rdLast[0].name,
      }));
      setThirdLastSlipValue(file3rdLast[0]);
    },
    [thirdLastSlipValue]
  );

  const onRejectReliving = (files: FileRejection[]) => {
    if (files[0].errors[0].code === "file-too-large") {
      setSnackBarState({
        open: true,
        message: "File size should not be larger than 1mb",
        severity: "warning",
      });
    } else {
      setSnackBarState({
        open: true,
        message: "File type must be only in .pdf format",
        severity: "warning",
      });
    }
  };

  const onRejectLastSlip = (files: FileRejection[]) => {
    if (files[0].errors[0].code === "file-too-large") {
      setSnackBarState({
        open: true,
        message: "File size should not be larger than 1mb",
        severity: "warning",
      });
    } else {
      setSnackBarState({
        open: true,
        message: "File type must be only in .pdf format",
        severity: "warning",
      });
    }
  };

  const onRejectSecondLastSlip = (files: FileRejection[]) => {
    if (files[0].errors[0].code === "file-too-large") {
      setSnackBarState({
        open: true,
        message: "File size should not be larger than 1mb",
        severity: "warning",
      });
    } else {
      setSnackBarState({
        open: true,
        message: "File type must be only in .pdf format",
        severity: "warning",
      });
    }
  };

  const onRejectThirdLastSlip = (files: FileRejection[]) => {
    if (files[0].errors[0].code === "file-too-large") {
      setSnackBarState({
        open: true,
        message: "File size should not be larger than 1mb",
        severity: "warning",
      });
    } else {
      setSnackBarState({
        open: true,
        message: "File type must be only in .pdf format",
        severity: "warning",
      });
    }
  };

  const handleSubmit = async () => {
    try {
      const formData: any = new FormData();

      if (relivingValue !== undefined) {
        formData.append("relivingLetter", relivingValue);
      }
      if (lastSlipValue !== undefined) {
        formData.append("salarySlip1st", lastSlipValue);
      }
      if (secondLastSlipValue !== undefined) {
        formData.append("salarySlip2nd", secondLastSlipValue);
      }
      if (thirdLastSlipValue !== undefined) {
        formData.append("salarySlip3rd", thirdLastSlipValue);
      }

      const response = await saveDocsAPI(formData);
      if (response.status === 200) {
        setIsNext(true);
        setSnackBarState({
          open: true,
          message: "Successfully Submitted!",
          severity: "success",
        });
        // setDocumentFormSubmitted((prev) => ({
        //   ...prev,
        //   form: {
        //     ...prev.form,
        //     workExpDocumentForm: true,
        //   },
        // }));
        setActiveTab((prev) => ({
          ...prev,
          documentUploads: {
            ...prev.documentUploads,
            tabsArray: tabs,
          },
        }));
      } else {
        setIsNext(false);
        setSnackBarState({
          open: true,
          message: "Something went wrong!",
          severity: "error",
        });
      }
    } catch (err) {
      // console.log({ err });
    }
  };

  const { mutate } = useMutation({
    mutationFn: setUserDone,
    onSuccess: () => {
      // setAccordionCompleted({
      //   recruiteeCompleted: true,
      //   documentCompleted: true,
      // });
      setActiveTab((prev) => ({
        ...prev,
        recruiteeDetails: {
          ...prev.recruiteeDetails,
          tabCompleted: true,
        },
        documentUploads: {
          ...prev.documentUploads,
          tabCompleted: true,
        },
      }));
      navigate("/thankyou", { state: { completed: true } });
    },
  });

  const clearNameReliving = () => {
    const confirm = window.confirm("Are you sure to delete the file?");
    if (confirm) {
      mutateDeleteReliving("workExperience.relivingLetter");
    }
  };

  const clearNameLast = () => {
    const confirm = window.confirm("Are you sure to delete the file?");
    if (confirm) {
      mutateDeleteSalLast("workExperience.salarySlip1st");
    }
  };

  const clearName2ndLast = () => {
    const confirm = window.confirm("Are you sure to delete the file?");
    if (confirm) {
      mutateDelete2ndSalLast("workExperience.salarySlip2nd");
    }
  };

  const clearName3rdLast = () => {
    const confirm = window.confirm("Are you sure to delete the file?");
    if (confirm) {
      mutateDelete3rdSalLast("workExperience.salarySlip3rd");
    }
  };

  const { mutate: mutateDeleteReliving, isLoading: isDeleteRelivingLoading } =
    useMutation({
      mutationFn: deleteDocsAPI,
      onSuccess: () => {
        setFileNames((prev) => ({
          ...prev,
          reliving: "",
        }));
        setIsDisable((prev) => ({
          ...prev,
          reliving: false,
        }));
        setRelivingValue(null);
        setSnackBarState({
          open: true,
          message: "File Deleted!",
          severity: "success",
        });
      },
      onError: () => {
        setIsDisable((prev) => ({
          ...prev,
          reliving: true,
        }));
      },
    });

  const { mutate: mutateDeleteSalLast, isLoading: isDeleteSalLastLoading } =
    useMutation({
      mutationFn: deleteDocsAPI,
      onSuccess: () => {
        setFileNames((prev) => ({
          ...prev,
          lastSal: "",
        }));

        setIsDisable((prev) => ({
          ...prev,
          lastSal: false,
        }));
        setLastSlipValue(null);
        setSnackBarState({
          open: true,
          message: "File Deleted!",
          severity: "success",
        });
      },
      onError: () => {
        setIsDisable((prev) => ({
          ...prev,
          lastSal: true,
        }));
      },
    });

  const {
    mutate: mutateDelete2ndSalLast,
    isLoading: isDelete2ndSalLastLoading,
  } = useMutation({
    mutationFn: deleteDocsAPI,
    onSuccess: () => {
      setFileNames((prev) => ({
        ...prev,
        secondLastSal: "",
      }));
      setIsDisable((prev) => ({
        ...prev,
        secondLastSal: false,
      }));
      setSecondLastSlipValue(null);
      setSnackBarState({
        open: true,
        message: "File Deleted!",
        severity: "success",
      });
    },
    onError: () => {
      setIsDisable((prev) => ({
        ...prev,
        secondLastSal: true,
      }));
    },
  });

  const {
    mutate: mutateDelete3rdSalLast,
    isLoading: isDelete3rdSalLastLoading,
  } = useMutation({
    mutationFn: deleteDocsAPI,
    onSuccess: () => {
      setFileNames((prev) => ({
        ...prev,
        thirdLastSal: "",
      }));
      setIsDisable((prev) => ({
        ...prev,
        thirdLastSal: false,
      }));
      setThirdLastSlipValue(null);
      setSnackBarState({
        open: true,
        message: "File Deleted!",
        severity: "success",
      });
    },
    onError: () => {
      setIsDisable((prev) => ({
        ...prev,
        thirdLastSal: true,
      }));
    },
  });

  const handleFinish = () => {
    // console.log({ uploadsData });

    //check for photos
    if (
      uploadsData?.data?.photos?.passportPhoto === null ||
      uploadsData?.data?.photos?.personalPhoto === null
    ) {
      setSnackBarState({
        message: "Please complete the Photos Form",
        open: true,
        severity: "warning",
      });
      return;
    }

    if (uploadsData?.data?.acceptedOfferLetter === null) {
      setSnackBarState({
        message: "Please complete the Techvariable Documents Form",
        open: true,
        severity: "warning",
      });
      return;
    }

    if (
      uploadsData?.data?.proofOfIdentity?.adhaarCard === null ||
      uploadsData?.data?.proofOfIdentity?.panCard === null
    ) {
      setSnackBarState({
        message: "Please complete the Proof Of Identity Form",
        open: true,
        severity: "warning",
      });
      return;
    }

    if (
      uploadsData?.data?.academicCertificates?.class10th === null ||
      uploadsData?.data?.academicCertificates?.class12th === null
    ) {
      setSnackBarState({
        message: "Please complete the Academic Certificates Form",
        open: true,
        severity: "warning",
      });
      return;
    }

    const response = window.confirm(
      "Are you sure to mark all process as complete? You won't be able to make any changes further."
    );
    if (response) {
      mutate(true);
    }
    return;
  };

  return (
    <ContainerComponent>
      <Grid container direction="row" columnSpacing={5}>
        <Grid item xs={12} sm={!2} md={6} lg={6} xl={6}>
          <TitleBox label={"Experience or Relieving Letter"}>
            <Dropzone
              disabled={isDisable.reliving}
              className="dropzone"
              onDrop={(files) => onDropReliving(files)}
              onReject={(files: FileRejection[]) => onRejectReliving(files)}
              maxSize={FILE_SIZE}
              accept={FILE_TYPE_OTHERS}
            >
              <ColorButton
                variant="contained"
                startIcon={<img src={SVGICONS.UploadDocumentIcon} />}
                sx={uploadButton}
              >
                Upload File
              </ColorButton>
              <DisplayMessage fileType="PDF" />
            </Dropzone>
            {fileNames.reliving !== "" ? (
              <UploadDetails
                fileName={fileNames.reliving}
                clearName={clearNameReliving}
                isLoading={isDeleteRelivingLoading}
              />
            ) : (
              <></>
            )}
          </TitleBox>
        </Grid>
        <Grid item xs={12} sm={!2} md={6} lg={6} xl={6}>
          <TitleBox label={"Last Salary Slip"}>
            <Dropzone
              disabled={isDisable.lastSal}
              onDrop={(files) => onDropLast(files)}
              onReject={(files: FileRejection[]) => onRejectLastSlip(files)}
              maxSize={FILE_SIZE}
              accept={FILE_TYPE_OTHERS}
              className="dropzone"
            >
              <ColorButton
                variant="contained"
                startIcon={<img src={SVGICONS.UploadDocumentIcon} />}
                sx={uploadButton}
              >
                Upload File
              </ColorButton>
              <DisplayMessage fileType="PDF" />
            </Dropzone>
            {fileNames.lastSal !== "" ? (
              <UploadDetails
                fileName={fileNames.lastSal}
                clearName={clearNameLast}
                isLoading={isDeleteSalLastLoading}
              />
            ) : (
              <></>
            )}
          </TitleBox>
        </Grid>

        <Grid item xs={12} sm={!2} md={6} lg={6} xl={6}>
          <TitleBox label={"Second Last Salary Slip"}>
            <Dropzone
              disabled={isDisable.secondLastSal}
              onDrop={(files) => onDropSecondLast(files)}
              onReject={(files: FileRejection[]) =>
                onRejectSecondLastSlip(files)
              }
              maxSize={FILE_SIZE}
              accept={FILE_TYPE_OTHERS}
              className="dropzone"
            >
              <ColorButton
                variant="contained"
                startIcon={<img src={SVGICONS.UploadDocumentIcon} />}
                sx={uploadButton}
              >
                Upload File
              </ColorButton>
              <DisplayMessage fileType="PDF" />
            </Dropzone>
            {fileNames.secondLastSal !== "" ? (
              <UploadDetails
                fileName={fileNames.secondLastSal}
                clearName={clearName2ndLast}
                isLoading={isDelete2ndSalLastLoading}
              />
            ) : (
              <></>
            )}
          </TitleBox>
        </Grid>

        <Grid item xs={12} sm={!2} md={6} lg={6} xl={6}>
          <TitleBox label={"Third Last Salary Slip"}>
            <Dropzone
              disabled={isDisable.thirdLastSal}
              onDrop={(files) => onDropThirdLast(files)}
              onReject={(files: FileRejection[]) =>
                onRejectThirdLastSlip(files)
              }
              maxSize={FILE_SIZE}
              accept={FILE_TYPE_OTHERS}
              className="dropzone"
            >
              <ColorButton
                variant="contained"
                startIcon={<img src={SVGICONS.UploadDocumentIcon} />}
                sx={uploadButton}
              >
                Upload File
              </ColorButton>
              <DisplayMessage fileType="PDF" />
            </Dropzone>
            {fileNames.thirdLastSal !== "" && (
              <UploadDetails
                fileName={fileNames.thirdLastSal}
                clearName={clearName3rdLast}
                isLoading={isDelete3rdSalLastLoading}
              />
            )}
          </TitleBox>
        </Grid>
        <Grid container justifyContent="flex-end">
          <div style={{ marginRight: "1em" }}>
            <ButtonComponent
              handleSubmit={handleSubmit}
              buttonText={"Previous"}
              width="100px"
            />
          </div>
          <ButtonComponent
            handleSubmit={isNext ? handleFinish : handleSubmit}
            buttonText={isNext ? "Finish" : "Save"}
            width="100px"
            // isLoading={isNext ? false : isLoading}
          />
          {/* {isNext ? (
            <ButtonComponent
              handleSubmit={handleFinish}
              buttonText={"Finish"}
              width="100px"
            />
          ) : (
            <ButtonComponent
              handleSubmit={handleSubmit}
              buttonText={"Save"}
              width="100px"
              // isLoading={is}
            />
          )} */}
        </Grid>
        <SnackBar
          snackbarOpen={snackBarState.open}
          handleCloseSnackbar={handleCloseSnackbar}
          message={snackBarState.message}
          severity={snackBarState.severity}
        />
      </Grid>
    </ContainerComponent>
  );
};

export default WorkExpDocuments;
