import axios from "axios";
import { BaseURL } from "../constants/baseurl";

export const axiosInstance = axios.create({
  baseURL: BaseURL,
});

axiosInstance.interceptors.request.use((config: any) => {
  let token = sessionStorage.getItem("access_token");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export const axiosUploadInstance = axios.create({
  baseURL: BaseURL,
});

axiosUploadInstance.interceptors.request.use((config: any) => {
  let token = sessionStorage.getItem("access_token");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
    config.headers.ContentType = "multipart/form-data";
  }
  return config;
});

