import { FormControl, MenuItem, Select, FormHelperText } from "@mui/material";
import { theme } from "../../theme";

const DropdownComponent = ({ name, value, handleChange, items }: any) => {
  // console.log("dropdown", value)

  return (
    <Select
      sx={{
        borderRadius: "10px",
        color: "#FFF",
        border: "1px solid",
        borderColor: `${theme.palette.primary.main}`,
        height: "2.8rem",
        fontSize: "0.875em",
        "& .MuiSvgIcon-root": {
          color: "white",
        },
        "& .MuiSelect-select": {
          outline: "none",
          border: "none",
        },
        // "& .MuiOutlinedInput": {
        //   outline: "1px solid yellow",
        // },
        // "& .MuiSelect-nativeInput": {
        //   border: "1px solid green",
        // },
      }}
      fullWidth
      name={name}
      value={value}
      onChange={handleChange}
      inputProps={{ "aria-label": "Without label" }}
    >
      {items.map((item: any) => (
        <MenuItem
          key={item.id}
          value={item.value}
          sx={{
            fontSize: "0.875em",
          }}
        >
          {item.text}
        </MenuItem>
      ))}
    </Select>
  );
};

export default DropdownComponent;
