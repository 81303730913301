export const checkBoxContent = [
  {
    name: "class10",
    text: "Class 10",
    isDisable: true,
  },
  {
    name: "class12",
    text: "Class 12",
    isDisable: true,
  },
  {
    name: "diploma",
    text: "Diploma",
    isDisable: false,
  },
  {
    name: "graduation",
    text: "Graduation",
    isDisable: false,
  },
  {
    name: "postGraduation",
    text: "Post Graduation",
    isDisable: false,
  },
];

export const labels = [
  "Institution / University",
  "Passing Year",
  "Percentage",
  "Subjects",
];
