import React, { useEffect } from "react";
import { useTheme, useMediaQuery, Typography, Box } from "@mui/material";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  documentUploadsComponents,
  recruiteeDetailsComponents,
  formDetailsComponents,
} from "./components";
import {
  documentFormSubmittedAtom,
  documentUploadsAtom,
  formDetailsAtom,
  onboardingTabsAtom,
  recruiteeAtom,
  recruiteeFormSubmittedAtom,
} from "../../recoil";

const FormComponent = () => {
  // const [activeTab, setActiveTab] = useRecoilState(onboardingTabsAtom);
  // const totalStep = activeTab.recruiteeDetailsTabs
  //   ? recruiteeDetailsComponents.comp.length
  //   : documentUploadsComponents.comp.length;

  // const recruiteeStepNo = useRecoilValue(recruiteeAtom);
  // const documentUploadsStepNo = useRecoilValue(documentUploadsAtom);

  // const recruiteeFormSubmitted = useRecoilValue(recruiteeFormSubmittedAtom);
  // const documentFormSubmitted = useRecoilValue(documentFormSubmittedAtom);

  const [activeTab, setActiveTab] = useRecoilState(formDetailsAtom);
  const totalStep = activeTab.recruiteeDetails.tabExpanded
    ? recruiteeDetailsComponents.comp.length
    : documentUploadsComponents.comp.length;

  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.down("md"));

  const StepCountComponent = () => {
    return (
      <Typography
        sx={{
          paddingLeft: "4%",
          paddingBottom: "2%",
          fontSize: "0.875em",
          // border: "1px solid green",
          opacity: 0.5,
        }}
        variant="body1"
      >
        Step
        {/* {activeTab.recruiteeDetailsTabs
          ? recruiteeStepNo
          : documentUploadsStepNo}
        / {totalStep} */}
        {activeTab.recruiteeDetails.tabExpanded
          ? activeTab.recruiteeDetails.stepCount
          : activeTab.documentUploads.stepCount}
        / {totalStep}
      </Typography>
    );
  };

  // useEffect(() => {
  //   console.log("current step", activeTab.recruiteeDetails.stepCount);
  // }, [activeTab]);

  // console.log("now active tab is ---->", activeTab);

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      {isMedium ? "" : <StepCountComponent />}
      {/* {activeTab.recruiteeDetailsTabs &&
        recruiteeDetailsComponents.comp.map((component) => {
          return component.id === recruiteeStepNo ? (
            <component.component key={component.key} />
          ) : null;
        })}

      {activeTab.documentUploadsTabs &&
        documentUploadsComponents.comp.map((component) => {
          return component.id === documentUploadsStepNo ? (
            <component.component key={component.key} />
          ) : null;
        })} */}

      {activeTab.recruiteeDetails.tabExpanded &&
        recruiteeDetailsComponents.comp.map((comp) => {
          return comp.id === activeTab.recruiteeDetails.stepCount ? (
            <comp.component key={comp.key} />
          ) : null;
        })}

      {activeTab.documentUploads.tabExpanded &&
        documentUploadsComponents.comp.map((comp) => {
          return comp.id === activeTab.documentUploads.stepCount ? (
            <comp.component key={comp.key} />
          ) : null;
        })}
    </Box>
  );
};

export default FormComponent;
