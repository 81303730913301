export const familyLangaugeSchema = {
    family: {
        spouse: {
            name: "",
            dependent: false,
            occupation: "",
            dateOfBirth: "",
        },
        mother: {
            name: "",
            dependent: false,
            occupation: "",
            dateOfBirth: "",
        },
        father: {
            name: "",
            dependent: false,
            occupation: "",
            dateOfBirth: "",
        },
    },
    languages: [
        {
            languageName: "English",
            speak: false,
            read: false,
            write: false,
        },
        {
            languageName: "Hindi",
            speak: false,
            read: false,
            write: false,
        },
        {
            languageName: "Assamese",
            speak: false,
            read: false,
            write: false,
        },
        // {
        //     languageName: "Bengali",
        //     speak: false,
        //     read: false,
        //     write: false,
        // },
    ],
}