import React from "react";
import { Box, useTheme, useMediaQuery, Grid } from "@mui/material";
import AccordionComponent from "../../components/Accordion/AccordionComponent";
import FormComponent from "./FormComponent";
import HeaderTabs from "../../components/Tabs/HeaderTabs";
import AccordionWrapper from "../../components/AccordionWrapper/AccordionWrapper";

const BodyComponent = () => {
  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.down("md"));
  const bodystyle = isMedium ? "column" : "row";

  return (
    <>
      <Grid container sx={{ px: isMedium ? "1em" : "4em" }}>
        <Grid item xl={3} lg={3} md={3} xs={12}>
          {/* {isMedium ? <HeaderTabs /> : <AccordionComponent />} */}
          {isMedium ? <HeaderTabs /> : <AccordionWrapper />}
        </Grid>
        <Grid item xl={9} lg={9} md={9} xs={12}>
          <FormComponent />
        </Grid>
      </Grid>
    </>
  );
};

export default BodyComponent;
