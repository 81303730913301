import { Tab, Tabs } from "@mui/material";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  documentFormSubmittedAtom,
  documentUploadsAtom,
  formDetailsAtom,
  onboardingTabsAtom,
  recruiteeAtom,
  recruiteeFormSubmittedAtom,
} from "../../recoil";
import {
  ITab,
  IAccordionComponents,
} from "../../interfaces/components.interface";
import {
  documentUploadsComponents,
  recruiteeDetailsComponents,
} from "../../pages/Onboarding/components";
import { useEffect } from "react";

const InsideTabs = ({
  activeHeaderTab,
}: {
  activeHeaderTab: IAccordionComponents;
}) => {
  // console.log("inside tab index", activeHeaderTab);
  // const [recruiteeStepNo, setRecruiteeStepNo] = useRecoilState(recruiteeAtom);
  // const [documentUploadsStepNo, setDocumentUploadsStepNo] =
  //   useRecoilState(documentUploadsAtom);
  // const value =
  //   activeHeaderTab.key === "recruiteeDetailsComponents"
  //     ? recruiteeStepNo
  //     : documentUploadsStepNo;

  // const [recruiteeSubmitState, setRecruiteeSubmitState] = useRecoilState(
  //   recruiteeFormSubmittedAtom
  // );
  // const [documentSubmittedState, setDocumentSubmittedState] = useRecoilState(
  //   documentFormSubmittedAtom
  // );

  // const handleClick = (curTab: ITab, index: number) => {
  //   const currentTab =
  //     activeHeaderTab.key === "recruiteeDetailsComponents"
  //       ? recruiteeSubmitState
  //       : documentSubmittedState;

  //   const previousTab = activeHeaderTab.comp[index === 0 ? index : index - 1];

  //   console.log({ currentTab, previousTab });

  //   const activeTabCompleted = currentTab.form[previousTab.key] as boolean;
  //   console.log("stepCOunt", recruiteeStepNo, documentUploadsStepNo);

  //   // if (curTab.id - 1 > currentTab.currentActiveStep && !activeTabCompleted) {
  //   //   console.log("hello", currentTab.currentActiveStep, activeTabCompleted);
  //   //   return;
  //   // }

  //   if (activeTabCompleted) {
  //     return false;
  //   }
  //   return true;
  // };

  // const handleChange = (e: any, newvalue: number) => {
  //   if (activeHeaderTab.key === "recruiteeDetailsComponents") {
  //     setRecruiteeStepNo(newvalue);
  //     console.log({ newvalue });
  //   }
  //   setDocumentUploadsStepNo(newvalue);
  // };

  const [activeTab, setActiveTab] = useRecoilState(formDetailsAtom);

  const handleClick = (curTab: ITab, idx: number) => {
    const previousStep =
      activeTab.recruiteeDetails.tabsArray[idx - 1 >= 0 ? idx - 1 : 0];
    if (!previousStep.isCompleted) return;

    if (activeTab.recruiteeDetails.tabExpanded) {
      setActiveTab((prev) => ({
        ...prev,
        recruiteeDetails: {
          ...prev.recruiteeDetails,
          stepCount: curTab.id,
        },
      }));
    }

    //documentUploads
    const previousDocumentUploadsStep =
      activeTab.documentUploads.tabsArray[idx - 1 >= 0 ? idx - 1 : 0];
    if (!previousDocumentUploadsStep.isCompleted) return;

    if (activeTab.documentUploads.tabExpanded) {
      setActiveTab((prev) => ({
        ...prev,
        documentUploads: {
          ...prev.documentUploads,
          stepCount: curTab.id,
        },
      }));
    }
  };

  return (
    <Tabs
      value={
        activeTab.recruiteeDetails.tabExpanded
          ? activeTab.recruiteeDetails.stepCount
          : activeTab.documentUploads.stepCount
      }
      // onChange={handleChange}
      indicatorColor="secondary"
      textColor="inherit"
      variant="scrollable"
      scrollButtons="auto"
      aria-label="scrollable auto tabs example"
      TabIndicatorProps={{
        sx: {
          backgroundColor: "white",
        },
      }}
    >
      {activeHeaderTab.comp.map((curTab: ITab, idx: number) => (
        <Tab
          disableRipple
          label={curTab.title}
          value={curTab.id}
          sx={{ textTransform: "capitalize", fontSize: "0.75em" }}
          onClick={() => handleClick(curTab, idx)}
        />
      ))}
    </Tabs>
  );
};

export default InsideTabs;

// disabled={handleClick(curTab, idx)}
// disabled={
//   curTab.id !== recruiteeStepNo && curTab.id > recruiteeStepNo
// }
