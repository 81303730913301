export const eduQualKeys = [
    "education" //object
]

export const eduQualMapper = (data: any) => {
    return eduQualKeys.map(key => {
        const value = data[key];
        // console.log({ key, value })

        // Object.entries(value).map((key, val) => {
        //     console.log("ass", { key, val })
        // })

        if (value.class10.institue === "") return false;
        if (value.class10.percentage === "") return false;
        if (value.class10.yearOfPassing === "") return false;
        if (value.class10.subjects.length === 0) return false;

        if (value.class12.institue === "") return false;
        if (value.class12.percentage === "") return false;
        if (value.class12.yearOfPassing === "") return false;
        if (value.class12.subjects.length === 0) return false;

        return true
    }).every(val => val === true)
}