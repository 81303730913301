import { useState, useEffect } from "react";
import { Grid, InputLabel, Button } from "@mui/material";
import { StyledTextField } from "../../../../components/StyledComponents/StyledTextField/StyledTextField";
import { useFormik } from "formik";
import { StyledSpanError } from "../../../../components/StyledComponents/StyledSpanError";
import { ICONS } from "../../../../constants";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  accordionCompletedAtom,
  formDetailsAtom,
  onboardingTabsAtom,
  recruiteeAtom,
  recruiteeFormSubmittedAtom,
} from "../../../../recoil";
import { bankDetailsSchema } from "../../../../schemas/bankDetailsSchema";
import { IBankDetails } from "../../../../interfaces/forms/bankDetails.interface";
import { bankDetailsVSchema } from "../../../../yup-schemas/bankDetailsVSchema";
import { MODULE_NAME } from "../../../../constants/baseurl";
import ButtonComponent from "../../../../components/ButtonComponent";
import { setBankDetailsDefaultValue } from "../../utils/setDefaultValues";
import ContainerComponent from "../../../../components/Container/ContainerComponent";
import { useQuery, useMutation } from "@tanstack/react-query";
import { getUserInfoAPI, saveBankDetailsAPI } from "../../../../api/recruitee";
import { ISnackbarDetailsInterface } from "../../../../interfaces";
import SnackBar from "../../../../components/SnackBar";
import CircularLoader from "../../../../components/CircularLoader";

const BankDetails = () => {
  const [bankDetails, setBankDetails] =
    useState<IBankDetails>(bankDetailsSchema);
  const setRecruiteeStepNo = useSetRecoilState(recruiteeAtom);
  const [isNext, setIsNext] = useState<boolean>(false);
  const [snackbarDetails, setSnackbarDetails] =
    useState<ISnackbarDetailsInterface>({
      open: false,
      message: "",
      severity: undefined,
    });

  // const [, setRecruiteeFormSubmitted] = useRecoilState(
  //   recruiteeFormSubmittedAtom
  // );
  // const setAccordionCompleted = useSetRecoilState(accordionCompletedAtom);

  const [activeTab, setActiveTab] = useRecoilState(formDetailsAtom);
  const tabs = activeTab.recruiteeDetails.tabsArray.map((tab) => {
    if (tab.id === activeTab.recruiteeDetails.stepCount)
      return {
        ...tab,
        isCompleted: true,
      };
    return tab;
  });

  //PATCH request
  const { mutate, isLoading: isLoadingPATCH } = useMutation({
    mutationFn: saveBankDetailsAPI,
    onSuccess: (data, variable) => {
      //snackbar open
      setSnackbarDetails({
        open: true,
        message: "Successfully submitted!",
        severity: "success",
      });
      // setRecruiteeFormSubmitted((prev) => ({
      //   ...prev,
      //   form: {
      //     ...prev.form,
      //     bankDetailsForm: true,
      //   },
      // }));
      // setAccordionCompleted((prev) => ({
      //   ...prev,
      //   recruiteeCompleted: true,
      // }));
      // setIsNext(true);

      setActiveTab((prev) => ({
        ...prev,
        recruiteeDetails: {
          ...prev.recruiteeDetails,
          tabsArray: tabs,
          tabCompleted: true,
        },
      }));
      setIsNext(true);
    },
    onError: (error) => {
      setSnackbarDetails({
        open: true,
        message: "Something went wrong",
        severity: "error",
      });
    },
  });

  // const patchFormData = async (data: IBankDetails) => {
  //   console.log("remo", data);

  //   try {
  //     const res = await saveBankDetailsAPI(data);
  //     if (res.status === 200) {
  //       //snackbar open
  //       setSnackbarDetails({
  //         open: true,
  //         message: "successfully submitted!",
  //         severity: "success",
  //       });
  //       setIsNext(true);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     setSnackbarDetails({
  //       open: true,
  //       message: "Something went wrong",
  //       severity: "error",
  //     });
  //   }
  // };

  const {
    values,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues: bankDetails,
    validationSchema: bankDetailsVSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => {
      // patchFormData(values);
      mutate(values);
    },
  });

  //GET request
  const { isLoading: isLoadingGET } = useQuery({
    queryKey: ["getBankDetails"],
    queryFn: getUserInfoAPI,
    onSuccess: (getData) => {
      setBankDetails(getData.data.data.bankAccDetails);
      setValues(setBankDetailsDefaultValue(getData.data.data.bankAccDetails));
    },
    onError: (error) => {
      setSnackbarDetails({
        open: true,
        message: "Something went wrong",
        severity: "error",
      });
    },
  });

  // useEffect(() => {
  //   const getFormData = async () => {
  //     try {
  //       const res = await getUserInfoAPI();
  //       console.log("bank details get", res);
  //       setBankDetails(res.data.data.bankAccDetails);
  //       setValues(setBankDetailsDefaultValue(res.data.data.bankAccDetails));
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };
  //   getFormData();
  // }, []);

  const handleCloseSnackbar = () => {
    setSnackbarDetails((prev) => ({
      ...prev,
      open: false,
    }));
  };

  return (
    <ContainerComponent>
      {isLoadingGET ? (
        <CircularLoader />
      ) : (
        <>
          <Grid container columnSpacing={4} rowSpacing={4}>
            <SnackBar
              snackbarOpen={snackbarDetails.open}
              message={snackbarDetails.message}
              severity={snackbarDetails.severity}
              handleCloseSnackbar={handleCloseSnackbar}
            />
            <Grid item xs={12} sm={12} md={4}>
              <InputLabel
                shrink
                htmlFor="bootstrap-input"
                sx={{ color: "white", fontSize: "0.75rem" }}
              >
                Bank Name *
              </InputLabel>
              <StyledTextField
                autoComplete="off"
                hiddenLabel
                fullWidth
                name="bankName"
                value={values.bankName}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              <StyledSpanError>{errors.bankName}</StyledSpanError>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <InputLabel
                shrink
                htmlFor="bootstrap-input"
                sx={{ color: "white", fontSize: "0.75rem" }}
              >
                Account Number *
              </InputLabel>
              <StyledTextField
                autoComplete="off"
                hiddenLabel
                fullWidth
                name="accountNo"
                value={values.accountNo}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              <StyledSpanError>{errors.accountNo}</StyledSpanError>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <InputLabel
                shrink
                htmlFor="bootstrap-input"
                sx={{ color: "white", fontSize: "0.75rem" }}
              >
                IFSC Code *
              </InputLabel>
              <StyledTextField
                autoComplete="off"
                hiddenLabel
                fullWidth
                name="IFSC"
                value={values.IFSC}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              <StyledSpanError>{errors.IFSC}</StyledSpanError>
            </Grid>
          </Grid>

          {/*  button*/}
          <Grid
            container
            justifyContent="flex-end"
            sm={12}
            sx={{ marginBottom: "1em" }}
          >
            <div style={{ marginRight: "1em" }}>
              <ButtonComponent
                handleSubmit={handleSubmit}
                buttonText={"Previous"}
                width="100px"
              />
            </div>
            <ButtonComponent
              handleSubmit={handleSubmit}
              buttonText={isNext ? "Next" : "Save"}
              width="100px"
              isLoading={isNext ? false : isLoadingPATCH}
            />
          </Grid>
        </>
      )}
    </ContainerComponent>
  );
};

export default BankDetails;
