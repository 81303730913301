import ControlPointIcon from "@mui/icons-material/ControlPoint";
import { Grid, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useFormik } from "formik";
import { useState } from "react";
import { useRecoilState } from "recoil";
import {
  getUserInfoAPI,
  saveWorkExperinceAPI,
} from "../../../../api/recruitee";
import ButtonComponent from "../../../../components/ButtonComponent";
import CircularLoader from "../../../../components/CircularLoader";
import ContainerComponent from "../../../../components/Container/ContainerComponent";
import SnackBar from "../../../../components/SnackBar";
import TitleBox from "../../../../components/TitleBox/TitleBox";
import { ISnackbarDetailsInterface } from "../../../../interfaces";
import {
  IExperienceDetails,
  IWorkExperienceReference,
} from "../../../../interfaces/forms/workExperience.interface";
import { formDetailsAtom } from "../../../../recoil";
import { GridRoot } from "./styles";
import { validate } from "./validationSchema";
import WorkExperienceForm from "./WorkExperienceForm";

export interface FormValuesInterface {
  workExperience: IExperienceDetails[];
}

export interface FormErrorInterface {
  workExperience: {
    organization: string;
    tctc: string;
    startDate: string;
    endDate: string;
    designation: string;
    reasonForLeaving: string;
    reference: IWorkExperienceReference;
  }[];
}

const userDataProps: FormValuesInterface = {
  workExperience: [],
};

const WorkExperience = () => {
  const { handleSubmit, handleChange, setValues, setFieldValue, ...formik } =
    useFormik({
      initialValues: userDataProps,
      enableReinitialize: true,
      validateOnChange: false,
      validate: (values) => validate(values),
      onSubmit: () => {
        saveExperienceData();
      },
    });

  const [isNext, setIsNext] = useState<boolean>(false);

  const [snackBarState, setSnackBarState] = useState<ISnackbarDetailsInterface>(
    {
      message: "",
      open: false,
      severity: undefined,
    }
  );
  // const [, setRecruiteeFormSubmitted] = useRecoilState(
  //   recruiteeFormSubmittedAtom
  // );

  const [activeTab, setActiveTab] = useRecoilState(formDetailsAtom);
  const tabs = activeTab.recruiteeDetails.tabsArray.map((tab) => {
    if (tab.id === activeTab.recruiteeDetails.stepCount)
      return { ...tab, isCompleted: true };
    return tab;
  });

  const spacing = { marginTop: "2em" };

  const handleCloseSnackbar = () => {
    setSnackBarState((prev) => ({
      ...prev,
      open: false,
    }));
  };

  const addExperience = (value: any) => {
    const presentTempExpdata = [
      ...formik.values.workExperience,
      {
        organization: "",
        tctc: 0,
        startDate: "",
        endDate: "",
        designation: "",
        reasonForLeaving: "",
        reference: {
          name: "",
          relationship: "",
          contact: "",
          designation: "",
        },
      },
    ];

    const newExpData = {
      workExperience: presentTempExpdata,
    };
    setValues(newExpData, false);
  };

  const removeExperience = (index: number) => {
    const expValues = [...formik.values.workExperience];
    expValues.splice(index, 1);
    setValues({ workExperience: expValues });
  };

  const { isLoading: isLoadingFetch } = useQuery({
    queryKey: ["getWorkExperience"],
    queryFn: getUserInfoAPI,
    onSuccess: (data) => {
      setValues({ workExperience: data?.data?.data?.workExperience });
    },
    onError: (error: unknown) => {
      setSnackBarState({
        open: true,
        message: "Something went wrong",
        severity: "error",
      });
    },
  });

  const saveExperienceData = async () => {
    if (formik.values.workExperience.length > 0) {
      const isStartDateNotValid = formik.values.workExperience.some((value) => {
        let date1 = Date.parse(value.startDate);
        let date2 = Date.parse(value.endDate);
        return date1 > date2;
      });
      if (isStartDateNotValid) {
        setSnackBarState({
          open: true,
          message: "Start Date cannot be ahead of End Date!",
          severity: "warning",
        });
        return;
      }
      mutate(formik.values.workExperience);
    }
    mutate(formik.values.workExperience);
  };

  const { mutate, isLoading } = useMutation({
    mutationFn: saveWorkExperinceAPI,
    onSuccess: () => {
      setSnackBarState({
        open: true,
        message: "successfully submitted!",
        severity: "success",
      });
      // setRecruiteeFormSubmitted((prev) => ({
      //   ...prev,
      //   form: {
      //     ...prev.form,
      //     workExpForm: true,
      //   },
      // }));

      setActiveTab((prev) => ({
        ...prev,
        recruiteeDetails: {
          ...prev.recruiteeDetails,
          tabsArray: tabs,
        },
      }));
      setIsNext(true);
    },
    onError: (error) => {
      setSnackBarState({
        open: true,
        message: "Something went wrong",
        severity: "error",
      });
    },
  });

  return (
    <div>
      <ContainerComponent>
        {isLoadingFetch ? (
          <CircularLoader />
        ) : (
          <>
            <Grid
              container
              direction="row"
              justifyContent="center"
              sx={GridRoot}
              onClick={() => addExperience(formik.values)}
            >
              <ControlPointIcon fontSize="small" />
              <Typography
                sx={{
                  paddingTop: "0.2em",
                  paddingLeft: "0.5em",
                  fontFamily: "Poppins",
                  fontWeight: 400,
                  fontSize: "12px",
                }}
              >
                Add new work experience
              </Typography>
            </Grid>
            {formik.values.workExperience.length > 0 &&
              formik.values.workExperience.map(
                (currentElement: IExperienceDetails, index: number) => {
                  return (
                    <Box sx={spacing}>
                      <TitleBox label={`Organization ${index + 1} `}>
                        <WorkExperienceForm
                          index={index}
                          name={[
                            `workExperience[${index}].organization`,
                            `workExperience[${index}].tctc`,
                            `workExperience[${index}].startDate`,
                            `workExperience[${index}].endDate`,
                            `workExperience[${index}].designation`,
                            `workExperience[${index}].reasonForLeaving`,
                            `workExperience[${index}].reference`,
                          ]}
                          value={currentElement}
                          errors={formik.errors}
                          removeExperience={removeExperience}
                          handleChange={handleChange}
                          setFieldValue={setFieldValue}
                        />
                      </TitleBox>
                    </Box>
                  );
                }
              )}
            <Grid
              container
              justifyContent="flex-end"
              sm={12}
              sx={{ marginBottom: "1em" }}
            >
              <div style={{ marginRight: "1em" }}>
                <ButtonComponent
                  handleSubmit={handleSubmit}
                  buttonText={"Previous"}
                  width="100px"
                />
              </div>
              <ButtonComponent
                handleSubmit={handleSubmit}
                buttonText={isNext ? "Next" : "Save"}
                width="100px"
                isLoading={isNext ? false : isLoading}
              />
            </Grid>

            <SnackBar
              snackbarOpen={snackBarState.open}
              handleCloseSnackbar={handleCloseSnackbar}
              message={snackBarState.message}
              severity={snackBarState.severity}
            />
          </>
        )}
      </ContainerComponent>
    </div>
  );
};

export default WorkExperience;
