import { CSSObject } from "@mui/material/styles";

export const GridRoot: CSSObject = {
  padding: "0.5em",
  height: " 45px",
  border: " 1px dashed #FFFFFF",
  borderRadius: " 10px",

  "&:hover": {
    cursor: "pointer",
    border: " 1px dashed #140047",
    backgroundColor: "#d6d6d6",
    color: "#140047",
  },
};

export const experienceGrid = {
  margin: "1em",
  padding: "1em",
  border: "1px solid #381A84",
  borderRadius: "10px",
  marginLeft: "7%",
};

export const experienceLabel = {
  textAlign: "center",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "12px",
  lineHeight: "18px",
  color: "#FFFFFF",
};
